module.exports = {
  "nav": {
    "shouye": "หน้าแรก",
    "hanqing": "การตลาด",
    "jiaoyi": "การซื้อขาย",
    "heyue": "สวอป",
    "wode": "ฉัน"
  },
  "common": {
    "login": "เข้าสู่ระบบ",
    "register": "ลงทะเบียน",
    "loginregister": "เข้าสู่ระบบ/ลงทะเบียน",
    "logout": "ออกจากระบบ",
    "tip": "การแจ้งเตือน",
    "delete": "ยืนยันจะลบหรือไม่",
    "set": "ตั้งค่า",
    "update": "แก้ไข",
    "more": "มากกว่า",
    "loading": "กำลังโหลดอยู่",
    "zanwu": "ยังไม่มีข้อมูล",
    "quxiao": "ยกเลิก",
    "ok": "ตกลง",
    "noyanzhengma": "แคปต์ชาไม่ถูกต้อง",
    "clearStorage": "ล้างแคช",
    "telErr": "รูปแบบหมายเลขโทรศัพท์มือถือไม่ถูกต้อง",
    "T404": "ระบบกำลังอัปเดตอยู่ และจะกู้คืนการเข้าสู่ในเร็วๆ นี้"
  },
  "loginPage": {
    "mima": "รหัสผ่าน",
    "passwordTxt": "กรุณากรอกรหัสผ่าน"
  },
  "homePage": {
    "charge": "เติมเงิน",
    "pickup": "ถอนเงิน",
    "exchange": "สปอร์ต",
    "promotionaward": "รีเบต"
  },
  "pageTitle": {
    "gonggaoxiangqing": "ประกาศ",
    "wodezichang": "ทรัพย์สินของฉัน",
    "bangzhuxiangqing": "รายละเอียด",
    "fundpwd": "รหัสผ่านกองทุน",
    "yuyanshezhi": "ภาษา",
    "huazhuang2": "การโอน",
    "renzheng": "การรับรองความถูกต้องชื่อจริง",
    "chooseAlterPhoneType": "หมายเลขโทรศัพท์มือถือ",
    "chooseAlterEmailType": "อีเมล",
    "letterList": "ข้อความ",
    "appDownload": "ดาวน์โหลดแอปมือถือ",
    "buy": "ซื้อเข้า",
    "sell": "ขายออก",
    "cexiao": "ยกเลิก",
    "time": "เวลา",
    "type": "รูปแบบ",
    "jiage": "ราคา",
    "weituoliang": "ปริมาณคำสั่ง",
    "changjiao": "ตกลงซื้อขายแล้ว",
    "weituoe": "ยอดคำสั่ง",
    "over": "โหลดทั้งหมด",
    "weituojia": "ราคาคำสั่ง",
    "weituoer": "ยอดคำสั่งทั้งหมด",
    "quedingchedan": "ตกลงจะยกเลิกออเดอร์",
    "chedaning": "ยกเลิกออเดอร์",
    "jiaoyidui": "คู่ซื้อขาย",
    "fangxiang": "ทิศทาง",
    "chongzhi": "รีเซ็ต",
    "xianjia": "ราคาจำกัด",
    "shijia": "ราคาตลาด",
    "plan": "ทริกเกอร์",
    "quanbu": "ทั้งหมด",
    "xianjiaweituo": "คำสั่งลิมิต",
    "yichexiao": "ยกเลิกออเดอร์แล้ว",
    "time1": "เวลาเริ่มต้องเช้ากว่าเวลาสิ้นสุด",
    "time2": "เวลาสิ้นสุดต้องช้ากว่าเวลาเริ่มต้น",
    "time3": "เวลาสิ้นสุดต้องเช้ากว่าเวลาปัจจุบัน",
    "cedancg": "การยกเลิกออเดอร์สำเร็จ",
    "cedansb": "การยกเลิกออเดอร์ล้มเหลว",
    "mairujiage": "ราคาต่อหน่วย",
    "yongxu": "SWAP",
    "zhanghu": "ยอดรวมของบัญชี",
    "yugu": "ทำนายราคาบังคับปิดตำแหน่ง",
    "baozheng": "อัตรามาร์จิ้น",
    "kaicang": "เปิดตำแหน่ง",
    "baocang": "บังคับชำระบัญชี",
    "pincang": "ปิดตำแหน่ง",
    "zhanghu2": "บัญชี",
    "pingduo": "Close Long",
    "kaiduo": "Open Long",
    "pingkong": "Close Short",
    "kaigong": "Open Short",
    "cufajia": "ราคาทริกเกอร์",
    "zuiyou": "ราคาดีที่สุด",
    "zhang": "ฉบับ",
    "keyong": "ที่นำใช้ได้",
    "kekaiduo": "Max. L",
    "kekaikong": "Max. S",
    "duocang": "Max (L)",
    "kongcang": "Max (S)",
    "kanzhang": "มองราคาขึ้น",
    "kandie": "มองราคาลง",
    "shuliang": "จำนวน",
    "chicang": "การถือตำแหน่งปัจจุบัน",
    "dangqianweituo": "คำสั่งปัจุบัน",
    "lishiweituo": "คำสั่งประวัติ",
    "shouyi": "กำไร",
    "shouyilv": "อัตรากำไร",
    "gangganbeishu": "ผลคูณเลเวอร์เรจ",
    "chicangliang": "ปริมาณการถือตำแหน่ง",
    "kepingliang": "ปริมาณการเปิด",
    "kaichangjun": "ราคาเปิดเฉลี่ย",
    "zuixinjia": "ราคาล่าสุด",
    "dangqianbaozheng": "มาร์จิ้นปัจจุบัน",
    "duotou": "การซื้อ",
    "kongtou": "การขาย",
    "baozhengjin": "มาร์จิ้น",
    "weituoshuliang": "จำนวนคำสั่ง",
    "jiesuanweik": "ชำระกำไรและขาดทุน",
    "chengjiaojia": "ราคาการซื้อขาย",
    "shouxufei": "ค่าธรรมเนียม",
    "xiugaichenggong": "แก้ไขสำเร็จ"
  },
  "views": {
    "wangjiPass": "ลืมรหัสผ่านหรือไม่",
    "confirmLogOut": "ยืนยันจะออกจากระบบหรือไม่",
    "personalCenter": "ศูนย์ผู้ใช้",
    "shezhi": "ตั้งค่าสำเร็จ",
    "yuanyouxiang": "รหัสยืนยันอีเมลเก่า",
    "newyouxiang": "รหัสยืนยันอีเมลใหม่",
    "fsyouxiang": "ส่งรหัสยืนยัน",
    "xyouxiang": "อีเมลใหม่",
    "loginPass": "รหัสผ่านในการเข้าสู่ระบบ",
    "baocun": "บันทึก",
    "fschenggong": "ส่งสำเร็จ",
    "inputyouxiang": "กรุณากรอกอีเมล์ใหม่",
    "inputOld": "กรุณากรอกรหัสยืนยันอีเมลเก่า",
    "inputNew": "กรุณากรอกรหัสยืนยันอีเมลใหม่",
    "inputPass": "กรุณากรอกรหัสผ่าน",
    "OldPass": "รหัสผ่านเก่า",
    "inputOldPass": "กรุณากรอกรหัสผ่านเก่า",
    "NewPass": "รหัสผ่านใหม่",
    "inputNewPass": "กรุณากรอกรหัสผ่านใหม่",
    "queren": "ยืนยันรหัสผ่าน",
    "email": "รหัสยืนยันอีเมล",
    "liangciPass": "รหัสผ่านที่กรอกของทั้งสองครั้งไม่สอดคล้องกัน",
    "shuruCode": "กรุณากรอกรหัสยืนยัน",
    "OldZJ": "รหัสผ่านกองทุนเก่า",
    "inputOldZJ": "กรุณากรอกรหัสผ่านกองทุนเก่า",
    "NewZJ": "รหัสผ่านกองทุนใหม่",
    "inputNewZJ": "กรุณากรอกรหัสผ่านกองทุนใหม่",
    "augeren": "ข้อมูลส่วนตัว",
    "aushang": "อัพโหลดเอกสาร",
    "aushenghe": "การตรวจสอบ",
    "name": "ชื่อ",
    "inputName": "กรุณากรอกชื่อจริงของคุณ",
    "huzhao": "หมายเลขบัตรประชาชน",
    "inputhuzhao": "กรุณากรอกหมายเลขบัตรประชาชน",
    "next": "ขั้นตอนต่อไป",
    "uphuzhao": "อัพโหลดรูปบัตรประชาชน",
    "shouhuzhao": "กรุณาอัปโหลดรูปพที่คุณถือบัตรประชาชน",
    "tijiao": "การเสนอ",
    "tip1": "1. บัตรประชาชนต้องอยู่ภายในระยะเวลาที่กำหนด",
    "tip2": "2. เนื้อหาของลายเซ็นต้องประกอบด้วย {hrefArry}ที่เขียนด้วยมือ ลายเซ็นส่วนตัว และวันที่ปัจจุบัน",
    "tip3": "3. โปรดตรวจสอบให้แน่ใจว่าภาพถ่ายและเนื้อหาของลายเซ็นส่วนตัวนั้นมองเห็นได้ชัดเจน",
    "shentijiao": "เสนอข้อมูลเรียบร้อยแล้ว กรุณารอการตรวจสอบ",
    "fanhui": "กลับ",
    "bangding": "ผูก",
    "inputEmail": "กรุณากรอกอีเมล์",
    "inputPhone": "กรุณากรอกหมายเลขโทรศัพท์",
    "kefu": "กรุณาติดต่อฝ่ายบริการลูกค้า"
  },
  "list": {
    "wufaxiugia": "คุณไม่สามารถแก้ไขผลคูณได้เมื่อคุณถือตำแหน่งไว้หรือคำสั่งที่ยังไม่ได้ซื้อขาย",
    "submittip1": "โปรดทำการรับรองความถูกต้องชื่อจริงก่อนเพื่อความปลอดภัยของเงินของคุณ",
    "submittip2": "โปรดตั้งรหัสผ่านกองทุนก่อนเพื่อความปลอดภัยของเงินของคุณ",
    "shuruweituo": "กรุณาใส่ราคาคำสั่ง",
    "shuruweituoliang": "กรุณาใส่จำนวนคำสั่ง",
    "dayukekai": "จำนวนคำสั่งปัจุบันต้องมากกว่าจำนวนการเปิดสถานะซื้อ",
    "dayuduocang": "จำนวนคำสั่งปัจุบันต้องมากกว่าจำนวนการปิดสถานะซื้อ",
    "dayukekaikong": "จำนวนคำสั่งปัจุบันต้องมากกว่าจำนวนการเปิดสถานะขาย",
    "dayukongcang": "จำนวนคำสั่งปัจุบันต้องมากกว่าจำนวนการปิดสถานะขาย",
    "shuruchufa": "กรุณาใส่ราคาทริกเกอร์",
    "zhangfubang": "การจัดอันดับอัตราการเติบโต",
    "liang": "จำนวน",
    "safar": "คลิกปุ่มด้านล่างในเบราว์เซอร์Safari",
    "kuaisuanzhuang": "เลือก [เพิ่มไปยังหน้าจอโฮม]",
    "noAccount": "ยังไม่มีบัญชี",
    "LoginReg": "ลงทะเบียนทันที",
    "butigongfuwu": "IP หรือภูมิภาคที่คุณอยู่ไม่สามารถใช้งานได้",
    "renzheng1": "รับรองความถูกต้องแล้ว",
    "renzheng2": "รับรองความถูกต้องล้มเหลว",
    "renzheng3": "กำลังตรวจสอบ",
    "renzheng4": "รับรองความถูกต้อง",
    "shenheing": "กำลังตรวจสอบอยู่ กรุณารออย่างทรหดอดทน",
    "inputzaicimima": "กรุณากรอกรหัสผ่านอีกครั้ง",
    "xuantian": "กรุณากรอกรหัสเชิญ (ให้เลือกได้)",
    "yuedu": "ฉันได้อ่านและยอมรับ ",
    "yonghuxieyi": "ข้อตกลงในการใช้บริการผู้ใช้",
    "hasAccount": "มีบัญชีแล้วหรือไม่",
    "denglu": "เข้าสู่ระบบทันที",
    "gouxuanxieyi": "กรุณาอ่านและกดถูกข้อตกลงการให้บริการผู้ใช้",
    "inputzijinmima": "กรุณากรอกรหัสผ่านกองทุน",
    "yijianfankui": "ข้อเสนอแนะ",
    "gao": "สูง",
    "di": "ต่ำ",
    "shengdu": "ความลึก",
    "chengjiao": "ทำการซื้อขาย",
    "zixuan1": "ลบออกจากรายการโปรดแล้ว",
    "zixuan2": "การลบออกจากรายการโปรดล้มเหลว",
    "zixuan3": "เพิ่มรายการโปรดสำเร็จ",
    "zixuan4": "เพิ่มรายการโปรดล้มเหลว",
    "jiaoyie": "ยอดรวมของการซื้อขาย",
    "chengjiaozonger": "ยอดรวมของการซื้อขาย",
    "chengjiaojun": "ราคาซื้อขายเฉลี่ย",
    "chengjiaoliang": "ปริมาณการซื้อขาย",
    "bigmairu": "เกินปริมาณซื้อขายสูงสุด",
    "weituochenggong": "คำสั่งสำเร็จ",
    "weituoshibai": "คำสั่งล้มเหลว",
    "yue": "ยอดคงเหลือ",
    "bizhong": "สกุลเงิน",
    "huazhuanshuliang": "จำนวนการโอน",
    "inputhzsl": "กรุณากรอกจำนวนการโอน",
    "bunengweifu": "จำนวนการโอนต้องมากกว่า0",
    "dayuhuazhuan": "จำนวนการโอนปัจจุบันต้องมากกว่ายอดเงินที่ทำการโอนได้ในบัญชี",
    "huazhuanchenggong": "การโอนสำเร็จ",
    "huazhuangshibai": "การโอนล้มเหลว"
  },
  "user": {
    "guizeshuoming": "กฎกติกา",
    "shenheweitongguo": "การตรวจสอบไม่ผ่าน",
    "shiming": "กรุณารับรองความถูกต้องชื่อจริงให้เรียบร้อย",
    "fuzhichenggong": "คัดลอกสำเร็จ",
    "frozen": "ระงับ",
    "zhanghuquanyi": "ส่วนของบัญชีผู้ใช้",
    "baozhenjin": "ฟรีมาร์จิ้น",
    "jiaoyishijian": "เวลาการซื้อขาย"
  },
  "tradePage": {
    "CUSTOM": "รายการโปรด"
  },
  "chart": {
    "minute": "นาที"
  },
  "charge": {
    "congbi": "ที่อยู่การเติมเงิน",
    "baocun": "บันทึกภาพQR โค้ด"
  },
  "heyuePage": {
    "yijianpingcang": "คลิกเดียวปิดตำแหน่ง",
    "copyerr": "คัดลอกล้มเหลว",
    "pincang": "จำนวนฉบับการปิดตำแหน่งไม่สามารถเป็น 0 ได้"
  },
  "yaoqin": {
    "zuce": "เวลาลงทะเบียน",
    "showPersonInfo": "ดูข้อมูลส่วนตัวของคุณ",
    "myinvitelink": "ลิงค์คำเชิญ",
    "my_column4": "ระดับ",
    "yaoqing": "เชิญชวน",
    "dengji": "ความคืบหน้าในการเติบโตของระดับ",
    "mubiao": " เป้าหมาย",
    "zong": "รีเบตทั้งหมด",
    "jiaoyi": "รายละเอียดการซื้อขาย",
    "wode": "รหัสเชิญชวน",
    "huodong": "โบนัสกิจกรรม"
  },
  "version3": {
    "hash": "ค่าแฮช",
    "betTip": "เดาตัวเลขสุดท้ายของค่าแฮชของบล็อก ETH อันแรกในทุก ๆ 10 นาที 0-7 คือเลขน้อย 8-f คือเลขมาก 0, 2, 4, 6, 8, a, c, e คือเลขคู่, 1, 3 , 5, 7, 9, b, d และ f คือเลขคี่",
    "betTip2": "เดาตัวเลขสุดท้ายของค่าแฮชของบล็อก ETH 0-7 คือเลขน้อย 8-f คือเลขมาก 0, 2, 4, 6, 8, a, c, e คือเลขคู่, 1, 3 , 5, 7, 9, b, d และ f คือเลขคี่",
    "betMoneyTip": "ยอดเงินเดิมพันต่อครั้ง",
    "doBet": "เดิมพันตอนนี้",
    "issue": "เบอร์",
    "blockHigh": "ความสูงของบล็อก",
    "blockTime": "เวลาบล็อก",
    "hash2": "ค่าแฮช",
    "gameRecord": "บันทึกของเกม",
    "betTime": "เวลาเดิมพัน",
    "pickUpAddress": "ที่อยู่การถอนเงิน",
    "arriveAccountNum": "จำนวนเงินที่ได้รับ",
    "remark": "หมายเหตุ",
    "contachService": "ติดต่อฝ่ายบริการลูกค้า",
    "totalBetMoney": "ยอดรวมของเดิมพันทั้งหมด",
    "unOpen": "ไม่มีผลลัพธ์",
    "maintain": "ในการบำรุงรักษาอยู่",
    "hasBingGo": "ถูกลอตเตอรี่",
    "unBingGo": "ไม่ได้ถูกลอตเตอรี่",
    "outOfBalance": "ยอดคงเหลือไม่เพียงพอ",
    "chooseBetNumTip": "กรุณาเลือกตัวเลือกการเดิมพัน",
    "chooseBetMoney": "กรุณากรอกจำนวนเงินการเดิมพันต่อครั้ง",
    "minBetAmount": "จำนวนเงินการเดิมพันต่อครั้งต่ำสุดคือ",
    "maxBetAmount": "จำนวนเงินการเดิมพันต่อครั้งสูงสุดคือ",
    "chooseWithDrawAddressTip": "กรุณาเลือกที่อยู่",
    "inputRemarkTip": "กรุณากรอกที่อยู่การถอนของคุณ",
    "confirmOrder": "ยืนยันออเดอร์",
    "manji": "ถึงระดับวีไอพีสูงสุดแล้ว",
    "selWithAddress": "กรุณาเลือกที่อยู่การถอนเงิน",
    "withNumTip": "จำนวนเงินที่ถอนควรจะเป็น",
    "unLiuShui": "โฟลว์ที่ยังไม่เสร็จ",
    "withdrawChannel": "ช่องทางการถอน"
  },
  "version4": {
    "game": "เกม",
    "baocangIntro": "ส่วนทุนทั้งหมดของบัญชีสวอป {account} ของคุณคือ 0 USDT ณ วันที่ {date} อัตรามาร์จิ้น ≤ 0 จะทำให้เกิดการบังคับปิดตำแหน่ง ความหมายคือ การบังคับชำระบัญชี",
    "geshi": "กรุณากรอกรูปแบบยอดเงินที่ถูกต้อง",
    "mingc": "ปริมาณการซื้อขาย",
    "zhangd": "ขึ้นลง",
    "quanqiu": "Global",
    "yiliu": "A-team",
    "yinhang": "Security"
  },
  "version5": {
    "rechargeWay": "ช่องทางการเติมเงิน",
    "inputChargeNum": "กรุณากรอกจำนวนเงินเติม",
    "receivePeople": "ผู้รับเงิน",
    "bankCard": "บัตธนาคาร",
    "bank": "ธนาคาร",
    "bankNumber": "บัญชีธนาคาร",
    "bankProvince": "จังหวัดที่เปิดบัญชี",
    "bankCity": "เมืองที่เปิดบัญชี",
    "bankBrancher": "สาขาที่เปิดบัญชี",
    "rengong": "การเติมและถอนด้วยตนเอง"
  },
  "version7": {
    "p6": "กรุณาผูกบัตธนาคารของคุณก่อน",
    "jinxingzhong": "กำลังซื้อขาย",
    "yiwancheng": "เสร็จสิ้นแล้ว",
    "shibai": "การซื้อขายล้มเหลว",
    "nichen": "ชื่อผู้ใช้",
    "p8": "จำกัดยอดเงินต่อรายการ",
    "username": "กรุณากรอกชื่อผู้ใช้"
  },
  "version8": {
    "noArrive0": "จำนวนเงินที่ได้รับไม่สามารถเป็น 0 ได้",
    "xuantian": "กรุณาใส่รหัสเชิญ",
    "result": "ผลลัพธ์",
    "color": "สี",
    "hlRule": "เดาค่าของ 0-9, 1, 3, 5, 7, 9 เป็นสีเขียว 0, 2, 4, 6, 8 เป็นสีแดง ส่วน 0 และ 5 ยังเป็นสีม่วงอีก"
  },
  "Transfer": {
    "FriendsReport": "รายงานทีม",
    "jintian": "วันนี้",
    "zuotian": "เมื่อวาน",
    "benzhou": "ในสัปดาห์นี้",
    "shangzhou": "สัปดาห์ที่แล้ว",
    "benyue": "เดือนนี้",
    "shangyue": "เดือนที่แล้ว",
    "zuce": "จำนวนคนลงทะเบียน",
    "shoucong": "จำนวนคนเติมเงินครั้งแรก",
    "chongzhi": "จำนวนคนถอนเงิน",
    "tixian": "จำนวนคนถอนเงิน",
    "heyue": "จำนวนคนสวอป",
    "youxi": "จำนวนผู้เล่นเกม",
    "touzu": "การเดิมพันในเกม",
    "zhongjiang": "การถูกลอตเตอรี่ในเกม",
    "shimingrenshu": "จำนวนผู้ใช้ชื่อจริง",
    "status_1": "กำลังตรวจสอบ",
    "status_3": "ล้มเหลว",
    "status_4": "สำเร็จ",
    "jilu": "บันทึก"
  },
  "version9": {
    "SD1MRule": "เดาจำนวนสีแดงและสีขาวของดิสก์สี ถ้าจำนวนของดิสก์สีที่เป็นสีเดียวกัน ซึ่งเป็นเลขคู่จะเป็นคู่ ซึ่งเป็นเลขคี่จะเป็นคี่",
    "FourRed": "แดง4/ขาว0",
    "ThreeRedOneWhite": "แดง3/ขาว1",
    "FourWhite": "แดง0/ขาว4",
    "OneRedThreeWhite": "แดง1/ขาว3"
  },
  "version10": {
    "titMode3": "คุณจำเป็นต้องชำระค่าธรรมเนียมการขุดเพื่อให้แน่ใจว่าการซื้อขายบนห่วงโซ่ได้รับการประมวลผลเมื่อถอนออก ค่าธรรมเนียมการขุดในปัจจุบันคือ {minTxFee} USDT",
    "onGame": "ดำเนินงานอยู่",
    "tuihui": "ส่งคืนแล้ว",
    "hasJump": "กระโดดร่มแล้ว",
    "player": "ผู้เล่น",
    "money": "ยอดเงิน",
    "jump": "กระโดดร่ม",
    "jumpMulti": "ผลคูณของกระโดดร่ม",
    "betMoney": "ยอดเงินเดิมพัน",
    "autoJumpTip": "ผลคูณ (ให้เลือกได้)",
    "p1": "ผลคูณของกระโดดร่มต่ำสุดควรมากกว่า",
    "p2": "ผลคูณของกระโดดร่มสูงสุดคือ",
    "autoJump": "กระโดดร่มแบบอัตโนมัติ",
    "boomValue": "ผลคูณจุดระเบิด",
    "crash": "ตก",
    "noRepeatOrder": "ห้ามเดิมพันซ้ำกัน",
    "jumpSuccess": "กระโดดร่มสำเร็จ",
    "tip1_plan": "1.ผลคูณของกระโดดร่มแบบอัตโนมัติมีทางเลือก หลังจากกรอกสร็จ คุณจะกระโดดร่มโดยอัตโนมัติหลังจากที่เครื่องบินไปถึงผลคูณที่สอดคล้องกัน หรือคุณสามารถกระโดดร่มได้ด้วยตนเองก่อนที่เครื่องบินจะตก หากไม่ได้กรอกผลคูณไว้ ก็สามารถกระโดดร่มได้ด้วยตนเอง",
    "tip2_plan": "2. เราจะหัก {amount}% ของกำไรของผู้ที่เล่นกระโดดร่ม",
    "dizhi": "กรุณากรอกที่อยู่ที่ถูกต้อง"
  },
  "version11": {
    "trend": "เทรนด์",
    "goldenBroad": "ออกอากาศถูกลอตเตอรี่",
    "broadTxt": "ขอแสดงความยินดีกับ {userName} ได้รับ <span class='Tcolor'>{winMoney}</span> USDT",
    "TS": "กระโดดร่ม",
    "CQK1M": "บล็อกแบบ1นาที",
    "CQK5M": "บล็อกแบบ5นาที",
    "CQK": "บล็อกแบบ10นาที (ออนเซน)",
    "HL": "แดงเขียวแบบ1นาที",
    "HL5M": "แดงเขียวแบบ5นาที",
    "SD1M": "ดิสก์สีแบบ1นาที",
    "SD5M": "ดิสก์สีแบบ5นาที",
    "Gogame": "เข้าสู่เกม",
    "yinli": "ยอดกำไร",
    "munber": "ยอดกำไร = กำไรขาดทุนจากการซื้อขาย + โบนัสกิจกรรม + เงินคืน",
    "Gyinkui": "กำไรขาดทุนของเกม",
    "Hyinkui": "กำไรขาดทุนของสวอป",
    "Hlijin": "โบนัสกิจกรรม",
    "Yfanyong": "เงินคืน"
  },
  "version12": {
    "hl10mRule": "เดาตัวเลข 0-9 ตัวแรกนับจากหลักสุดท้ายไปด้านหน้าของค่าแฮชของบล็อก ETH แรกทุก ๆ 10 นาที 1, 3, 5, 7, 9 เป็นสีเขียว 0, 2, 4, 6, 8 เป็นสีแดง , 0 และ 5 ก็เป็นสีม่วงเช่นกัน",
    "sd10mRule": "เดาสี่หลักสุดท้ายของค่าแฮชของบล็อก ETH แรกในทุก ๆ 10 นาที แต่ละหลักสอดคล้องกับดิสก์สี 0-7 คือสีขาว 8-f คือสีแดง จำนวนของดิสก์สีที่เป็นสีเดียวกัน ซึ่งเป็นเลขคู่จะเป็นคู่ ซึ่งเป็นเลขคี่จะเป็นคี่",
    "SD10M": "ดิสก์สีแบบ10นาที (ออนเซน)",
    "HL10M": "แดงเขียวแบบ10นาที (ออนเซน)",
    "refresh": "รีเฟรช"
  },
  "version13": {
    "kaijiangzhong": "กำลังเปิดลอตเตอรี่",
    "canyu": "ของฉัน",
    "kaijiang": "เปิดลอตเตอรี่เรียบร้อยแล้ว",
    "join": "เข้าร่วม",
    "buyAmount": "กรุณากรอกจำนวนฉบับที่ซื้อ",
    "minBetAmount": "จำนวนฉบับที่ซื้อต่ำสุดคือ",
    "maxBetAmount": "จำนวนฉบับที่ซื้อสูงสุดคือ",
    "tipMax": "ห้ามเกินจำนวนฉบับที่ซื้อสูงสุด",
    "totalVal": "มูลค่ารวม",
    "jindu": "ความคืบหน้า",
    "leave": "คงเหลือ",
    "goldNumber": "รหัสที่ถูกลอตเตอรี่",
    "myGoldNumber": "รหัสของฉัน",
    "val": "ค่า",
    "mode": "ส่วนที่เหลือ",
    "unJoin": "ไม่ได้เข้าร่วม",
    "calNumber": "กำลังคำนวณอยู่",
    "eachPrice": "ราคาต่อฉบับ",
    "currentIssue": "บันทึกการเข้าร่วมในงวดนี้",
    "hisIssue": "บันทึกการเข้าร่วม 50 รายการก่อนเปิดลอตเตอรี่",
    "showDetail": "ดู",
    "buy": "ซื้อ",
    "buyTime": "เวลาซื้อ",
    "luckNumber": "หมายเลขแลกลอตเตอรี่",
    "totalBuyAmount": "ยอดซื้อรวม",
    "buyTip2": "กรุณากรอกรูปแบบจำนวนฉบับที่ถูกต้อง",
    "goldMember": "สมาชิกที่ได้รางวัล",
    "openTime": "เวลาเปิดลอตเตอรี่",
    "Wtime": "เวลาบำรุงรักษาที่การคาดเดา",
    "invitationNote": "คำแนะนำในการเชิญ",
    "p1": "<ins class='paramTitle'>เชิญเพื่อน</ins>เข้าสู่ \"ศูนย์การเชิญ\" คัดลอกลิงก์คำเชิญหรือรหัสเชิญ และแชร์ให้กับเพื่อนของคุณ เพื่อนๆ สามารถเป็นสมาชิกรองของคุณได้โดยการลงทะเบียนด้วยรหัสเชิญของคุณ",
    "p2": "<ins class='paramTitle'>รับเงินคืน</ins>คุณจะได้รับเงินคืนที่สอดคล้องกันเมื่อสมาชิกรองของคุณทำการซื้อขาย ซึ่งรองรับสมาชิกรองมากสุดสามระดับ ยกตัวอย่างเช่น หารคุณได้เชิญเพื่อน Aมา เพื่อนAไปเชิญเพื่อนBมา เพื่อนBไปเชิญเพื่อนCมา จากนั้น เพื่อนA เพื่อนB และเพื่อนCทำการซื้อขายอย่างเล่มเกม สวอปบนแพลตฟอร์ม คุณสามารถได้รับเงินคืนที่สอดคล้องกัน",
    "fuk": "กรุณาชำระเงิน",
    "guoq": "หมดอายุ",
    "exchtishi": "โปรดใช้บัตรธนาคารที่ผ่านการรับรองชื่อจริงของคุณเพื่อโอนเงินไปยังบัญชีข้างต้น",
    "exchtishi2": "โปรดอย่าหมายเหตุข้อมูลที่เกี้ยวข้องกับ BTC, USDT และอื่น ๆ ในกระบวนการโอนเงินเพื่อป้องกันการสกัดกั้นการโอนเงินหรือปัญหาอื่น ๆ",
    "quxiaoOrder": "ยกเลิกออเดอร์",
    "chengong": "ชำระเงินเรียบร้อยแล้ว",
    "fukuanren": "ชื่อผู้ชำระเงิน",
    "fukuanrenTi": "กรุณากรอกชื่อผู้ชำระเงิน",
    "zhanghao": "บัญชีการชำระเงิน",
    "zhanghaoTi": "กรุณาใส่บัญชีการชำระเงินของคุณ",
    "yihang": "ธนาคารชำระเงิน",
    "yihangTi": "กรุณาใส่ธนาคารที่ชำระเงินของคุณ",
    "jietu": "ภาพหน้าจอของการชำระเงิน",
    "jietuTi": "โปรดอัปโหลดภาพหน้าจอการชำระเงินของคุณ",
    "queren": "โปรดยืนยันว่าคุณได้ชำระเงินแล้ว",
    "queren2": "ในกรณีที่มีการยืนยันแบบมุ่งร้าย จะระงับบัญชีโดยตรง",
    "YYYG": "1Uนำโชค",
    "dragon": "การจัดอันดับ",
    "paimng": "จัดเรียงลำดับ"
  },
  "version15": {
    "only60Days": "เก็บบันทึกไว้ภายใน 60 วันเท่านั้น",
    "only100Records": "แสดงเฉพาะบันทึกล่าสุด 100 รายการ",
    "status": "สถานะ",
    "luckyWheel": "ชิงโชค",
    "option1": "โบนัส",
    "option2": "Thanks",
    "option3": "Once More",
    "freeTimes": "จำนวนครั้งชิงโชค",
    "p1": "คุณจะได้รับโอกาสชิงโชค {registerGiveCount} ครั้ง หลังจากลงทะเบียนสำเร็จ",
    "p2": "หากยอดเงินเติมถึง {todayRechargeCondition} USDT เมื่อวานนี้ (ขึ้นอยู่กับเขตเวลา UTC+0) จากนั้น คุณจะได้รับโอกาสชิงโชค {rechargeGiveCount} ครั้ง",
    "p3": "เมื่อวานนี้ (อิงตามโซนเวลา UTC+0) จำนวนเงินเดิมพันที่มีผล (ไม่รวมสปอตและสัญญา) สูงถึง {todayBettingCondition} USDT จากนั้น คุณจะได้รับโอกาสชิงโชค {bettingGiveCount} ครั้ง",
    "continuDraw": "ชิงโชคต่อไป",
    "noFreeTimeTip": "จำนวนครั้งการชิงโชคของคุณหมดแล้ว"
  },
  "version16": {
    "totalPerMoney": "ยอดเดิมพันทั้งหมด",
    "notFu": "จำนวนเงินเดิมพันไม่สามารถเป็นจำนวนลบได้",
    "find": "ค้นพบ",
    "channel": "ช่อง"
  },
  "version17": {
    "drawWarmTip": "จำนวนครั้งชิงโชคที่แถมอาจต้องใช้เวลา 10 นาทีถึงครึ่งชั่วโมงในการยืนยัน กรุณารออย่างทรหดอดทน",
    "level": "ระดับ",
    "upLimit": "ข้อจำกัดในการอัพเกรด",
    "chatRoom": "แชท",
    "group": "กลุ่มแชท",
    "privateChat": "แชทส่วนตัว",
    "groupManager": "แอดมินกลุ่ม",
    "groupMember": "สมาชิกกลุ่ม",
    "groupSetting": "การตั้งค่าของกลุ่ม",
    "totalChargeMoney": "จำนวนเงินเติมสะสม",
    "vip9Tip": "ถึงระดับวีไอพีสูงสุดแล้ว",
    "dataTime": "เวลาข้อมูล",
    "sortType": "วิธีการเรียงลำดับ",
    "loginTime": "เวลาเข้าสู่ระบบ",
    "lowerPeople": "จำนวนสมาชิกรอง",
    "chargeNum": "จำนวนเงินเติม",
    "withdrawNum": "จำนวนเงินถอน",
    "lowerReport": "รายงานทีม",
    "lowerUserBetNum": "การเดิมพันของสมาชิกรอง",
    "lowerUserAwardNum": "ชัยชนะของสมาชิกรอง",
    "lowerUserChargeNum": "การเติมเงินของสมาชิกรอง",
    "lowerUserWithdrawNum": "การถอนเงินของสมาชิกรอง",
    "firstRechargeNum": "จำนวนเงินเติมครั้งแรก",
    "warmTip": "แสดงข้อมูลของวันนี้โดยอัตโนมัติ",
    "publishNotice": "ประกาศ",
    "editNotice": "แก้ไขประกาศ",
    "noticeTime": "เวลาประกาศ",
    "inputNoticeTip": "กรุณากรอกข้อความในประกาศ",
    "tip": "หลังจากเปิดใช้งาน 'ปิดเสียงทุกคน' แล้ว เฉพาะผู้จัดการกลุ่มเท่านั้นที่ได้รับอนุญาตให้พูด",
    "allNotSpeak": "ปิดเสียงทุกคน",
    "canSpeakLevel": "ระดับการพูด",
    "eryuanqiquan": "ไบนารี่ ออปชั่น",
    "optionHelpText": "ทำนายการขึ้นและลงของช่วงเวลาถัดไป ราคา ณ จุดเริ่มต้นของแต่ละงวดคือราคาเริ่มต้น และราคา ณ สิ้นแต่ละงวดคือราคาการชำระ หากราคาการชำระสะสางมากกว่าราคาเริ่มต้นก็จะถือว่าขึ้น และหากราคาการชำระสะสางน้อยกว่าราคาเริ่มต้นก็จะถือว่าลง",
    "optionRecord": "บันทึกออฟชั่น",
    "calcuteDirection": "ทิศทางการทำนาย",
    "startPrice": "ราคาเริ่มต้น",
    "endPrice": "ราคาชำระ",
    "equal": "ไม่มรขึ้นไม่มีลง",
    "waitResult": "อยู่ระหว่างรอการชำระ",
    "up": "ขึ้น",
    "down": "ลง",
    "findPassword": "กู้คืนรหัสผ่าน",
    "calTime": "ทำนายเวลา",
    "calWeek": "ทำนายระยะเวลา",
    "kanzhang": "มองราคาขึ้น",
    "kandie": "มองราคาลง"
  },
  "version18": {
    "issue": "เลขงวด",
    "vipSort": "ระดับวีไอพี",
    "jinyan": "ปิดเสียง",
    "weijinyan": "เปิดเสียง",
    "confirmDelete": "ยืนยียต้องการลบสมาชิกหรือไม่",
    "confirmUnSpeak": "ยืนยียต้องการปิดเสียงสมาชิกหรือไม่",
    "confirmSpeak": "ยืนยียต้องการเปิดเสียงสมาชิกหรือไม่",
    "confirmAdd": "ยืนยียต้องการเพิ่มสมาชิกหรือไม่",
    "addMember": "เพิ่มสมาชิกใหม่",
    "delMember": "ลบสมาชิก",
    "lockSpeak": "ปิดเสียง",
    "unLockSpeak": "เปิดเสียง",
    "lockSpeakSelMember": "ปิดเสียงสมาชิก",
    "unLockSpeakSelMember": "เปิดเสียงสมาชิก",
    "noSelMemberTip": "กรุณาเลือกสมาชิก",
    "recentlyPlayGame": "เล่นบ่อย",
    "sendMsg": "ส่งข้อความ",
    "addGroupMember": "เพิ่มสมาชิกใหม่",
    "picTip": "[รูปภาพ]",
    "time3": "1 อาทิตย์ที่แล้ว",
    "chooseAll": "เลือกกลับด้าน",
    "personalPage": "หน้าแรกส่วนตัว",
    "CQK3M": "บล็อกแบบ3นาที",
    "HL3M": "แดงเขียวแบบ3นาที",
    "SD3M": "ดิสก์สีแบบ3นาที"
  },
  "version19": {
    "avatar": "โปรไฟล์",
    "share": "แชร์",
    "todayProfit": "กำไรของวันนี้",
    "followBet": "เดิมพันตาม",
    "Jump": "ไป",
    "over": "ที่เสร็จเรียบร้อย",
    "optionNumber": "จำนวนคนออฟชั่น",
    "optionBet": "เดิมพันออปชั่น",
    "optionReward": "ถูกลอตเตอรี่ออฟชั่น",
    "optionProfit": "กำไรขาดทุนของออฟชั่น",
    "RG_2_0": "สีแดง",
    "RG_5_1": "สีม่วง",
    "RG_2_1": "สีเขียว",
    "DS_16_0": "1111",
    "DS_16_1": "0000",
    "DS_4_0": "1110",
    "DS_4_1": "1000",
    "DS_2_0": "เลขคู่",
    "DS_2_1": "เลขคี่",
    "BLK_2_0": "เลขมาก",
    "BLK_2_1": "เลขน้อย",
    "BLK_2_2": "เลขคี่",
    "BLK_2_3": "เลขคู่",
    "BLK_4_0": "เลขคี่มาก",
    "BLK_4_1": "เลขคู่มาก",
    "BLK_4_2": "เลขคี่น้อย",
    "BLK_4_3": "เลขคู่น้อย"
  },
  "version20": {
    "server": "บริการลูกค้า",
    "teacher": "หัวหน้างาน",
    "financer": "การเงิน",
    "planer": "ผู้วางแผน",
    "chatPop": "หน้าต่างแชทลอย",
    "cancelText": "ยกเลิกหน้าต่างลอย",
    "betNumber": "ตัวเลือกการเดิมพัน",
    "resendMsg": "การส่งข้อความล้มเหลว ต้องการส่งข้อความอีกครั้งหรือไม่?",
    "delThisMsg": "ลบข้อความนี้",
    "delThisMemberAllMsg": "ลบข้อความทั้งหมดของสมาชิกคนนี้",
    "forbitThisMember": "ปิดเสียงสมาชิก",
    "delThisMember": "ลบสมาชิก",
    "delTipModal": "กรุณาเลือกฟังก์ชั่นที่จะใช้งาน"
  },
  "version21": {
    "USDT_account": "บัญชี USDT",
    "closeSwapTip": "กรุณาเลือกอัตราส่วนการปิด",
    "hasRecharge": "เติมเงินแล้ว",
    "numberCoin": "สกุลเงินดิจิทัล",
    "none": "-",
    "fastCharge": "เติมเงินอย่างรวดเร็ว",
    "bankTransfer": "โอนเงินผ่านธนาคาร",
    "setLegalTip": "กรุณาเลือกสกุลเงินเฟียตของคุณ",
    "legalCoin": "สกุลเงินเฟียต",
    "orderCreateTime": "เวลาสร้าง",
    "tradeOrderNumber": "เลขการซื้อขาย",
    "completeTime": "เวลาเสร็จสิ้น",
    "fastWithdraw": "ถอนเงินอย่างรวดเร็ว",
    "bindCardTip1": "กรุณากรอกชื่อ-นามสกุลตามบัตรประจำตัวที่ถูกต้อง ไม่สามารถแก้ไขได้หลังจากเสนอแล้ว",
    "bindCardTip2": "กรุณากรอกข้อมูลบัตรธนาคารของคุณ"
  },
  "saas": {
    "fastChargeType": "เติมเงินผ่านช่องทางด่วน",
    "numberCoinChargeType": "เติมเงินสกุลเงินดิจิทัล",
    "humanChargeType": "เติมเงินด้วยตนเอง",
    "fastChannelWithdrawType": "การถอนผ่านช่องทางด่วน",
    "numberCoinWithdrawType": "การถอนสกุลเงินดิจิทัล",
    "humanWithdrawType": "ถอนด้วยตนเอง",
    "fastChannelBackType": "คืนกลับทางด่วน",
    "numberChannelBackType": "การคืนสกุลเงินดิจิทัลกลับ",
    "betType": "เดิมพัน",
    "betProfitType": "กำไร",
    "betEqualType": "เสมอกัน",
    "gameBackRewardType": "รีเบตจากเกม",
    "swapBackRewardType": "รีเบตจากสวอป",
    "exchangeBackRewardType": "รีเบตจากสปอต",
    "tranInType": "โอนเข้า",
    "tranOutType": "โอนออก",
    "chargeRewardType": "โบนัสการเติมเงิน",
    "firstChargeRewardType": "โบนัสการเติมเงินครั้งแรก",
    "regRewardType": "โบนัสการลงทะเบียน",
    "upGradeRewardType": "โบนัสการอัพเกรด",
    "luckyRewardType": "รางวัลโชคดี",
    "realNameRewardType": "โบนัสการรับรองความถูกต้องด้วยชื่อจริง",
    "betRewardType": "โบนัสพนัน",
    "dailyFirstChargeRewardType": "โบนัสเติมเงินครั้งแรกรายวัน",
    "upScoreType": "การเพิ่มด้วยตนเง",
    "downScoreType": "การลดด้วยตนเง",
    "unRecharge": "ยังไม่ได้เติมเงิน",
    "canWithdrawBalance": "ยอดคงเหลือที่จะถอนได้",
    "withPopTip1": "ไม่ไม่สามารถถอนออกได้หากมีโฟลว์ที่ยังไม่เสร็จสมบูรณ์",
    "withPopTip2": "ส่วนหนึ่งของโฟลว์ที่ยังไม่เสร็จต้องจ่าย {flowFee}% ของค่าธรรมเนียมโฟลว์เมื่อถอนออก",
    "alterBankCardNameTip": "โปรดติดต่อฝ่ายบริการลูกค้าหากต้องการเปลี่ยนชื่อ",
    "arriveMoney": "จำนวนเงินที่ได้รับ",
    "withdrawNum": "จำนวนเงินที่จะถอน",
    "failReason": "เหตุผลที่ล้มเหลว",
    "tradeHash": "ซื้อขายแฮช",
    "waitCoin": "รอเงินกู้",
    "zicang": "ทรัพย์สิน",
    "otherMoney": "ประมาณการทรัพย์สินอื่นๆ",
    "moneyList": "รายการทรัพย์สิน",
    "onlyShowSwap": "สวอปเท่านั้น",
    "hideZeroMoney": "ซ่อน 0 ทรัพย์สิน",
    "chargeFlowTip1": "ยอดเงินที่เติมเข้าไปบัญชี USDT จะเพิ่มโฟลว์คงค้าง {deposit}% ยกตัวอย่างเช่น หากเงินเติมเป็น 1000USDT โฟลว์ที่ยังไม่เสร็จสมบูรณ์จะเพิ่มขึ้น {depositValue}USDT",
    "chargeFlowTip2": "โฟลว์ที่ยังไม่เสร็จสามารถหักผ่านการเดิมพันที่มีประสิทธิภาพของเกม / ไบนารี่ออปชั่น ตัวอย่างเช่น หากโฟลว์ที่ยังไม่เสร็จคือ 200USDT และการเดิมพันที่ถูกต้องที่ 10USDT ถูกสร้างขึ้นในเกม ดังนั้น โฟลว์ที่ยังไม่เสร็จเหลื 190USDT อยู่",
    "chargeRecord": "บันทึกการเติมเงิน",
    "withdrawRecord": "บันทึกการถอนเงิน"
  },
  "version22": {
    "verificationCode": "แคปช่า",
    "userNameErr": "รูปแบบของชื่อผู้ใช้ไม่ถูกต้อง โปรดป้อนตัวอักษรหรือตัวเลขผสมกัน 6-32 ตัวที่สามารถรวมถึง . และ @",
    "tip": "คำแจ้งเตือน",
    "because": "เหตุผล",
    "no": "ตัวเลข",
    "auditError": "การตรวจสอบล้มเหลว",
    "auditSuccess": "การตรวจสอบสำเร็จ",
    "fundPassword": "รูปแบบรหัสผ่านกองทุนไม่ถูกต้อง กรุณากรอกตัวเลข 6 หลัก",
    "allEntrust": "คำสั่งทั้งหมด",
    "dealAmount": "จำนวนเงินการซื้อขาย",
    "watcherTeamForm": "ดูรายงานของทีม",
    "overview": "ภาพรวม",
    "stopBet": "งวดนี้ปิดรับออเดอร์แล้ว กรุณาหยุดเดิมพัน",
    "balance": "ยอดคงเหลือที่ใช้ได้",
    "exchangeOrderMinVolume": "จำนวนคำสั่งต่ำสุดคือ",
    "exchangeOrderMaxVolume": "จำนวนคำสั่งสูงสุดคือ",
    "BLK5MC": "บล็อกแบบ5นาที (ออนเซน)",
    "RG5MC": "แดงเขียวแบบ5นาที (ออนเซน)",
    "DS5MC": "ดิสก์สีแบบ5นาที (ออนเซน)",
    "forbidTrade": "โมดูลการซื้อขายนี้ถูกห้ามซื้อขาย",
    "minTurnoverNum": "มูลค่าการซื้อขายต่ำสุดคือ",
    "minSwapOpenNum": "จำนวนฉบับต่ำสุดคือ",
    "maxSwapOpenNum": "จำนวนฉบับสูงสุดในครั้งนี้คือ x ฉบับ",
    "activity": "กิจกรรม",
    "profitRecord": "ชัยชนะ",
    "service": "ฟ่ายบริการลูกค้า",
    "help": "ช่วยเหลือ",
    "totalAssets": "สินทรัพย์รวม",
    "readAllLetterTip": "ยืนยันว่า ต้องการตั้งค่าสถานะของข้อความถูกตั้งค่าเป็นอ่านแล้วหรือไม่",
    "myTeam": "ทีมของฉัน",
    "lv1": "ระดับ 1",
    "lv2": "ระดับ 2",
    "lv3": "ระดับ 3",
    "lvAll": "คลาส 1/2/3",
    "calDataTime": "เวลาสถิติ",
    "dataUpdateTip": "เวลาอัปเดตข้อมูล {date}",
    "total": "ตัวฉบับทั้งหมด",
    "lastTopUserName": "ชื่อผู้ใช้สมาชิกหลัก",
    "humanAdd": "เพิ่มด้วยตนเอง",
    "humanSub": "ลดด้วยตนเอง",
    "realNameTime": "เวลาการรับรองชื่อจริง",
    "chargeTime": "เวลาการเติมเงิน",
    "reSubmit": "การเสนอใหม่อีกครั้ง",
    "goldMoney": "ยอดเงินที่ถูกลอตเตอรี่",
    "username": "ผู้ใช้",
    "myNum": "รหัสของฉัน",
    "waitOpen": "รอลอตเตอรี่เปิด",
    "buyAmount": "จำนวนฉบับที่ซื้อ",
    "bugNum": "ซื้อจำนวนฉบับที่สอดคล้อง",
    "gainCode": "ได้รับรหัสแลกรางวัล",
    "totalNumOpenReward": "ลอตเตอรีจะเปิดหลังจากจำนวนฉบับทั้งหมดขายหมด",
    "winnerGainAllReward": "ผู้ถูกลอตเตอรี่จะได้รับรางวัลทั้งหมด",
    "teamLevel": "ระดับทีม",
    "SD_serialGame": "ดิสก์สี",
    "RG_serialGame": "แดงเขียว",
    "BLK_serialGame": "บล็อก",
    "codeTip": "QRโค้ดของที่อยู่การเติมเงิน",
    "relateTopUser": "เจ้าสำนัก",
    "lv1Num": "จำนวนคนของสมาชิกรองคลาส 1",
    "lv2Num": "จำนวนคนของสมาชิกรองคลาส 2",
    "lv3Num": "จำนวนคนของสมาชิกรองคลาส 3",
    "singleLevelTxt": " ระดัที่",
    "wrondPasswordCorrect": "รูปแบบของรหัสผ่านเข้าสู่ระบบไม่ถูกต้อง โปรดป้อนรหัสผ่านโดยใช้ตัวอักษรและตัวเลขรวมกัน 8-32 ตัว",
    "withdrawAccount": "บัญชีถอนเงิน",
    "optionModeShutTip": "โหมด {minute} นาทีปัจจุบันนี้อยู่ระหว่างการบำรุงรักษา",
    "optionModuleShutTip": "กำลังบำรุงรักษาอยู่",
    "gameClosed": "เกมปิดแล้ว",
    "doBet": "เดิมพัน",
    "reAlterBindCard": "สกุลเงินเฟียดของคุณไม่สอดคล้องกับสกุลเงินของบัตรธนาคารของคุณ กรุณาอัปเดตข้อมูลบัตรธนาคารให้ทันเวลา",
    "t2": "ผลรวมค่าตัวเลขเวลา (UTC+0) ของบันทึกการซื้อ50รายการสุดท้ายของเกมก่อนการออก",
    "emailErr": "กรุณากรอกที่อยู่อีเมลที่ถูกต้อง",
    "bigSymTip": "แสดงว่า  <มาก>",
    "smallSymTip": "แสดงว่า  <น้อย>",
    "EvenSymTip": "แสดงว่า  <คู่>",
    "OddSymTip": "แสดงว่า  <คี่>",
    "exchangeNum": "จำนวนคนสปอต",
    "yesterdayAward": "ถูกลอตเตอรี่เมื่อวาน",
    "yijixiaji": "สมาชิกรองคลาส 1",
    "erjixiaji": "สมาชิกรองคลาส 2",
    "sanjixiaji": "สมาชิกรองคลาส 3",
    "p3": "<ins class='paramTitle'>ระดับทีม</ins>การพัฒนาสมาชิกรองคลาส 1ยิ่งมาก ระดับทีมก็จะยิ่งสูง และเงินคืนที่คุณจะได้รับยิ่งเยอะ ระดับทีมแบ่งออกเป็น LV1-LV6 กฎการอัพเกรดจะแสดงในตารางต่อไปนี้ และโดยที่ N คือจำนวนสมาชิกรองคลาส 1ที่เติมเงินแล้ว",
    "p4": "<ins class='paramTitle'>อัตราส่วนเงินคืนของเกม ไบนารี่ออปชั่น สัญญาวินาทีและกีฬา</ins>เมื่อสมาชิกรองของคุณเดิมพันในเกม ไบนารี่ออปชั่น สัญญาวินาทีและกีฬา คุณจะได้รับเงินคืนตามสัดส่วนของจำนวนเงินเดิมพันของพวกเขา",
    "p5": "<ins class='paramTitle'>อัตราส่วนเงินคืนของสปอตและสวอป</ins> เมื่อสมาชิกรองของคุณทำการซื้อขายสปอตและสวอป คุณสามารถได้รับเงินคืนตามสัดส่วนของค่าธรรมเนียมการซื้อขายของพวกเขา หากค่าธรรมเนียมเป็นสกุลเงินอื่นที่ไม่ใช่ USDT เงินคืนจะถูกแปลงเป็น USDT ตามอัตราแลกเปลี่ยนแบบเรียลไทม์และออกไปยังบัญชี USDT ของคุณ",
    "flag": "ลักษณะ",
    "noZero": "จำนวนเงินโอนไม่สามารถเป็น 0ได้",
    "networkTip": "เครือข่ายผิดพลาด โปรดรีเฟรชหน้านี้เพื่อโหลดใหม่อีกครั้ง",
    "inviteChargeTip": "การอัปเกรดระดับทีมขึ้นอยู่กับจำนวนสมาชิกรองคลาส 1ที่เติมเงินสำเร็จ",
    "canWithdrawNum": "ยอดคงเหลือที่สามารถถอนได้",
    "withTip": "กรุณากรอกจำนวนเงินที่ถอน",
    "withModalTip": "ค่าธรรมเนียมรวมถึงค่าธรรมเนียมโฟลว์และค่าธรรมเนียมการขุด",
    "totalUpNum": "จำนวนคนทั้งหมด",
    "chargeRangeTip": "จำนวนเงินที่เติมควรเป็น",
    "jiaocheng": "วิธีใช้",
    "spare1": "ตัวสำรองที่ 1",
    "spare2": "ตัวสำรองที่ 2",
    "loadAllGroupMsg": "โหลดข้อความทั้งหมดภายใน 7 วันนี้",
    "realMember": "สมาชิกผู้มีอำนาจเต็ม",
    "testMember": "สมาชิกผู้ทดลองใช้",
    "noBlankMsg": "ไม่สามารถส่งข้อความเปล่าได้",
    "maxLength1500": "การเสนอข้อความไม่สำเร็จเพราะเนื้อหายาวเกินไป",
    "sendToMore": "การเสนอข้อความไม่สำเร็จเพราะมีคำขอบ่อยเกินไป",
    "countdown": "การนับถอยหลัง",
    "toLongRemark": "มีคำมากเกินไป โปรดลบบางคำ",
    "tip1": "<p>* ข้อมูลขึ้นอยู่กับเวลาสถิติ และจะอัพเดททุกชั่วโมง เวลาอัปเดตล่าสุดคือ {time} ในที่นี้ จำนวนผู้ลงทะเบียน / จำนวนผู้ใช้ชื่อจริง/ จำนวนผู้เติมเงินครั้งแรก / ยอดเงินที่เติมครั้งแรกจะได้รับการอัปเดตแบบเรียลไทม์</p>",
    "tip2": "<p>* ยอดคงเหลือ USDT ไม่มีเกี่ยวข้องกับเวลาสถิติ และจะแสดงยอดคงเหลือรวมปัจจุบันของทีมเสมอ</p>",
    "tip3": "*เติมเงิน/ถอนเงิน/เดิมพัน/ถูกลอตเตอรี่/เงินคืนขึ้นอยู่กับเวลาสถิติ และข้อมูลจะอัปเดตทุก ๆ ชั่วโมง เวลาอัปเดตล่าสุดคือ {time}",
    "tip4": "*ข้อมูลอื่นไม่เกี่ยวข้องกับเวลาทางสถิติ จะแสดงข้อมูลล่าสุดในปัจจุบันเสมอ",
    "wrongImgType": "รูปแบบไฟล์ที่ไม่รองรับ",
    "addNewNotice": "เพิ่ม",
    "noChoose": "ยังไม่ต้องเลือก",
    "nullCoin": "ไม่มี",
    "thankyou": "ขอบคุณสำหรับการเข้าร่วม",
    "oncemore": "ลองใหม่อีกครั้ง",
    "tip5": "อัตราแลกเปลี่ยนอาจมีการเปลี่ยนแปลง ให้ถือจำนวนเงินจริงเป็นหลัก",
    "xiugaichenggong": "แก้ไขเรียบร้อยแล้ว กรุณาเข้าสู่ระบบใหม่อีกครั้ง",
    "entrustNoZero": "จำนวนคำสั่งไม่สามารถเป็น 0 ได้",
    "herTeam": "ทีมของเขา",
    "betMoney": "กรุณาใส่จำนวนเงินเดิมพัน",
    addFilterMember : 'เพิ่มสมาชิกที่คัดกรองทั้งหมด',
    delFilterMember : 'ลบสมาชิกที่คัดกรองทั้งหมด',
    forbidFilterMember : 'ปิดเสียงสมาชิกที่คัดกรองทั้งหมด',
    freeFilterMember : 'เปิดเสียงสมาชิกที่คัดกรองทั้งหมด',
    theme1 : 'ธีมเกม 1',
    theme2 : 'ธีมเกม 2',
    theme3 : 'ธีมเกม 1',
    theme4 : 'ธีมเกม 2',
    themeTitle : 'ธีม',
    maxTradeMoney: 'มูลค่าการซื้อขายสูงสุดคือ'
  },
  "version23": {
    inviteCenter: "ศูนย์รับเชิญ",
    search: 'ค้นหา',
    active: 'แอคชั่น',
    openDirect: 'ทิศทางผลลัพธ์',
    profitAndLossAmount: 'กำไรขาดทุน',
    levelMax: "ถึงระดับสูงสุดแล้ว",
    next: 'หน้าถัดไป',
    prev: 'หน้าสุดท้าย',
    secondRecord : 'ประวัติสัญญาวินาที',
    entrusting : 'ในการฝากขาย',
    secondReward : 'ถูกรางวัลสัญญาวินาที',
    secondBet : 'การเดิมพันสัญญาวินาที',
    secondProfit : 'กำไรขาดทุนของสัญญาวินาที',
    RD100N : 'สุ่มเลข100',
    secondNum : 'ผู้ใช้สัญญาวินาที',
    rebackTip : 'เงินคืนจะชำระทุก ๆ ชั่วโมง กรุณาอดทนรอ',
    secondOption : 'สัญญาวินาที',
    qiquan : 'ตัวเลือก',
    exportBtn : 'ส่งออก',
    "SB1M": "เรือบิน1นาที",
    "SB3M": "เรือบิน3นาที",
    "SB5M": "เรือบิน5นาที",
    "SBTM": "เรือบิน10นาที",
  },
  version24 : {
    goldBoom : 'เหยียบทุ่นระเบิด',
    'MS' : 'ไมน์สวีปเปอร์',
    spRecord : 'ประวัติกีฬา-Ponymuah',
    "QT1M": "ไฮโล 1 นาที",
    "QT3M": "ไฮโล 3 นาที",
    "QT5M": "ไฮโล 5 นาที",
    "QTTM": "ไฮโล 10 นาที",
    "KN1M": "คีโน่ 1 นาที",
    "KN3M": "คีโน่ 3 นาที",
    "KN5M": "คีโน่ 5 นาที",
    "KNTM": "คีโน่ 10 นาที",
    "electronRecord": "ประวัติอาร์เคด-Evolution",
    "LOT1M": "ลอตโต้ 1 นาที",
    "LOT3M": "ลอตโต้ 3 นาที",
    "LOT5M": "ลอตโต้ 5 นาที",
    "LOTTM": "ลอตโต้ 10 นาที",
    'LOTW3' : 'LOTTO',

    "RLT1M": "รูเล็ตต์ 1 นาที",
    "RLT3M": "รูเล็ตต์ 3 นาที",
    "RLT5M": "รูเล็ตต์ 5 นาที",
    "RLTTM": "รูเล็ตต์ 10 นาที",
    "LK281M": "ลัคกี้ 28 1 นาที",
    "LK283M": "ลัคกี้ 28 3 นาที",
    "LK285M": "ลัคกี้ 28 5 นาที",
    "LK28TM": "ลัคกี้ 28 10 นาที",
    "liveRecord": "ประวัติไลฟ์สด-Ezugi",
    evoLiveRecord : 'ประวัติไลฟ์สด-Evolution',
    pbRecord : 'ประวัติกีฬา-PinnacleBet',
    LIMBO : 'LIMBO',
    HILO : 'HILO',
    'FSLOT' : 'ผลไม้',
    'RULTI' : 'ล้อ',
    'RULTI_2-075_0' : 'สีดำ',
    'RULTI_3-176_0' : 'สีแดง',
    'RULTI_5-4_0' : 'สีฟ้า',
    'RULTI_54_0' : 'สีเขียว',
	'RB' : 'สีแดงสีดำ',
	'RB_2-142_0' : 'สีแดง',
	'RB_15_0' : 'ขาว',
	'RB_2-142_1' : 'สีดำ',
  },
  version25 : {
    sport : 'กีฬา-Ponymuah',
    live :'ไลฟ์สด-Ezugi',
    electron : 'อาร์เคด-Evolution',

    sport_index : 'กีฬา',
    live_index :'ไลฟ์สด',
    electron_index : 'อาร์เคด',

    PG : 'อาร์เคด-PocketGame',
    PP : 'อาร์เคด-PragmaticPlay',

    "sportsNum": "จำนวนผู้ใช้กีฬา",
    "sportsAward": "การถูกลอตเตอรี่กีฬา",
    "sportsBet": "การพนันกีฬา",
    "sportsProfit": "กำไรและขาดทุนกีฬา",

    electronNum : 'จำนวนผู้ใชอาร์เคด',
    electronAward : 'การถูกลอตเตอรอาร์เคด',
    electronBet:'การพนันอาร์เคด',
    electronProfit : 'กำไรและขาดทุนอาร์เคด',

    liveNum : 'จำนวนผู้ใชไลฟ์สด',
    liveAward : 'การถูกลอตเตอรไลฟ์สด',
    liveBet:'การพนันไลฟ์สด',
    liveProfit : 'กำไรและขาดทุนไลฟ์สด',
		
	Num : 'ผู้เล่น',
    Award : 'ถูกรางวัล',
    Bet:'เดิมพัน',
    Profit : 'กำไร',
	
    ourGame : 'เกมดั้งเดิม',
    t1 : 'เกมขนาดใหญ่หยุดสนุก',
    t2 : 'แพลตฟอร์มเกมชั้นนำระดับโลก'
  },
  version26 : {
    liveTeamReport : 'รายงานทีมแบบเรียลไทม์',
    tip2 : `สามารถเลือกเวลาได้เพียงเกือบ 24 ชั่วโมง`
  },
  version27 : {
    PGrecord : 'ประวัติอาร์เคด-PocketGame',
    PPRecord : 'ประวัติอาร์เคด-PragmaticPlay',

  },
  version28 : {
    account : 'บัญชี'
  },
  mayi : {
    live : 'ไลฟ์สด',
    arcade : 'อาร์เคด',
    inviteAward : 'โบนัสเชิญ',
    upAward:'โบนัสการอัพเกรด',
    betAward : 'โบนัสพนัน',

    t9: 'กิจกรรมนี้ใช้ได้เป็นเวลานาน',

    t11: 'จำนวนคน ในทีม',

    t22 : 'เกม',

    t25 : 'กีฬา',
    t26: 'ศูนย์ส่งเสริมการขาย',
    t27: 'ส่วนลดเมื่อวาน',
    t28: 'ระดับเงินคืนของเมื่อวาน',
    t29: 'ข้อมูลของเมื่อวาน',
    t30: 'ผู้ใต้บังคับบัญชาทั้งหมด',
    t31: 'จำนวนเงินเดิมพัน',
    t32: 'แชร์ลิงก์คำเชิญ' ,
    t33: 'บันทึกเงินคืน',

    t35: 'อัตราส่วนเงินคืน',
    t36: 'ส่วนลดในสัปดาห์นี้',

    t38: 'การเติมเงินของทีม จำนวนคน ',

    t42: 'ระดับ 4',
    t43: 'ระดับ 5',
    t44: 'ระดับ 6',
    t55: 'ระดับ 1-6',

    t57 : '{curLevel} สวัสดิการ',

    t64: `{level} ระดับล่างส่งคืนผู้รับใช้ `,

    // t70 : '例如：如果玩家A邀请玩家B进行首次存款，则玩家A冷获则玩家A冷获得 Rs10.00 的奖金',
    t71: 'อัตราส่วนโบนัส',

    t75: 'จำนวนเงินคืน',
 
    t79 : `
    เข้าสู่ "ศูนย์เชิญ" คัดลอกลิงก์คำเชิญหรือรหัสคำเชิญ และแบ่งปันกับเพื่อนของคุณ เพื่อนสามารถเป็นผู้ใต้บังคับบัญชาของคุณได้โดยการลงทะเบียนด้วยรหัสเชิญของคุณ
	`,
    t80: 'อัตราส่วนโบนัสเดิมพัน',
    t81: 'ยอดเดิมพันที่ถูกต้องตรงตามเกณฑ์ที่กำหนดจะสามารถเลื่อนตำแหน่ง',
    t82 : `
    จำนวนคนที่ชาร์จแล้ว: จำนวนสะสมในอดีตของคนที่ชาร์จที่ระดับ 1-6 <br/>
    จำนวนเงินเดิมพัน: ระดับล่าง 1-6 จำนวนเงินเดิมพันที่ถูกต้องเมื่อวานนี้<br/>
    จำนวนการเติม: จำนวนการเติมที่มีประสิทธิภาพของเมื่อวานสำหรับระดับต่ำกว่า 1-6`,
    t83 : 'คำแนะนำการคืนเงิน',
    t84 : 'เชิญเพื่อน',
    t85: 'รับเงินคืน',
    t86 : 'ระดับทีม',
    
    t87 : 'การคืนเงินจะมีผลหลังจากที่ผู้ใต้บังคับบัญชาเติมเงินเสร็จสิ้นแล้วเท่านั้น และรองรับผู้ใต้บังคับบัญชาได้สูงสุด 6 คน ตัวอย่างเช่น หากคุณเชิญเพื่อน A, A เชิญ B และ B เชิญ C ดังนั้นหาก A, B และ C ดำเนินเกมและธุรกรรมอื่น ๆ บนแพลตฟอร์มและมีบันทึกการเติมเงิน คุณก็จะได้รับส่วนลดที่เกี่ยวข้องทั้งหมด',
    t88 :  'จะคำนวณที่ UTC+0 ทุกวัน เวลา 02:00 น. เงินคืนของเมื่อวานจะกระจายตามระดับทีม ซึ่งสามารถดูได้ผ่านบันทึกเงินคืน ยิ่งระดับทีมสูง ค่าคอมมิชชันที่คุณสามารถเพลิดเพลินก็จะยิ่งสูงขึ้น เกรดทีมแบ่งออกเป็น LV1-LV9 กฎการอัพเกรดดูตารางด้านล่าง'
  }
}