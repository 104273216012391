import { get, post } from './http'
import request from "@/request/http";
// export const apiAddress = params => post('/api/posts', params);

// export const apiCat = params => get('https://api.thecatapi.com/v1/images/search', params);


export const tenantinfo = params => get('/api/rocket-api/tenantinfo/get', params);
export const getLanguageList = params => get('/api/rocket-api/langconfig/list', params);

export const login = params => post('/api/rocket-api/member/login', params);

export const setting = params =>  get('/api/rocket-api/member/info', params);

export const getMyInvitationList = params => post('/api/rocket-api/invite/report/my/team', params);

export const memberInfo = params => post('/api/rocket-api/member/invite/info', params);

export const config = params => post('/api/rocket-api/member/invite/config', params);// 获取邀请说明信息

export const getLowerOptionRecord = params => post('/api/rocket-api/option/invite/order', params);// 获取期权记录
export const getLowerSecondRecord = params => get('/api/rocket-api/second/invite/order/list', params);// 获取期权记录
export const getRabateRatio = params => get('/api/rocket-api/member/invite/rebate/config', params);// 获取期权记录

export const coinList = params => get('/api/rocket-api/contract/market/symbol-thumbs', params);// 获取交易对
export const getUpConfig = params => get('/api/rocket-api/member/invite/upgrade/config', params);// 获取交易对

export const allWallet = params => get('/api/rocket-api/wallet/list', params);  // 获取币种

export const getTradeDetailType = params => post('/api/rocket-api/trans/type/list', params);

export const transactionDetail = params => post('/api/rocket-api/member/invite/transaction', params); // 交易明细

export const getGameList = params => post('/api/rocket-api/game/init/get', params);

export const getLowerGameRecord = params => post('/api/rocket-api/rebaterecord/list', params);
export const getPPLowerGameRecord = params => get('/api/rocket-api/outer-pp/invite/order/list', params);
export const getEvoLiveLowerGameRecord = params => get('/api/rocket-api/outer-evo-live/invite/order/list', params);
export const getPBLowerGameRecord = params => get('/api/rocket-api/pbSportOrder/invite/list', params);

export const getOrderDetail = params => get('/api/rocket-api/game/order/detail', params);

export const getOptionSummary = params => post(`/api/rocket-api/invite/report/team/option/summary`, params);
export const getPGSummary = params => post(`/api/rocket-api/invite/report/team/pg/summary`, params);
export const getEvoLiveSummary = params => post(`/api/rocket-api/invite/report/team/evo-live/summary`, params);
export const getPPSummary = params => post(`/api/rocket-api/invite/report/team/pp/summary`, params);
export const getPBSummary = params => post(`/api/rocket-api/invite/report/team/pbSport/summary`, params);
export const getSecondSummary = params => post(`/api/rocket-api/invite/report/team/second/summary`, params);
export const getGameSummary = params => post(`/api/rocket-api/invite/report/team/game/summary`, params);
export const getFinanceSummary = params => post(`/api/rocket-api/invite/report/team/financial/summary`, params);
export const getSwapAndExchangeSummary = params => post(`/api/rocket-api/invite/report/team/contract/summary`, params);
export const getGameNum = params => post("/api/rocket-api/invite/report/team/game/num", params);
export const getOptionNum = params => post("/api/rocket-api/invite/report/team/option/num", params);
export const getPGNum = params => post("/api/rocket-api/invite/report/team/pg/num", params);
export const getPPNum = params => post("/api/rocket-api/invite/report/team/pp/num", params);
export const getPBNum = params => post("/api/rocket-api/invite/report/team/pbSport/num", params);
export const getEvoLiveNum = params => post("/api/rocket-api/invite/report/team/evo-live/num", params);
export const getSecondNum = params => post("/api/rocket-api/invite/report/team/second/num", params);
export const getExchangeNum = params => post("/api/rocket-api/invite/report/team/exchange/num", params);
export const getChargeNum = params => post("/api/rocket-api/invite/report/team/recharge/num", params);
export const getChargeDataRealTime = params => post("/api/rocket-api/invite/report/team/realtime/recharge", params);

export const getWithdrawNum = params => post("/api/rocket-api/invite/report/team/withdraw/num", params);
export const getWithdrawDataRealTime = params => post("/api/rocket-api/invite/report/team/realtime/withdraw", params);

export const getFirstChargeNum = params => post("/api/rocket-api/invite/report/team/first/num", params);
export const getRealNameNum = params => post("/api/rocket-api/invite/report/team/real/num", params);
export const getRegisterNum = params => post("/api/rocket-api/invite/report/team/register/num", params);
export const getSwapNum = params => post("/api/rocket-api/invite/report/team/contract/num", params);
export const promotionStatistics = params => post("/api/rocket-api/invite/report/team/game/summary", params);

export const getRegisterPage = params => post("/api/rocket-api/invite/report/team/register/page", params);
export const getRealNamePage = params => post("/api/rocket-api/invite/report/team/real/page", params);
export const getFirstChargePage = params => post("/api/rocket-api/invite/report/team/first/page", params);
export const getChargePage = params => post("/api/rocket-api/invite/report/team/recharge/page", params);
export const getWithdrawPage = params => post("/api/rocket-api/invite/report/team/withdraw/page", params);
export const getGamePage = params => post("/api/rocket-api/invite/report/team/game/page", params);
export const getOptionPage = params => post("/api/rocket-api/invite/report/team/option/page", params);
export const getPGPage = params => post("/api/rocket-api/invite/report/team/pg/page", params);
export const getPPPage = params => post("/api/rocket-api/invite/report/team/pp/page", params);
export const getPBPage = params => post("/api/rocket-api/invite/report/team/pbSport/page", params);
export const getEvoLivePage = params => post("/api/rocket-api/invite/report/team/evo-live/page", params);
export const getExchangePage = params => post("/api/rocket-api/invite/report/team/exchange/page", params);
export const getSwapPage = params => post("/api/rocket-api/invite/report/team/contract/page", params);
export const getSecondPage = params => post("/api/rocket-api/invite/report/team/second/page", params);
export const getHelpDetail = params => get("api/rocket-api/syshelp/more/help/detail", params);


export const getServiceList = params => get(`api/rocket-api/service/get`);
export const getLowerPgGameRecord = params => get(`api/rocket-api/pgOrder/invite/list`,params);

/**
 * 文件流返回
 * @param url 接口地址
 */
export const exportBlob = (url,params) => {
    return request({
        url: url,
        method: 'get',
        responseType: 'blob',
        params
    })
}





