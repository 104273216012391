import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

import Element, {Message} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
//import moment from 'moment'

import './style/4.css'
import '@/style/index.scss'

import CustomDialog from '@/components/CustomDialog.vue'

import './icons/index.js'
import {mutations} from "@/store";
import Decimal from "decimal.js";
import VueI18n from "vue-i18n";

import en_US from "./lang/en";
import zh_CN from "./lang/zh";
import zh_hant from "./lang/zh_TW";
import vi from "./lang/vi";
import ja from "./lang/ja";
import tha from "./lang/tha";
import es from "./lang/es";
import pt from "./lang/pt";
import tr from "./lang/tr";
import idn from "./lang/idn";
import hi from './lang/hi'
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import twLocale from 'element-ui/lib/locale/lang/zh-TW';
import viLocale from 'element-ui/lib/locale/lang/vi';
import jaLocale from 'element-ui/lib/locale/lang/ja';
import thLocale from 'element-ui/lib/locale/lang/th';
import esLocale from 'element-ui/lib/locale/lang/es';
import ptLocale from 'element-ui/lib/locale/lang/pt';
import trLocale from 'element-ui/lib/locale/lang/tr-TR';
import idLocale from 'element-ui/lib/locale/lang/id';
import hiLocale from '../public/hi';

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$message = Message
Vue.prototype.$eventBus = new Vue()
Vue.prototype.toFloor = toFloor
Vue.prototype.$Decimal = Decimal
Vue.prototype.deepClone = deepClone

if (localStorage.getItem('userData')) {
  mutations.changeUserData(JSON.parse(localStorage.getItem('userData')))
}

Vue.component('CustomDialog', CustomDialog)

Vue.filter("toFloor", (number, scale) => {
  return toFloor(number, scale);
});

function deepClone(obj, hash = new WeakMap()) {
  if (obj === null) return obj; // 如果是null或者undefined我就不进行拷贝操作
  if (obj instanceof Date) return new Date(obj);
  if (obj instanceof RegExp) return new RegExp(obj);
  // 可能是对象或者普通的值  如果是函数的话是不需要深拷贝
  if (typeof obj !== "object") return obj;
  // 是对象的话就要进行深拷贝
  if (hash.get(obj)) return hash.get(obj);
  let cloneObj = new obj.constructor();
  // 找到的是所属类原型上的constructor,而原型上的 constructor指向的是当前类本身
  hash.set(obj, cloneObj);
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      // 实现一个递归拷贝
      cloneObj[key] = deepClone(obj[key], hash);
    }
  }
  return cloneObj;
}

function toFloor(number, scale = 6) {
  let d; //正负符号
  if (number < 0) {
    d = "-";
    number = Math.abs(number);
  }
  if (new Number(number) == 0) {
    if (scale) {
      let __str = "0.";
      return __str.padEnd(scale + 2, "0");
    } else return 0;
  }
  var __str = number + "";
  if (__str.indexOf("e") > -1 || __str.indexOf("E") > -1) {
    var __num = new Number(number).toFixed(scale + 1),
      __str = __num + "";
    return d
      ? d + __str.substring(0, __str.length - 1)
      : __str.substring(0, __str.length - 1);
  } else if (__str.indexOf(".") > -1) {
    if (scale == 0) {
      return d
        ? d + __str.substring(0, __str.indexOf("."))
        : __str.substring(0, __str.indexOf("."));
    }
    let l = __str.split(".")[1].length;
    if (l > scale)
      return d
        ? d + __str.substring(0, __str.indexOf(".") + scale + 1)
        : __str.substring(0, __str.indexOf(".") + scale + 1);
    else {
      let z = scale - l,
        n = 0;
      while (n < z) {
        __str += "0";
        n++;
      }
      return d ? d + __str : __str;
    }
  } else {
    let n = 0;
    __str = __str + ".";
    while (n < scale) {
      __str += "0";
      n++;
    }
    return d ? d + __str : __str;
  }
}

/*function transDateTypeToTimeStamp(type) {
  let startTime, endTime;
  switch (+type) {
    case 0:
      startTime = Date.parse(moment().format("YYYY/MM/DD"));
      endTime = startTime + 24 * 60 * 60 * 1000 - 1;
      break;
    case 1: //昨天

      startTime = Date.parse(
        moment().subtract(1, "days").format("YYYY/MM/DD")
      );
      endTime = startTime + 24 * 60 * 60 * 1000 - 1;

      break;
    case 2: //本周
      startTime = Date.parse(moment().isoWeekday(1).format("YYYY/MM/DD"));
      endTime = Date.parse(moment().format("YYYY/MM/DD")) + 24 * 60 * 60 * 1000 - 1;
      break;
    case 3: //上周
      startTime = Date.parse(moment().weekday(-7).format("YYYY/MM/DD"));
      endTime = startTime + 7 * 24 * 60 * 60 * 1000 - 1;

      break;
    case 4: //本月
      console.log(
        moment().startOf("month").format("YYYY/MM/DD"),
        "本月开始时间"
      );
      startTime = Date.parse(moment().startOf("month").format("YYYY/MM/DD"));
      endTime = Date.parse(moment().format("YYYY/MM/DD")) + 24 * 60 * 60 * 1000 - 1;
      break;
    case 5: //上月
      console.log(
        moment().startOf("month").subtract(1, "month").format("YYYY/MM/DD"),
        "上月开始时间"
      );

      startTime = Date.parse(
        moment().startOf("month").subtract(1, "month").format("YYYY/MM/DD")
      );
      endTime = Date.parse(moment().startOf("month").format("YYYY/MM/DD")) - 1;
      break;
  }

  return [startTime, endTime];
}*/


const startTime = (time)=>{
  const nowTimeData = new Date(time)
  return nowTimeData.setHours(0,0,0,0)
}

const endTime = (time)=>{
  const nowTimeData = new Date(time)
  return nowTimeData.setHours(23,59,59,999)
}

const getCurrentDate = (timestamp)=>{
  return timestamp ? new Date(timestamp) : new Date()
}

const getBeginToday = (timestamp=0)=>{
  return timestamp ? startTime(new Date(timestamp)) : new Date(new Date().toLocaleDateString()).getTime()
}
const getEndToday = (timestamp=0)=>{
  return getBeginToday(timestamp) + 24 * 60 * 60 * 1000 -1
}
const getYesterDayBeginDay = (timestamp=0)=>{
  return getBeginToday(timestamp) - 24 * 60 * 60 * 1000
}

const getYesterDayEnd = (timestamp=0)=>{
  return getBeginToday(timestamp) - 1
}

const getBeginWeek = (timestamp)=>{
  var currentDate = getCurrentDate(timestamp)
  var week = currentDate.getDay()
  var millionsecond = 24 * 60 * 60 * 1000
  var minusDay = week != 0 ? week - 1 : 6
  var monday = startTime(currentDate) - minusDay * millionsecond
  return monday
}

const getBeginLaskWeek = (timestamp=0)=>{
  /* var currentDate = getCurrentDate()
   var first = currentDate.getDate() - currentDate.getDay() - 6
   var startDate = new Date(currentDate.setDate(first))*/
  return getBeginWeek(timestamp) - 7 * 24 * 60 * 60 * 1000
}

 const getEndLastWeek = (timestamp=0)=>{
  // var currentDate = getCurrentDate()
  // var first = currentDate.getDate() - currentDate.getDay() - 6
  // var last = first + 6
  // var endDate = new Date(currentDate.setDate(last))
  return getBeginLaskWeek(timestamp) + 7 * 24 * 60 * 60 * 1000 - 1
}

const getBeginMonth = (timestamp)=>{
  var currentDate = getCurrentDate(timestamp)
  var currentMonth = currentDate.getMonth()
  var currentYear = currentDate.getFullYear()
  var firstDay = new Date(currentYear,currentMonth,1)
  return Date.parse(firstDay)
}
const getEndMonth = ()=>{
  var currentDate = getCurrentDate()
  var month = currentDate.getMonth() + 1
  var currentYear = currentDate.getFullYear()
  var endOfMontyh = new Date(currentYear,month,0)
  return endTime(endOfMontyh)
}
const getBeginLastMonth = (timestamp)=>{
  var currentDate = getCurrentDate(timestamp)
  var currentMonth = currentDate.getMonth()
  var currentYear = currentDate.getFullYear()
  var priorMontyhFirstDay = getPriorMonthFirstDay(currentYear,currentMonth)
  return Date.parse(priorMontyhFirstDay)
}
const getEndLastMonth = (timestamp)=>{
  var lastDay = getBeginMonth(timestamp) -1
  return lastDay
}
const getPriorMonthFirstDay = (year,month)=>{
  if(month === 0){
    month = 11
    year--
    return new Date(year,month,1)
  }
  month--
  return new Date(year,month,1)
}

let transDateTypeToTimeStamp = (dateType,timestamp)=>{
  let arr = []
  switch (dateType) {
    case 0 :
      arr =  [getBeginToday(timestamp),getEndToday(timestamp)]
      break;
    case 1:
      arr = [getYesterDayBeginDay(timestamp),getYesterDayEnd(timestamp)]
      break;
    case 2:
      arr = [getBeginWeek(timestamp),getEndToday(timestamp)]
      break;
    case 3:
      arr = [getBeginLaskWeek(timestamp),getEndLastWeek(timestamp)]
      break;
    case 4:
      arr = [getBeginMonth(timestamp),getEndToday(timestamp)]
      break;
    case 5:
      arr = [getBeginLastMonth(timestamp),getEndLastMonth(timestamp)]
      break;
  }
  return arr
}

Date.prototype.Format = function (fmt) {
  //author: meizz
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1
          ? k === "S"
            ? ("000" + o[k]).substr(("" + o[k]).length)
            : o[k]
          : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  return fmt;
};

Vue.filter("formatTime", function (timestamp, format = "yyyy-MM-dd hh:mm:ss") {
  if (timestamp == null || timestamp == undefined || timestamp == '') {
    return ''
  } else if (isNaN(Number(timestamp))) {
    return timestamp;
  } else {
    return new Date(timestamp).Format(format);
  }
});

Vue.prototype.transDateTypeToTimeStamp = transDateTypeToTimeStamp



// 准备翻译的语言环境信息
const messages = {
  "en-US": Object.assign(en_US, enLocale),
  "zh-CN": Object.assign(zh_CN, zhLocale),
  "zh-TW": Object.assign(zh_hant, twLocale),
  ja: Object.assign(ja, jaLocale),
  tha: Object.assign(tha, thLocale),
  vi: Object.assign(vi, viLocale),
  "es-US": Object.assign(es, esLocale),
  es: Object.assign(es, esLocale),
  pt: Object.assign(pt, ptLocale),
  tr: Object.assign(tr, trLocale),
  idn: Object.assign(idn, idLocale),
  hi : Object.assign(hi, hiLocale)
};
Vue.use(Element, {
  i18n: (key, value) => i18n.t(key,value)
});
Vue.use(VueI18n);


// 通过选项创建 VueI18n 实例
const i18n = new VueI18n({
  locale: "en-US",
  messages,
});

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')



