<template>
  <div id="app">
    <router-view />

        <!--客服-->
        <div class="customer-wrap"  @click="open">
          <!-- <img src="./assets/customIndex.svg" alt="" class="customer-icon"> -->
          <icon-svg iconClass="customer" class="module-icon customer-icon" ></icon-svg>

          <div class="seat"></div>
          <div class="tooltip-wrap" v-show="isShowCustomer"   @mouseleave="isShowCustomer = false">
            <div class="header">
              <p class="title">{{$t('version20.server')}}</p>
            </div>
            <div class="content">
              <p class="item" v-for="(item, index) in popupTxt" :key="index">{{item}}</p>
            </div>
          </div>
          <div class="triangle" v-show="isShowCustomer"></div>
        </div>


<!--    弹窗列表-->
    <div class="popModal serviceModal" v-if="showServiceModal">
      <div class="mask"></div>
      <div class="popMain">

        <h2 class="title">
           <span class="titleTxt">{{$t('version20.server')}}</span>
        <!-- <Icon src="./assets/close.svg" alt="" class="closeBtn" @click="showServiceModal=false"> -->
          <icon-svg iconClass="close" class="module-icon closeBtn" @click.native="showServiceModal=false"></icon-svg>

         </h2>
          <div class="list" >
            <div class="listItem" v-for="x in serviceList"
                 @click="goService(x)"
            >
              <img class="icon" src="./assets/kefu/01@2x.png" alt="" v-if="[2,3].includes(x.serviceType)">
              <img class="icon" src="./assets/kefu/LINE@2x.png" alt="" v-if="x.serviceType == 5">
              <img class="icon" src="./assets/kefu/Livechat@2x.png" alt="" v-if="x.serviceType == 1">
              <img class="icon" src="./assets/kefu/Messenger@2x.png" alt="" v-if="x.serviceType == 4">
              <img class="icon" src="./assets/kefu/Skype@2x.png" alt="" v-if="x.serviceType == 6">
              <img class="icon" src="./assets/kefu/tele@2x.png" alt="" v-if="x.serviceType == 7">
              <img class="icon" src="./assets/kefu/whatsapp@2x.png" alt="" v-if="x.serviceType == 8">
              <img class="icon" src="./assets/kefu/Zendesk@2x.png" alt="" v-if="x.serviceType == 0">
              <span
                      class="language"

              >{{ x.serviceTitle }}</span
              >
            </div>

          </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {coinList, getGameList, tenantinfo, getLanguageList,getServiceList} from '@/request/api.js'
  import {mutations, state} from '@/store.js'
import { Icon } from 'element-ui';
 
  export default {
    name: 'App',
    data(){
      return {
        isShowCustomer: false,
        customerServiceType: 3,
        customerServiceUrl: '',
        popupTxt: '',
        lang : '',
        serviceList : [],
        showServiceModal : false,
        languageData : [],
        theme : 'white'
      }
    },
    computed : {
      siteConfig: () => state.siteConfig,

    },
    watch: {
      '$i18n.locale': {
        handler() {
          this.getGameList();
          mutations.setLangStatus(this)
        },
        immediate: true
      }
    },
    async created() {
      await this.getLanguageList();
      await this.getCoinList();
      await this.getGameList();

    },
    mounted(){
      document.body.className = this.theme
    },
    methods: {
      doOpenService(data){
        let serviceType,serviceUrl
        if(data){
          serviceType = data.serviceType
          serviceUrl = data.serviceContexts
          this.customerServiceType = serviceType
          this.customerServiceUrl = serviceUrl
        }else{
          // serviceType = this.customerServiceType
          // serviceUrl = this.customerServiceUrl;
          this.customerServiceType = this.siteConfig.customerServiceType
          this.customerServiceUrl = this.siteConfig.customerServiceUrl
        }

        if(!this.customerServiceType && ! this.customerServiceUrl){
          this.$message.error(this.$t('common.zanwu'))
          return
        }
        let _this = this
        if(this.customerServiceType == 3){
          this.isShowCustomer = true
          this.showServiceModal = false
          this.popupTxt = this.customerServiceUrl.split('\n');
            console.log(this.popupTxt,this.customerServiceUrl, 'this.popupTxt')
            // this.isShowCustomer = true;
            this.$nextTick(() => {
              const content = document.querySelector('.tooltip-wrap .content');
              const toolTipWrap = document.querySelector('.tooltip-wrap');
              if (content.offsetHeight > 150) {
                toolTipWrap.classList.add('tooltip-wrap-bottom')
              } else {
                toolTipWrap.classList.remove('tooltip-wrap-bottom')
              }
              // this.isShowCustomer = false
            })
        }else if (this.customerServiceType == 0) {
          console.log('zendesk')
          if (!window.zE) {
            let zenDesk = document.createElement("script");
            zenDesk.id = "ze-snippet";
            zenDesk.src = serviceUrl;
            document.getElementsByTagName("head")?.[0]?.appendChild(zenDesk);
            zenDesk.onload = function () {
              try {
                // zE("webWidget", "hide");
                _this.showZenDesk();
              } catch (e) {
                console.log(e);
              }
            };
          } else {
            _this.showZenDesk();
          }
        } else if (this.customerServiceType == 1) {
          console.log('livechat')
          if (!this.hasLoadLiveChat) {
            try {
              window.__lc = window.__lc || {};
              window.__lc.license = serviceUrl;
              (function (n, t, c) {
                function i(n) {
                  return e._h ? e._h.apply(null, n) : e._q.push(n);
                }
                var e = {
                  _q: [],
                  _h: null,
                  _v: "2.0",
                  on: function () {
                    i(["on", c.call(arguments)]);
                  },
                  once: function () {
                    i(["once", c.call(arguments)]);
                  },
                  off: function () {
                    i(["off", c.call(arguments)]);
                  },
                  get: function () {
                    if (!e._h)
                      throw new Error(
                              "[LiveChatWidget] You can't use getters before load."
                      );
                    return i(["get", c.call(arguments)]);
                  },
                  call: function () {
                    i(["call", c.call(arguments)]);
                  },
                  init: function () {
                    var n = t.createElement("script");
                    (n.async = !0),
                            (n.type = "text/javascript"),
                            (n.src = "https://cdn.livechatinc.com/tracking.js"),
                            t.head.appendChild(n);
                  },
                };
                !n.__lc.asyncInit && e.init(),
                        (n.LiveChatWidget = n.LiveChatWidget || e);
              })(window, document, [].slice);
              if (window.LiveChatWidget) {
                /* var t = setInterval(() => {
                   if (document.getElementById("chat-widget-container")) {
                     clearInterval(t);
                     document.getElementById("chat-widget-container").style.display =
                             "none";
                   }
                 }, 100);*/
                function onReady() {
                  // Chat Widget is ready
                  _this.hasLoadLiveChat = true;
                  //  LiveChatWidget.call("hide");
                  _this.showLiveChat();
                }
                LiveChatWidget.on("ready", onReady);
              }
            } catch (e) {
              console.log(e);
            }
          } else {
            _this.showLiveChat();
          }
        } else {
          window.open(serviceUrl,"_blank");
        }
      },
      goService(data){
        /* Line:5
         Livechat:1
         Messenger:4
         Skype:6
         Telegram:7
         WhatsApp:8
         Zendesk:0
         弹窗:3
         链接式客服:2*/
        this.doOpenService(data)
      },
      open() {
        getServiceList().then(res=>{
          console.log(res,'res')
          if(res.code == 200){
            this.serviceList = res.data
            this.isShowCustomer = false
            if(!res.data || !res.data.length){
              //this.toast(this.$t('common.zanwu'))
              //新的客服没有设置取旧的
              this.doOpenService()
              return
            }
            if(res.data.length == 1){
              this.doOpenService(res.data[0])
            }else{
              this.showServiceModal = true
            }
          }

        })

      },
      showLiveChat() {
        console.log('showLiveChat');
        if (window.LiveChatWidget) {
          LiveChatWidget.call("maximize");
          // let _this = this;
          function onVisibilityChanged(data) {
            switch (data.visibility) {
              case "maximized":
                if (document.getElementById("chat-widget-container")) {
                  document.getElementById("chat-widget-container").style.display =
                    "block";
                }
                // _this.cancelLoading();
                break;
              case "minimized":
                // _this.cancelLoading();
                LiveChatWidget.call("hide");
                break;
              case "hidden":
                console.log("hidden");
                break;
            }
          }

          LiveChatWidget.on("visibility_changed", onVisibilityChanged);
        }
      },
      showZenDesk() {
        console.log('showZenDesk');
        // let _this = this;
        if (window.zE) {
          zE("webWidget", "show");
          zE("webWidget", "open");
          zE("webWidget:on", "userEvent", function (event) {
            if (event.action == "Web Widget Minimised") {
              zE("webWidget", "hide");
            }
            // _this.cancelLoading();
          });
        } else {
          // _this.cancelLoading();
          // this.toast("Custom Service Error");
        }
      },
      getCoinList() {
        coinList().then(res => {
          if (res.code === 200) {
            mutations.changeCoinList(res.data)
          } else {
            // this.$message.error(error)
          }
        })
      },
      getGameList() {
        getGameList({
          ver: "1.0",
        }).then(res => {
          if (res.code == 200) {
            let data = res.data.gameList.filter(item => item.status);

            data.forEach((item) => {
              item.time = null;
              // item.isShowFullHashValue = false //是否显示完整的哈希值，只有10分，和5分链上才显示完整，其余区块显示缩写
              item.isLineBlockGame = false;
              item.alias = this.getGameNameBySubservicecode(
                item.gameName,
                this
              );
              if (/1M/.test(item.gameName)) {
                item.time = 1;
              }
              if (/3M/.test(item.gameName)) {
                item.time = 3;
              }
              if (/5M/.test(item.gameName) || item.gameName == "VNLOT") {
                item.time = 5;
                if (/MC/.test(item.gameName)) item.isLineBlockGame = true;
              }
              if (/TM/.test(item.gameName)) {
                item.time = 10;
                item.isLineBlockGame = true;
              }
            });
            console.log(data)
            mutations.changeGameList(data)
          } else {
            // res.msg && this.toast(res.msg);
          }
        })
      },
      getGameNameBySubservicecode(code, vueThis) {
        var name;
        switch (code) {
          case "VNLOT":
            name = vueThis.$t("version16.yn5mGameName");
            break;
          case "CRASH":
            name = vueThis.$t("version11.TS");
            break;
          case "BLK1M":
            name = vueThis.$t("version11.CQK1M");
            break;
          case "BLK3M":
            name = vueThis.$t("version18.CQK3M");
            break;
          case "BLK5M":
            name = vueThis.$t("version11.CQK5M");
            break;
          case "BLKTM":
            name = vueThis.$t("version11.CQK");
            break;
          case "RG1M":
            name = vueThis.$t("version11.HL");
            break;
          case "RG3M":
            name = vueThis.$t("version18.HL3M");
            break;
          case "RG5M":
            name = vueThis.$t("version11.HL5M");
            break;
          case "DS1M":
            name = vueThis.$t("version11.SD1M");
            break;
          case "DS3M":
            name = vueThis.$t("version18.SD3M");
            break;
          case "DS5M":
            name = vueThis.$t("version11.SD5M");
            break;
          case "DSTM":
            name = vueThis.$t("version12.SD10M");
            break;
          case "RGTM":
            name = vueThis.$t("version12.HL10M");
            break;
          case "LK1U":
            name = vueThis.$t("version13.YYYG");
            break;
          case "BLK5MC":
            name = vueThis.$t("version22.BLK5MC");
            break;
          case "RG5MC":
            name = vueThis.$t("version22.RG5MC");
            break;
          case "DS5MC":
            name = vueThis.$t("version22.DS5MC");
            break;
          case 'RD100N':
            name = vueThis.$t('version23.RD100N')
                break
          case 'SB1M':
            name = vueThis.$t('version23.SB1M')
              break
          case 'SB3M':
            name = vueThis.$t('version23.SB3M')
            break
          case 'SB5M':
            name = vueThis.$t('version23.SB5M')
            break
          case 'SBTM':
            name = vueThis.$t('version23.SBTM')
            break
          default :
            name = vueThis.$t(`version24.${code}`)
                break;
        }
        return name;
      },
      getTenantinfo() {
        tenantinfo().then(res => {
          if (res.code === 200) {
            mutations.changeSiteConfig(res.data)
            document.title = res.data.mobileTitle ?? window.location.origin
            let link =
                    document.querySelector("link[rel*='icon']") ||
                    document.createElement("link");
            link.type = "image/x-icon";
            link.rel = "shortcut icon";
            link.href = res.data.webpageIco && res.data.webpageIco.startsWith('/') ? res.data.webpageIco : '/' + res.data.webpageIco;
            document.getElementsByTagName("head")?.[0]?.appendChild(link);
            if(state.maintainData && Object.keys(state.maintainData).length){
              this.$router.replace('/404')
              return 
            }else if(state.noAccessData && Object.keys(state.noAccessData).length){
              this.$router.push('/noAccess')
              return ;
            }
            //当前用户处在404维护页面，现在恢复访问帮他跳转到主页
            if (
              (window.location.href.indexOf("404") != -1 && !Object.keys(state.maintainData).length) ||
              window.location.href.indexOf("noAccess") != -1
            ) {
              this.$router.replace("/index");
            }
          }
        }).finally(()=>{
          document.getElementById('app').style.opacity = 1
        })
      },
      getLanguageList() {
        getLanguageList().then(res => {
          if (res.code === 200) {

            window.__version = '2.5.6'
            this.languageData = res.data

            const filterArr = res.data.filter(item => {
              return item.status === 1
            });
            const arr = filterArr.sort((a,b) => {
              return a.sort - b.sort
            });
            mutations.setLangList(arr.map(item => {
              return {
                label: item.alias,
                code: item.lang,
              }
            }));

          }else{
            document.title = window.location.origin

          }
           
        }).catch(e => {
          if (e?.code === 40301) this.$message.error(this.$t('list.butigongfuwu'))
          if (e?.code === 444) this.$message.error(this.$t('version3.maintain'))
        }).finally(()=>{
          console.log('最终取得语言数据',window.__version)
          const lang = localStorage.getItem('lang');
          console.log(lang,'缓存lang')
          const curNavLang = window.navigator.language

          if(state.langList.length){
          
            const index = state.langList.findIndex(item => {
              return item.code === lang || item.code.indexOf(lang) != -1
            })
            console.log(curNavLang,'curNavLang',index)
            const xxx = curNavLang.indexOf('-') == '-1' ? curNavLang : curNavLang.split('-').join('\\-');
            const reg = new RegExp('^[' + xxx + ']')
            console.log(reg)
            const navLangIndex = state.langList.findIndex(item => {
              return item.code === window.navigator.language || reg.test(item.code)
              || item.code.indexOf(xxx) != -1
            })
            console.log(state.langList, 'state.langList')
            console.log(navLangIndex, !!~index,'navLangIndex')
            // 默认取本地记录的语言
            if (lang && !!~index) {
              console.log("默认取本地记录的语言")
              mutations.setLang(lang, this)
            } else {
              // 默认取浏览器默认语言
              if (!!~navLangIndex) {
                mutations.setLang(state.langList[navLangIndex].code, this)
                console.log(navLangIndex, 'navLangIndex')
              } else {
                // 取语言列表第一个
                mutations.setLang(state.langList[0].code, this)
              }
            }
          }else{
            // 默认取本地记录的语言
            if (lang ) {
                mutations.setLang(lang, this)
              } else {
                // 默认取浏览器默认语言
                  mutations.setLang(curNavLang, this)
                } 
              
          }
          
             this.getTenantinfo();
        })
      }
    }
  }
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  opacity: 0;
}
.dark{
  --page-bg : #000;
  --login-dialog-bg : transparent;
  --font-color1 : #fff;
  --font-color2 : #cbccce;
  --inviteCenter-bg : rgba(25, 25, 25, 1);
  --aside-active-bg : linear-gradient(to right, rgba(234, 200, 62, 0.09), rgba(43, 36, 37, 0));
  --aside-active-fontColor : rgba(234, 200, 62, 1);
  --aside-active-indicatorBar : rgba(234, 200, 62, 1);
  --header-bg : rgba(13, 13, 13, 1);
  --aside-boxshadow : unset;
  --header-shadow : 0px 2px 4px rgba(0,0,0,.2);
  --header-border:1px solid rgba(26, 26, 26, 1);
  --invite-main-bg : transparent;
}
.white{
  --page-bg : #f6f6f6;
  --login-dialog-bg : #ffff;
  --font-color1 : #01051e;
  --font-color2 : #808191;
  --main-color1 : #355dff;
  --main-color2 : #1b1d21;
  --main-color3 : #e4e4e4;
  --chart-color1 : #ff7a68;
  --chart-color2 : #3dbaa2;
  --gradient-color1 : linear-gradient(90deg,#6278fe 0%,#5d5efe 100%);
  --gradient-color2 : radial-gradient(ellipse 143.58% 143.58% at 0% 0% ,#d080ff 0%,#6c5dd3 100%);;
  --gradient-color3 : linear-gradient(223.58deg,#86e1a0 0%,#4fbf67 100%);
  --gradient-color4 : linear-gradient(270deg,#855dfe 0%,#3eb1fd 100%);
  --gradient-color5 : linear-gradient(135.86deg,#f8cb6a 0%,#fd76b5 100%);
  --gradient-color6 : linear-gradient(223.58deg,#63acfe 0%,#1de5e1 100%);
  --inviteCenter-bg : #fff;
  --aside-active-bg : #e4e4e4;
  --aside-active-fontColor : #11142d;
  --aside-active-indicatorBar : #355dff;
  --header-bg :#fff;
  --aside-boxshadow : 13px 0px 30px rgba(174, 174, 174, 0.08);
  --header-shadow : none;
  --header-border:none;
  --invite-main-bg : #ececec;

}


@mixin realPX(){

}

/*---------------------弹窗---------------------*/


/*---------------------弹窗---------------------*/
.v-modal{
  opacity: .9 !important;
}
.popModal{

width : 100%;
height : 100%;

z-index: 4;
position: fixed;
left : 0;
top : 0;
}
.mask{
position: absolute;
left : 0;
top : 0;
width : 100%;
height : 100%;
z-index: 5;
background: #000;
opacity: .7;
}

.popMain{
width:50%;
height:55%;
// background: #fff;
border-radius:6px;
z-index: 6;
position: absolute;
left : 50%;
top : 50%;
transform: translate(-50%, -50%);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

.amusePop .popMain{
background: url("./assets/bg001@2x.png") no-repeat center center;
background-size: cover;
}

.qrCode{
width : 200px;
height : 200px;
background: #fff;
padding: 5px;
}

.title{
  display: flex;
  justify-content: space-between;
}
.closeBtn{
/* position: absolute;
right : 20px;
top : 20px; */
width : 16px;
height : 16px;
font-size: 12px;
color : #fff;
cursor: pointer;
z-index: 23;
}
.site{
font-family:PingFang SC;
color:#ffffff;
font-size:14px;
line-height:30px;
text-align:center;
margin: 30px 20px 0 20px;
}
.site p{
color : #2688fe;
text-align: center;
}


/*---------------------客服---------------------*/
.customer-wrap {
  width: 60px;
  height: 60px;
  background-color: #fff;
  /*box-shadow: 0 0 4px 2px rgba(167,167,167, .2);*/
  position: fixed;
  right: 50px;
  bottom: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 888;
  border : 1px solid #cccc;
}
.customer-wrap:hover {
  background-color:#355dff;
  border : none;
  .customer-icon{
    fill:#fff !important;
    color:#fff !important;
  }
}
.customer-wrap:active {
  background-color:#7F97F8;
  border : none;
  .customer-icon{
    fill:#fff !important;
    color:#fff !important;
  }
}

.customer-wrap-mask {
  width: 60px;
  height: 60px;
  position: fixed;
  right: 50px;
  bottom: 100px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 889;
}

.customer-icon {
  width: 30px !important;
  height: 30px !important;
  fill:#01051e !important;

}

.seat {
  width: 85px;
  min-height: 100px;
  position: absolute;
  right: 0;
}

.tooltip-wrap {
  min-width: 250px;
  background-color:#20262f;
  padding: 0 20px 20px 20px;
  /*box-shadow: 0 0 4px 2px rgba(167,167,167, .2);*/
  border-radius: 8px;
  position: absolute;
  right: 85px;
  z-index: 999;
}
.tooltip-wrap-bottom {
  bottom: -80px !important;
}
.triangle {
  border-top: 10px solid transparent;
  border-left: 15px solid #20262f;
  border-bottom: 10px solid transparent;
  position: absolute;
  right: 70px;
  top: 50%;
  transform: translateY(-50%);
}
.tooltip-wrap .header {
  padding: 10px 0;
  border-bottom: 1px dashed rgba(255,255,255, .5);
}
.tooltip-wrap .header .title {
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
  margin: 0;
}
.tooltip-wrap .content {
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
  text-align: left;
  color: #ffffff;
}
.tooltip-wrap .content .item {
  font-size: 14px;
  line-height: 20px;
  color:#a0a2a3;
  margin: 0;
  word-break: break-all;
}
/*---------------------客服---------------------*/

.serviceModal{

 .title{
   height : 180px;
   text-align: left;
   font-size: 16px;
   padding-top: 40px;
   padding-left: 30px;
   padding-right: 30px;
   background:url("./assets/invite/customBg.png") no-repeat center top;
   background-size: 100% 100%;
   margin : 0 !important;
   border-radius : 10px !important;
   overflow: hidden;
   position : relative;
   z-index: 22;
   .titleTxt{
     color:#fff;
     font-weight: normal;
   }
 }
 .list{
/*    display: flex;
   align-items: flex-start; */
   padding : 64px 40px;
   background: #fff;
   justify-content: space-between;
   flex-wrap: wrap;
   position: relative;
   margin-top: -30px;
   overflow: scroll;
   height: 300px;
   border-bottom-left-radius: 24px;
   border-bottom-right-radius: 24px;
   .listItem{
     height : 50px;
     margin-bottom: 12px;
     display: flex;
     justify-content: flex-start;
     align-items: center;
     padding-left: 15px;
     cursor: pointer;
     border:1px solid;
border-color:#e4e4e4;
border-radius:10px;
&:hover{
  border:2px solid;
border-color:#355dff;
border-radius:10px;
.language{
  color:#355dff;
}
}
     .icon{
       width : 32px;
       height : 32px;
       margin-right: 10px;
     }
     .language{
      color:#11142d;
font-size:14px;
       overflow: hidden;
       text-overflow: ellipsis;
       white-space: nowrap;
       display: block;
       width : 240px;
       text-align: left;
      
     }
   }
 }
 .popMain{
 
   width : 50vw;
//  height : 45vh;
 left : 50%;
 top : 50%;
 transform: translate(-50%,-50%);
   display: block !important;
   align-items: flex-start;
   overflow :hidden;
  border-radius : 24px;
 }
}


  ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #e4e4e4;
      border-radius: 4px;
    }
    ::-webkit-scrollbar-track {
      background-color: #f6f6f6;
    } 
</style>
