<template>
  <el-dialog :width="dialogWidth" :title="title" :visible="visible" @close="$emit('update:visible', false)" :class="{'headerBottomLine': isShowHeaderBottomLine}">
    <slot></slot>
  </el-dialog>
</template>

<script>
  export default {
    name: "CustomDialog",
    props: {
      title: {
        type: String,
        default: '温馨提示'
      },
      visible: {
        type: Boolean,
        default: false
      },
      isShowHeaderBottomLine: {
        type: Boolean,
        default: true
      },
      dialogWidth: {
        type: String,
        default: '540px'
      }
    }
  }
</script>

<style lang="scss" scoped>
  /deep/.el-dialog {
    border-radius : 10px !important;
    overflow: hidden;
  }
  /deep/.el-dialog__header {
    background: url(../assets/invite/topbanner@3x.png) no-repeat center center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height : 120px;
    background-size: 100% 100%;
    padding-left: 30px;
    display:flex;
    align-items : center;
    .el-dialog__title {
      color:#11142d;
      font-size: 18px;
      line-height: 22px;

    }
    .el-dialog__close {
      width: 16px;
      height: 16px;
      color: #000;
    }
}
/deep/.el-dialog__body {
    background-color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 0 10px 10px 10px;
  }

/*   .headerBottomLine {
    /deep/.el-dialog__header {
      border-bottom: 1px solid rgba(64, 65, 68, 1);
    }
  } */
  /deep/.btn{
    background:#355dff !important;
    color:#fff !important;
    border-radius: 10px !important;
  }
</style>