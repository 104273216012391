module.exports = {
    "nav": {
        "shouye": "Início",
        "hanqing": "Mercados",
        "jiaoyi": "Troca",
        "heyue": "Contrato",
        "wode": "Eu"
    },
    "common": {
        "login": "Conecte-se",
        "register": "Inscrever-se",
        "loginregister": "Conecte-se / Inscrever-se",
        "logout": "Sair",
        "tip": "Dica",
        "delete": "Você tem certeza que quer deletar?",
        "set": "Definições",
        "update": "Modificar",
        "more": "Mais",
        "loading": "Carregando",
        "zanwu": "Sem Dado",
        "quxiao": "Cancelar",
        "ok": "Confirmar",
        "noyanzhengma": "Insira o CAPTCHA correto",
        "clearStorage": "Limpar Cache",
        "telErr": "Insira o número de celular correto",
        "T404": "A plataforma está sendo atualizada e o acesso será restaurado posteriormente"
    },
    "loginPage": {
        "mima": "Senha",
        "passwordTxt": "Por favor insira a senha"
    },
    "homePage": {
        "charge": "Depósito",
        "pickup": "Retirar",
        "exchange": "Spot",
        "promotionaward": "Desconto"
    },
    "pageTitle": {
        "gonggaoxiangqing": "Anúncio",
        "wodezichang": "Saldos",
        "bangzhuxiangqing": "Detalhes",
        "fundpwd": "Senha do Fundo",
        "yuyanshezhi": "Língua",
        "huazhuang2": "Transferir",
        "renzheng": "Autenticação",
        "chooseAlterPhoneType": "Número de celular",
        "chooseAlterEmailType": "E-mail",
        "letterList": "Notificações",
        "appDownload": "Baixe o aplicativo",
        "buy": "COMPRAR",
        "sell": "VENDER",
        "cexiao": "Cancelar",
        "time": "Tempo",
        "type": "Tipo",
        "jiage": "Preço",
        "weituoliang": "Quantia",
        "changjiao": "Executado",
        "weituoe": "Pagamento",
        "over": "Todos carregados",
        "weituojia": "Preço confiado",
        "weituoer": "Valor total",
        "quedingchedan": "Tem certeza de cancelar?",
        "chedaning": "Cancelando",
        "jiaoyidui": "Pares de moedas",
        "fangxiang": "Direção",
        "chongzhi": "Redefinir",
        "xianjia": "Limite",
        "shijia": "Mercado",
        "plan": "Acionar",
        "quanbu": "Tudo",
        "xianjiaweituo": "Limite",
        "yichexiao": "Cancelado",
        "time1": "A hora de início deve ser anterior à hora de término",
        "time2": "A hora de início deve ser anterior à hora de término",
        "time3": "A hora de término deve ser anterior à hora atual",
        "cedancg": "Cancelado com sucesso",
        "cedansb": "Falha ao cancelar",
        "mairujiage": "Preço unitário",
        "yongxu": "SWAP",
        "zhanghu": "Equidade",
        "yugu": "Preço de Liquidação EST",
        "baozheng": "Taxa de margem",
        "kaicang": "Abrir",
        "baocang": "Queimado",
        "pincang": "Fechar",
        "zhanghu2": "Conta",
        "pingduo": "Fechar longo",
        "kaiduo": "Abrir longo",
        "pingkong": "Fechar curto",
        "kaigong": "Abrir curto",
        "cufajia": "Preço de acionamento",
        "zuiyou": "Melhor preço",
        "zhang": "Cont",
        "keyong": "Disponível",
        "kekaiduo": "Max. L",
        "kekaikong": "Max. S",
        "duocang": "Max (L)",
        "kongcang": "Max (S)",
        "kanzhang": "Grandes",
        "kandie": "Curto",
        "shuliang": "Quantia",
        "chicang": "posições",
        "dangqianweituo": "Pedidos em aberto",
        "lishiweituo": "História",
        "shouyi": "P/L",
        "shouyilv": "Razão P/L",
        "gangganbeishu": "Aproveitar",
        "chicangliang": "Posição",
        "kepingliang": "Aproveitar. Posição",
        "kaichangjun": "Preço de abertura",
        "zuixinjia": "Último preço",
        "dangqianbaozheng": "Margem Cur",
        "duotou": "Grandes",
        "kongtou": "Curto",
        "baozhengjin": "Margem",
        "weituoshuliang": "Valor confiado",
        "jiesuanweik": "Liquidação P/L",
        "chengjiaojia": "Preço final",
        "shouxufei": "Taxa",
        "xiugaichenggong": "Modificado com sucesso"
    },
    "views": {
        "wangjiPass": "Esqueceu a senha?",
        "confirmLogOut": "Tem certeza de sair?",
        "personalCenter": "Central do usuário",
        "shezhi": "Definido com sucesso",
        "yuanyouxiang": "Código de e-mail antigo",
        "newyouxiang": "Novo código de e-mail",
        "fsyouxiang": "Enviar código",
        "xyouxiang": "Novo Email",
        "loginPass": "Senha de login",
        "baocun": "Salvar",
        "fschenggong": "Enviado com sucesso",
        "inputyouxiang": "Digite seu novo endereço de e-mail",
        "inputOld": "Insira o código de verificação de e-mail antigo",
        "inputNew": "Insira o novo código de verificação de e-mail",
        "inputPass": "Digite sua senha de acesso",
        "OldPass": "Senha de login antiga",
        "inputOldPass": "Digite sua senha de login antiga",
        "NewPass": "Nova senha de acesso",
        "inputNewPass": "Digite sua nova senha de acesso",
        "queren": "Confirme a Senha",
        "email": "Código de verificação",
        "liangciPass": "As senhas inseridas são inconsistentes",
        "shuruCode": "Insira o código de verificação",
        "OldZJ": "Senha antiga do fundo",
        "inputOldZJ": "Por favor, digite sua senha de fundo antiga",
        "NewZJ": "Nova senha do fundo",
        "inputNewZJ": "Por favor, digite sua nova senha de fundo",
        "augeren": "Informação pessoal",
        "aushang": "Carregar cartão de identificação",
        "aushenghe": "Análise",
        "name": "Nome completo",
        "inputName": "Digite seu nome completo",
        "huzhao": "Número de identificação emitido pelo governo",
        "inputhuzhao": "Insira seu número de identidade, passaporte ou carteira de motorista",
        "next": "Próximo",
        "uphuzhao": "Carregue a foto do seu cartão de identificação",
        "shouhuzhao": "Carregar fotos de indivíduos com certificados",
        "tijiao": "Enviar",
        "tip1": "1. Certifique-se de que seu ID não expirou",
        "tip2": "2. O conteúdo da assinatura inclui manuscrito {hrefArry}, assinatura pessoal e data atual",
        "tip3": "3. Certifique-se de que as fotos e assinaturas pessoais estejam claramente visíveis",
        "shentijiao": "As informações foram enviadas, aguarde análise",
        "fanhui": "Retornar",
        "bangding": "Ligar",
        "inputEmail": "Indique o seu endereço de e-mail",
        "inputPhone": "Insira seu número de telefone",
        "kefu": "Entre em contato com o atendimento ao cliente"
    },
    "list": {
        "wufaxiugia": "Você não pode modificar várias quando tiver posições ou ordens não negociadas",
        "submittip1": "Para garantir a segurança de seus fundos, realize a autenticação de nome real primeiro",
        "submittip2": "Para garantir a segurança de seus fundos, defina primeiro a senha do fundo",
        "shuruweituo": "Insira o preço confiado",
        "shuruweituoliang": "Insira o valor confiado",
        "dayukekai": "O valor não pode ser maior que Max. L",
        "dayuduocang": "O valor não pode ser maior que Max (S)",
        "dayukekaikong": "A quantidade não pode ser superior a Max. S",
        "dayukongcang": "O valor não pode ser superior a Max (L)",
        "shuruchufa": "Insira o preço de ativação",
        "zhangfubang": "Classificação",
        "liang": "Volume",
        "safar": "Clique no botão abaixo no Safari",
        "kuaisuanzhuang": "escolha [Adicionar à Tela de Início]",
        "noAccount": "Ainda não tem conta?",
        "LoginReg": "Registro",
        "butigongfuwu": "Por favor, entenda que seu IP ou serviço local não está disponível no momento",
        "renzheng1": "Verificado",
        "renzheng2": "Falha ao verificar",
        "renzheng3": "Em análise",
        "renzheng4": "Verificar",
        "shenheing": "Em análise, aguarde pacientemente",
        "inputzaicimima": "Digite a senha novamente",
        "xuantian": "Insira o código de convite (opcional)",
        "yuedu": "Eu li e concordo ",
        "yonghuxieyi": "Termos de Uso",
        "hasAccount": "Já registrado?",
        "denglu": "Conecte-se agora",
        "gouxuanxieyi": "Por favor, leia e verifique o Contrato de Serviço do Usuário",
        "inputzijinmima": "Por favor, digite a senha do seu fundo",
        "yijianfankui": "Comentários",
        "gao": "Alto",
        "di": "Baixo",
        "shengdu": "Livro de pedidos",
        "chengjiao": "Preenchidos",
        "zixuan1": "Removido dos favoritos",
        "zixuan2": "Falha ao remover",
        "zixuan3": "Falha ao remover",
        "zixuan4": "Falha ao adicionar",
        "jiaoyie": "Quantia total",
        "chengjiaozonger": "Quantia total",
        "chengjiaojun": "Preço médio",
        "chengjiaoliang": "Volume",
        "bigmairu": "Excedendo o valor máximo disponível",
        "weituochenggong": "Negociado com sucesso",
        "weituoshibai": "Falha ao negociar",
        "yue": "Saldo",
        "bizhong": "Moeda",
        "huazhuanshuliang": "Quantia da transferência",
        "inputhzsl": "Insira a quantia da transferência",
        "bunengweifu": "A quantia da transferência deve ser maior que 0",
        "dayuhuazhuan": "A quantia da transferência não pode ser maior que o valor disponível em sua conta",
        "huazhuanchenggong": "Transferido com sucesso",
        "huazhuangshibai": "Falha ao transferir"
    },
    "user": {
        "guizeshuoming": "Regras",
        "shenheweitongguo": "Falha ao verificar",
        "shiming": "Por favor, complete a autenticação de nome real",
        "fuzhichenggong": "Copiado com sucesso",
        "frozen": "Congelados",
        "zhanghuquanyi": "Equidade",
        "baozhenjin": "Aproveitar. Margem",
        "jiaoyishijian": "Hora da transação"
    },
    "tradePage": {
        "CUSTOM": "Favoritos"
    },
    "chart": {
        "minute": "Minuto"
    },
    "charge": {
        "congbi": "Endereço de depósito",
        "baocun": "Salvar código QR"
    },
    "heyuePage": {
        "yijianpingcang": "Fechar Flash",
        "copyerr": "Falha ao copiar",
        "pincang": "O cont da posição de fechamento não pode ser 0"
    },
    "yaoqin": {
        "zuce": "Data de registro",
        "showPersonInfo": "Veja seu perfil",
        "myinvitelink": "Link do Convite",
        "my_column4": "Classe",
        "yaoqing": "Convite",
        "dengji": "Progresso do Nível",
        "mubiao": " Alvo",
        "zong": "Descontos totais",
        "jiaoyi": "Transações",
        "wode": "Código de Convite",
        "huodong": "Bônus de Atividade"
    },
    "version3": {
        "hash": "Valor de Hash",
        "betTip": "Adivinhe o último bit do valor de hash do primeiro bloco ETH a cada 10 minutos, 0-7 são pequenos, 8-f são grandes, 0, 2, 4, 6, 8, a, c e e são pares, 1, 3 , 5, 7, 9, b, d e f são ímpares.",
        "betTip2": "Acho que o último bit do valor de hash, 0-7 são pequenos, 8-f são grandes, 0, 2, 4,6, 8, a, c e e são pares, 1, 3, 5, 7, 9, b , d e f são ímpares.",
        "betMoneyTip": "Quantia por aposta",
        "doBet": "Aposte Agora",
        "issue": "No.",
        "blockHigh": "Altura do Bloco",
        "blockTime": "Tempo de Bloqueio",
        "hash2": "Valor de Hash",
        "gameRecord": "Registros de Jogos",
        "betTime": "Hora das Apostas",
        "pickUpAddress": "Endereço de Retirada",
        "arriveAccountNum": "Montante Recebido",
        "remark": "Observação",
        "contachService": "Entre em contato com os serviços ao cliente",
        "totalBetMoney": "Apostas Totais",
        "unOpen": "Sem resultado",
        "maintain": "Manutenção",
        "hasBingGo": "Ganhou",
        "unBingGo": "Perdido",
        "outOfBalance": "O equilíbrio não é suficiente",
        "chooseBetNumTip": "Selecione a opção de aposta",
        "chooseBetMoney": "Insira o valor por aposta",
        "minBetAmount": "O valor mínimo por aposta é ",
        "maxBetAmount": "O valor máximo de por aposta é ",
        "chooseWithDrawAddressTip": "Selecione um endereço de retirada",
        "inputRemarkTip": "Insira seu endereço de retirada",
        "confirmOrder": "Confirmar",
        "manji": "Nível VIP Máximo atingido",
        "selWithAddress": "Selecione um endereço de retirada",
        "withNumTip": "A quantia da retirada deve ser",
        "unLiuShui": "Fluxo Inacabado",
        "withdrawChannel": "Canal de Retirada"
    },
    "version4": {
        "game": "Jogo",
        "baocangIntro": "O patrimônio da sua conta de swap {account} era 0 USTD em  {date}, e a taxa de margem ≤ 0 e a liquidaçao forçada serão acionadas.",
        "geshi": "Insira o valor correto",
        "mingc": "Volume",
        "zhangd": "Mudar",
        "quanqiu": "Global",
        "yiliu": "A-team",
        "yinhang": "Security"
    },
    "version5": {
        "rechargeWay": "Canal de Depósito",
        "inputChargeNum": "Insira o valor do depósito",
        "receivePeople": "Tenedor",
        "bankCard": "Cartão do Banco",
        "bank": "Banco",
        "bankNumber": "Conta Bancária",
        "bankProvince": "Província Localizada",
        "bankCity": "Cidade Localizada",
        "bankBrancher": "Banco de Filiais",
        "rengong": "Depósito e retirada Manual"
    },
    "version7": {
        "p6": "Vincule seu cartão de banda primeiro",
        "jinxingzhong": "Em Transação",
        "yiwancheng": "Feito",
        "shibai": "Fracassado",
        "nichen": "Nome de usuário",
        "p8": "Cota de transação única",
        "username": "Digite o nome de usuário"
    },
    "version8": {
        "noArrive0": "A quantia recebido não pode ser 0",
        "xuantian": "Insira o código do convite",
        "result": "Resultado",
        "color": "Cor",
        "hlRule": "Adivinhe o número entre 0-9. 1, 3, 5, 7, 9 são verdes, 0, 2, 4, 6, 8 são vermelhos, 0 e 5 também são roxos."
    },
    "Transfer": {
        "FriendsReport": "Relatório da Equipe",
        "jintian": "Hoje",
        "zuotian": "Ontem",
        "benzhou": "Essa Semana",
        "shangzhou": "Semana Passada",
        "benyue": "Este Mês",
        "shangyue": "Mês Passado",
        "zuce": "Usuários de Registro",
        "shoucong": "Usuários de Primeiro Depósito",
        "chongzhi": "Usuários de Depósito",
        "tixian": "Usuários de Saque",
        "heyue": "Trocar Usuários",
        "youxi": "Usuários do Jogo",
        "touzu": "Apostas em Jogos",
        "zhongjiang": "Vencedor do Jogo",
        "shimingrenshu": "Usuários de Verificação",
        "status_1": "Revendo",
        "status_3": "Fracassado",
        "status_4": "Sucesso",
        "jilu": "Registro"
    },
    "version9": {
        "SD1MRule": "Adivinhe o número de discos de cores (vermelho e branco). Discos da mesma cor têm número par é pares, e discos da mesma cor têm número ímpar é ímpares.",
        "FourRed": "4-Vermelho/0-Branco",
        "ThreeRedOneWhite": "3-Vermelho/1-Branco",
        "FourWhite": "0-Vermelho/4-Branco",
        "OneRedThreeWhite": "1-Vermelho/3-Branco"
    },
    "version10": {
        "titMode3": "Ele precisa pagar a taxa de mineração para garantir que a transação na cadeia seja processada ao sacar. A taxa de mineração atual é de {minTxFee} USDT.",
        "onGame": "Em Processamento",
        "tuihui": "Retornou",
        "hasJump": "Sucesso",
        "player": "Jogadoras",
        "money": "Quantia",
        "jump": "Saque",
        "jumpMulti": "Saque Múltiplo",
        "betMoney": "Quantia da Aposta",
        "autoJumpTip": "Múltiplo de Saque Automático (Opcional)",
        "p1": "O múltiplo mínimo deve ser maior que ",
        "p2": "O múltiplo máximo é ",
        "autoJump": "Saque Automático",
        "boomValue": "Falha Múltipla",
        "crash": "Falha",
        "noRepeatOrder": "Não aposte repetidamente",
        "jumpSuccess": "Saltar de Paraquedas",
        "tip1_plan": "1. O múltiplo de saque automático é opcional. Após o preenchimento, você sacará automaticamente após a aeronave atingir o múltiplo correspondente, ou poderá sacar manualmente antes que a aeronave caia; se você não tiver preenchido, ainda poderá sacar manualmente antes que a aeronave caia.",
        "tip2_plan": "2. Receberemos {amount}% do lucro dos jogadores de cashout.",
        "dizhi": "Digite o endereço correto"
    },
    "version11": {
        "trend": "Tendências",
        "goldenBroad": "Vencedores",
        "broadTxt": "Parabéns, {userName} ganhou <span class='Tcolor'>{winMoney}</span> USDT",
        "TS": "Paraquedismo",
        "CQK1M": "Blocos de 1min",
        "CQK5M": "Blocos de 5min",
        "CQK": "Blocos de 10min (corrente)",
        "HL": "Vermelho_Verde de 1min",
        "HL5M": "Vermelho_Verde de 5min",
        "SD1M": "Discos de 1min",
        "SD5M": "Discos de 5min",
        "Gogame": "Vai brincar",
        "yinli": "Lucro",
        "munber": "Lucro = Lucro de Negociação + Bônus de Atividade + Rebate",
        "Gyinkui": "Lucro do Jogo",
        "Hyinkui": "Lucro do Contrato",
        "Hlijin": "Bônus de Atividade",
        "Yfanyong": "Desconto"
    },
    "version12": {
        "hl10mRule": "Adivinhe o primeiro 0-9 contando do último bit até a frente do valor de hash do primeiro bloco ETH a cada 10 minutos, 1, 3, 5, 7, 9 são verdes, 0, 2, 4, 6, 8 são vermelhos , 0 e 5 também são roxos.",
        "sd10mRule": "Adivinhe os últimos quatro bits do valor de hash do primeiro bloco ETH a cada 10 minutos, cada código corresponde a um disco, 0-7 são brancos, 8-f são vermelhos. Discos da mesma cor têm número par é pares, e discos da mesma cor têm número ímpar é ímpares.",
        "SD10M": "Discos de 10min (corrente)",
        "HL10M": "Vermelho_Verde de 10min (corrente)",
        "refresh": "Atualizar"
    },
    "version13": {
        "kaijiangzhong": "Desenho",
        "canyu": "Meu",
        "kaijiang": "Finalizado",
        "join": "Juntar",
        "buyAmount": "Número de cópias a comprar",
        "minBetAmount": "O número mínimo de cópias de compra é: ",
        "maxBetAmount": "O número máximo de cópias de compra é: ",
        "tipMax": "Por favor, note que não compre acima do número máximo de cópias",
        "totalVal": "Valor total",
        "jindu": "Progresso",
        "leave": "Permanecer",
        "goldNumber": "Código vencedor",
        "myGoldNumber": "Meu código",
        "val": "Valor",
        "mode": "Restante",
        "unJoin": "Não participou",
        "calNumber": "Calculando",
        "eachPrice": "Preço por exemplar",
        "currentIssue": "Recordes nesta rodada",
        "hisIssue": "50 registros antes do sorteio",
        "showDetail": "Ver",
        "buy": "Comprar",
        "buyTime": "Tempo de Compra",
        "luckNumber": "Número do Prêmio",
        "totalBuyAmount": "Quantia Total da Compra",
        "buyTip2": "Insira o formato correto de por cópia",
        "goldMember": "Membro do Prêmio",
        "openTime": "Tempo de Desenho",
        "Wtime": "Tempo estimado de manutenção",
        "invitationNote": "Instruções do Convite",
        "p1": "<ins class='paramTitle'>Convidar amigos</ins>Entre na central de convites, copie o link do convite ou o código do convite e compartilhe-o com seus amigos. Os amigos podem se tornar seus membros subordinados registrando-se com seu código de convite.",
        "p2": "<ins class='paramTitle'>Ganhe descontos</ins>Você pode obter descontos correspondentes quando os convidados negociam, que é de até três níveis de convidados. Por exemplo, se você convidar seu amigo A, A convida B e B convida C, então A, B e C podem negociar jogos, trocas e outras transações na plataforma. Você também pode obter descontos correspondentes nessas ações.",
        "fuk": "Por favor pague",
        "guoq": "Expirado",
        "exchtishi": "Use seu cartão bancário certificado para enviar fundos para a conta acima.",
        "exchtishi2": "Por favor, não anote BTC, USDT e outras informações no processo de remessa para evitar a interceptação de remessas ou quaisquer outros problemas.",
        "quxiaoOrder": "Cancelar o pedido",
        "chengong": "Eu paguei com sucesso",
        "fukuanren": "Nome do Pagador",
        "fukuanrenTi": "Insira o nome do pagador",
        "zhanghao": "Conta de Pagamento",
        "zhanghaoTi": "Insira sua conta de pagamento",
        "yihang": "Banco Pagador",
        "yihangTi": "Insira seu banco pagador",
        "jietu": "Captura de tela do pagamento",
        "jietuTi": "Faça o upload da captura de tela do pagamento",
        "queren": "Confirme que você pagou",
        "queren2": "Em caso de confirmação maliciosa, a conta será congelada",
        "YYYG": "Sorte 1U",
        "dragon": "Classificação",
        "paimng": "No."
    },
    "version15": {
        "only60Days": "Apenas mantenha os dados dentro de 60 dias",
        "only100Records": "Mostrar apenas os últimos 100 registros",
        "status": "Estado",
        "luckyWheel": "Sorteio",
        "option1": "Bônus",
        "option2": "Thanks",
        "option3": "Once More",
        "freeTimes": "Oportunidades de Desenho",
        "p1": "Você terá {registerGiveCount} oportunidades de sorteio após o registro bem-sucedido.",
        "p2": "Se o valor do depósito atingir {todayRechargeCondition} USDT ontem (sujeito ao fuso horário UTC+0), você poderá obter oportunidades de sorteio de {rechargeGiveCount}.",
        "p3": "Ontem (no fuso horário UTC+0) o montante de apostas válidas (excluindo spot e contrato) atingiu {todayBettingCondition} USDT, você poderá obter oportunidades de sorteio de {bettingGiveCount}.",
        "continuDraw": "Continuar a Desenhar",
        "noFreeTimeTip": "Você esgotou todas as suas oportunidades de desenho"
    },
    "version16": {
        "totalPerMoney": "Total de pedidos",
        "notFu": "O valor da aposta não pode ser 0",
        "find": "Descobrir",
        "channel": "Canal"
    },
    "version17": {
        "drawWarmTip": "Os tempos de sorteio podem levar de dez minutos a meia hora para serem confirmados. Por favor, espere pacientemente.",
        "level": "Nível",
        "upLimit": "Limitação de Atualização",
        "chatRoom": "Bater papo",
        "group": "Bate-papos em Grupo",
        "privateChat": "Bate-papos Privados",
        "groupManager": "Gerente de Grupo",
        "groupMember": "Membro do Grupo",
        "groupSetting": "Configurações do Grupo",
        "totalChargeMoney": "Valor do Depósito Acumulado",
        "vip9Tip": "Nível VIP máximo atingido",
        "dataTime": "Hora dos Dados",
        "sortType": "Ordem de classificação",
        "loginTime": "Hora de Login",
        "lowerPeople": "Convidados",
        "chargeNum": "Quantia do Depósito",
        "withdrawNum": "Quantidade retirada",
        "lowerReport": "Relatório da Equipe",
        "lowerUserBetNum": "Apostas dos Convidados",
        "lowerUserAwardNum": "Convidados Vencedores",
        "lowerUserChargeNum": "Depósito de Convidados",
        "lowerUserWithdrawNum": "Retirada dos Convidados",
        "firstRechargeNum": "Quantia do primeiro depósito",
        "warmTip": "Mostrar os dados de hoje automaticamente",
        "publishNotice": "Publicar",
        "editNotice": "Editar Aviso",
        "noticeTime": "Hora do Aviso",
        "inputNoticeTip": "Insira o conteúdo do aviso",
        "tip": "Depois que 'Tudo Proibido' é ativado, apenas os gerentes de grupo podem falar",
        "allNotSpeak": "Tudo Proibido",
        "canSpeakLevel": "Nível de Fala",
        "eryuanqiquan": "Opção Binária",
        "optionHelpText": "Preveja os altos e baixos do próximo período de tempo. O preço no início de cada período é o preço inicial e o preço no final de cada período é o preço de ajuste. Se o preço de ajuste for maior que o preço inicial, ele estará em alta (longo) e se o preço de ajuste for menor que o preço inicial, ele estará em baixa (curto).",
        "optionRecord": "Registros de Opção",
        "calcuteDirection": "Direção de Previsão",
        "startPrice": "Preço Inicial",
        "endPrice": "Preço de Liquidação",
        "equal": "Igual",
        "waitResult": "A ser liquidado",
        "up": "Grandes",
        "down": "Curto",
        "findPassword": "Recuperar Senha",
        "calTime": "Tempo de Previsão",
        "calWeek": "Período de Previsão",
        "kanzhang": "Grandes",
        "kandie": "Curto"
    },
    "version18": {
        "issue": "No.",
        "vipSort": "VIP",
        "jinyan": "Silenciado",
        "weijinyan": "Não Mudo",
        "confirmDelete": "Tem certeza de que deseja excluir o membro?",
        "confirmUnSpeak": "Tem certeza de que deseja silenciar o membro?",
        "confirmSpeak": "Tem certeza de que deseja ativar o som do membro?",
        "confirmAdd": "Tem certeza de que deseja adicionar o membro?",
        "addMember": "Adicionar novo membro",
        "delMember": "Excluir o membro",
        "lockSpeak": "Silenciado",
        "unLockSpeak": "Não Mudo",
        "lockSpeakSelMember": "Silenciou o Membro",
        "unLockSpeakSelMember": "Reativou o Som do Membro",
        "noSelMemberTip": "Selecione o membro",
        "recentlyPlayGame": "Reproduzido com Frequência",
        "sendMsg": "Enviar mensagem",
        "addGroupMember": "Adicionar Novo Membro",
        "picTip": "[Imagem]",
        "time3": "1 semana atrás",
        "chooseAll": "Selecione Inverter",
        "personalPage": "Página Pessoal",
        "CQK3M": "Blocos de 3min",
        "HL3M": "Vermelho_Verde de 3min",
        "SD3M": "Discos de 3min"
    },
    "version19": {
        "avatar": "Avatar",
        "share": "Compartilhar",
        "todayProfit": "Lucro de Hoje",
        "followBet": "Acompanhamento",
        "Jump": "VAI",
        "over": "Finalizado",
        "optionNumber": "Usuários da Opção",
        "optionBet": "Apostas de Opção",
        "optionReward": "Opção Vencedora",
        "optionProfit": "Lucro da Opção",
        "RG_2_0": "Vermelho",
        "RG_5_1": "Roxo",
        "RG_2_1": "Verde",
        "DS_16_0": "1111",
        "DS_16_1": "0000",
        "DS_4_0": "1110",
        "DS_4_1": "1000",
        "DS_2_0": "Par",
        "DS_2_1": "Ímpar",
        "BLK_2_0": "Grande",
        "BLK_2_1": "Pequeno",
        "BLK_2_2": "Ímpar",
        "BLK_2_3": "Par",
        "BLK_4_0": "Grande-Ímpar",
        "BLK_4_1": "Grande-Par",
        "BLK_4_2": "Pequeno-Ímpar",
        "BLK_4_3": "Pequeno-Par"
    },
    "version20": {
        "server": "Atendimento ao Cliente",
        "teacher": "Supervisor",
        "financer": "Finança",
        "planer": "Planejador",
        "chatPop": "Janela flutuante de bate-papo",
        "cancelText": "Cancelar janela flutuante",
        "betNumber": "Opções de apostas",
        "resendMsg": "Falha no envio da mensagem. Deseja reenviar a mensagem?",
        "delThisMsg": "Excluir a mensagem",
        "delThisMemberAllMsg": "Excluir todas as mensagens do membro",
        "forbitThisMember": "Silenciou o membro",
        "delThisMember": "Excluir o membro",
        "delTipModal": "Selecione a função a ser operada"
    },
    "version21": {
        "USDT_account": "Conta USDT ",
        "closeSwapTip": "Selecione a taxa de fechamento",
        "hasRecharge": "Recarregado",
        "numberCoin": "Moeda Digital",
        "none": "-",
        "fastCharge": "Recarga Rápida",
        "bankTransfer": "TransferênciaBancária",
        "setLegalTip": "Selecione sua moeda fiduciária",
        "legalCoin": "Moeda Fiat",
        "orderCreateTime": "Hora de Criação",
        "tradeOrderNumber": "Número da Transação",
        "completeTime": "Tempo de Conclusão",
        "fastWithdraw": "Retirada Rápida",
        "bindCardTip1": "Preencha o nome completo em seu cartão de identificação válido, pois ele não pode ser modificado após o envio",
        "bindCardTip2": "Preencha as informações do seu cartão bancário"
    },
    "saas": {
        "fastChargeType": "Recarga Rápida de Canal",
        "numberCoinChargeType": "Recarga de Moeda Digital",
        "humanChargeType": "Recarga Manual",
        "fastChannelWithdrawType": "Retirada Rápida do Canal",
        "numberCoinWithdrawType": "Retirada de Moeda Digital",
        "humanWithdrawType": "Retirada Manual",
        "fastChannelBackType": "Retorno Rápido do Canal",
        "numberChannelBackType": "Devolução de Moeda Digital",
        "betType": "Aposta",
        "betProfitType": "Lucro",
        "betEqualType": "Igual",
        "gameBackRewardType": "Desconto de Jogo",
        "swapBackRewardType": "Trocar Desconto",
        "exchangeBackRewardType": "Desconto à Vista",
        "tranInType": "Transferir",
        "tranOutType": "Transferir para Fora",
        "chargeRewardType": "Bônus de Recarga",
        "firstChargeRewardType": "Bônus de Primeiro Depósito",
        "regRewardType": "Bônus de Registro",
        "upGradeRewardType": "Bônus de Promoção",
        "luckyRewardType": "Sorteio",
        "realNameRewardType": "Bônus de Nome Real",
        "betRewardType": "Bônus de Apostas",
        "dailyFirstChargeRewardType": "Bônus Diário de Primeiro Depósito",
        "upScoreType": "Aumento Manual",
        "downScoreType": "Diminuição Manual",
        "unRecharge": "Não recarregado",
        "canWithdrawBalance": "Saldo Sacarável",
        "withPopTip1": "A retirada não é permitida se houver fluxo inacabado.",
        "withPopTip2": "A parte do fluxo inacabado precisa pagar {flowFee}% da taxa de fluxo ao sacar.",
        "alterBankCardNameTip": "Entre em contato com o atendimento ao cliente se precisar alterar seu nome",
        "arriveMoney": "Montante Recebido",
        "withdrawNum": "Quantidade retirada",
        "failReason": "Motivo da falha",
        "tradeHash": "TXID",
        "waitCoin": "Aguardando Empréstimo",
        "zicang": "Ativos",
        "otherMoney": "Estimativa de Outros Ativos",
        "moneyList": "Lista de Ativos",
        "onlyShowSwap": "Trocar Apenas",
        "hideZeroMoney": "Ocultar Pequenos Saldos",
        "chargeFlowTip1": "O fluxo inacabado será aumentado em {deposit}% do valor da recarga da conta USDT. Por exemplo, se 1000 USDT forem recarregados, adicionará {depositValue} USDT de fluxo inacabado.",
        "chargeFlowTip2": "O fluxo inacabado pode ser deduzido através da aposta efetiva do jogo/opção binária. Por exemplo, se o fluxo inacabado for 200USDT e uma aposta válida de 10USDT for gerada no jogo, restam 190USDT no fluxo inacabado.",
        "chargeRecord": "Registros de Depósito",
        "withdrawRecord": "Registros de Retirada"
    },
    "version22": {
        "verificationCode": "Código de verificação",
        "userNameErr": "O formato do nome de usuário está incorreto. Insira de 6 a 32 letras ou combinações alfanuméricas que possam conter. e @",
        "tip": "Dica",
        "because": "Razão",
        "no": "Número",
        "auditError": "Falha na auditoria",
        "auditSuccess": "Auditoria Bem-Sucedida",
        "fundPassword": "O formato da senha do fundo está incorreto. Insira 6 dígitos",
        "allEntrust": "Histórico de Pedidos",
        "dealAmount": "Quantia da Transação",
        "watcherTeamForm": "Ver relatórios da equipe",
        "overview": "Visão Geral",
        "stopBet": "O pedido foi fechado neste período. Por favor, pare de apostar",
        "balance": "Saldo Disponível",
        "exchangeOrderMinVolume": "A quantia mínimo confiado é ",
        "exchangeOrderMaxVolume": "A quantia máximo confiado é ",
        "BLK5MC": "Blocos de 5min (corrente)",
        "RG5MC": "Vermelho_Verde de 5min (corrente)",
        "DS5MC": "Discos de 5min (corrente)",
        "forbidTrade": "O módulo de transação proibiu transações",
        "minTurnoverNum": "O faturamento mínimo é ",
        "minSwapOpenNum": "O número mínimo de contratos é x",
        "maxSwapOpenNum": "O número máximo de contratos é x neste momento",
        "activity": "Atividade",
        "profitRecord": "Vitórias",
        "service": "Serviço",
        "help": "Ajuda",
        "totalAssets": "Valor Total",
        "readAllLetterTip": "Tem certeza de que deseja definir todas as mensagens como lidas?",
        "myTeam": "Meu time",
        "lv1": "Nível 1",
        "lv2": "Nível 2",
        "lv3": "Nível 3",
        "lvAll": "Classe 1/2/3",
        "calDataTime": "Tempo Estatístico",
        "dataUpdateTip": "Tempo de atualização de dados: {date}",
        "total": "Total de Cópias",
        "lastTopUserName": "Nome de Usuário Superior",
        "humanAdd": "Aumento Manual",
        "humanSub": "Diminuição Manual",
        "realNameTime": "Tempo de Autenticação",
        "chargeTime": "Hora do Depósito",
        "reSubmit": "Reenviar",
        "goldMoney": "Quantia da vitória",
        "username": "Utilizador",
        "myNum": "Meu Código",
        "waitOpen": "Aguardando o Sorteio",
        "buyAmount": "Número de cópias a comprar",
        "bugNum": "Adquira as cópias correspondentes",
        "gainCode": "Obtenha o código da loteria",
        "totalNumOpenReward": "A loteria será aberta depois que o número total de cópias estiver esgotado",
        "winnerGainAllReward": "O vencedor receberá todos os prêmios",
        "teamLevel": "Nível do time",
        "SD_serialGame": "Discos",
        "RG_serialGame": "Vermelho_Verde",
        "BLK_serialGame": "Blocos",
        "codeTip": "Código QR do Endereço de Depósito",
        "relateTopUser": "Superior",
        "lv1Num": "Classe 1- Convidados",
        "lv2Num": "Classe 2- Convidados",
        "lv3Num": "Classe 3- Convidados",
        "singleLevelTxt": " Classe",
        "wrondPasswordCorrect": "O formato da senha de login está incorreto. Insira uma combinação de 8 a 32 letras e números",
        "withdrawAccount": "Conta de Saque",
        "optionModeShutTip": "O modo {minute} minuto está em manutenção no momento",
        "optionModuleShutTip": "Em Manutenção",
        "gameClosed": "Jogo fechado",
        "doBet": "APOSTA",
        "reAlterBindCard": "Sua moeda fiduciária é inconsistente com a moeda do seu cartão bancário. Atualize as informações do cartão bancário a tempo",
        "t2": "A partir da soma dos valores de tempo (UTC+0) dos últimos 50 registros de compra do jogo antes do sorteio",
        "emailErr": "Por favor, digite o endereço de e-mail correto",
        "bigSymTip": "representar <Grande>",
        "smallSymTip": "representar <Pequeno>",
        "EvenSymTip": "representar <Par>",
        "OddSymTip": "representar <Ímpar>",
        "exchangeNum": "Usuários Spot",
        "yesterdayAward": "Vitória de Ontem",
        "yijixiaji": "Classe 1 - Convidado",
        "erjixiaji": "Classe 2 - Convidado",
        "sanjixiaji": "Classe 3 - Convidado",
        "p3": "<ins class='paramTitle'>Nível da equipe</ins>Quanto mais turma 1 - convidados forem promovidos, maior será o nível da equipe e o desconto que você poderá aproveitar. O nível de equipe é dividido em LV1-LV6. Consulte a tabela a seguir para as regras de upgrade, onde N é o número de classe 1 - convidados que foram cobrados.",
        "p4": "<ins class='paramTitle'>Taxa de desconto de jogo, opção, contrato dos segundos</ins>Quando os convidados apostam em jogos, contrato dos segundos, opções binárias, você pode obter um desconto correspondente ao valor da aposta.",
        "p5": "<ins class='paramTitle'>Taxa de desconto de spot e swap</ins>Quando os convidados realizam transações spot e swap, você pode obter um desconto correspondente à comissão da transação. Se a comissão estiver em outras moedas que não USDT, o desconto será convertido em USDT de acordo com a taxa de câmbio em tempo real e emitido para sua conta USDT.",
        "flag": "Carácter",
        "noZero": "O valor da transferência não pode ser 0",
        "networkTip": "Erro de rede, atualize a página e recarregue.",
        "inviteChargeTip": "A atualização do nível da equipe depende do número de classe 1 - convidados que recarregam com sucesso.",
        "canWithdrawNum": "Aproveitar. Equilíbrio",
        "withTip": "Insira a quantia da retirada",
        "withModalTip": "A taxa de serviço inclui taxa de fluxo e taxa de mineração.",
        "totalUpNum": "Total de Pessoas",
        "chargeRangeTip": "O valor do depósito deve ser ",
        "jiaocheng": "Instrução",
        "spare1": "Informações Alternativas 1",
        "spare2": "Informações Alternativas 2",
        "loadAllGroupMsg": "Todas as mensagens dentro de 7 dias foram carregadas",
        "realMember": "Associação completa",
        "testMember": "Membro de teste",
        "noBlankMsg": "Você não pode enviar uma mensagem em branco",
        "maxLength1500": "Falha no envio da mensagem porque o conteúdo é muito longo",
        "sendToMore": "Falha no envio da mensagem porque as solicitações são muito frequentes",
        "countdown": "Contagem Regressiva",
        "toLongRemark": "Muitas palavras, exclua algumas palavras",
        "tip1": "<p>* Os dados estão sujeitos ao tempo estatístico. Os dados são atualizados a cada hora. A hora de atualização mais recente é: {time}; Entre eles, usuários de registro / usuários de verificação / usuários de primeiro depósito / valor do primeiro depósito são atualizados em tempo real</p>",
        "tip2": "<p>* O saldo de USDT não tem nada a ver com o tempo estatístico e sempre exibe o saldo total atual da equipe</p>",
        "tip3": "* O valor do depósito / retirada / aposta / ganho / desconto está sujeito ao tempo estatístico. Os dados são atualizados a cada hora. A hora de atualização mais recente é: {time}",
        "tip4": "* Outros dados são independentes do tempo estatístico e os dados mais recentes são sempre exibidos",
        "wrongImgType": "Formato de arquivo não suportado",
        "addNewNotice": "Adicionar",
        "noChoose": "Não selecionado",
        "nullCoin": "Nenhum",
        "thankyou": "Obrigado Pela Participação",
        "oncemore": "Mais Uma Vez",
        "tip5": "Como a taxa de câmbio pode flutudar, consulte o valor real",
        "xiugaichenggong": "Modificação bem-sucedida, faça login novamente",
        "entrustNoZero": "A quantidade delegada não pode ser 0",
        "herTeam": "O time dele ",
        "betMoney": "Por favor insira a quantia da aposta ",
        addFilterMember : 'Adicionar todos os membros filtrados',
        delFilterMember : 'Eliminar todos os membros filtrados',
        forbidFilterMember : 'Silenciar todos os membros filtrados',
        freeFilterMember : 'Desbloquear todos os membros filtrados',
        theme1 : 'Tema do Jogo 1',
        theme2 : 'Tema do Jogo 2',
        theme3 : 'EX Tema 1',
        theme4 : 'EX Tema 2',
        themeTitle : 'Tema',
        maxTradeMoney: 'A rotação máxima é '
    },
    "version23": {
        inviteCenter: "Centro de Convite",
        search: 'Pesquisa',
        active: 'Acção',
        openDirect: 'Direcção da Lotaria',
        profitAndLossAmount: 'P/L',
        levelMax: "Nível mais alto alcançado",
        next: 'Página anterior anterior',
        prev: 'A próxima página',
        secondRecord : 'Registros de Segundos Contrato',
        entrusting : 'Em consignação',
        secondReward : 'Segundos Contratos Vencedores',
        secondBet : 'Segundos Contratos de Apostas',
        secondProfit : 'Lucro do Contrato de Segundos',
        RD100N : 'Aleatório 100',
        secondNum : 'Utilizadores do Contrato de Segundos',
        rebackTip : 'O desconto será pago a cada hora, por favor aguarde pacientemente.',
        secondOption : 'Contrato dos Segundos',
        qiquan : 'Opção',
    exportBtn : 'Exportação',
    "SB1M": "Aeronave de 1min",
    "SB3M": "Aeronave de 3min",
    "SB5M": "Aeronave de 5min",
    "SBTM": "Aeronave de 10min",
  },
  version24 : {
    goldBoom : 'Pisar uma mina',
    'MS' : 'Varredura de minas',
    spRecord : 'Registos de Sportivos-Ponymuah',
    "QT1M": "SICBO de 1min",
    "QT3M": "SICBO de 3min",
    "QT5M": "SICBO de 5min",
    "QTTM": "SICBO de 10min",
    "KN1M": "KENO de 1min",
    "KN3M": "KENO de 3min",
    "KN5M": "KENO de 5min",
    "KNTM": "KENO de 10min",
    "electronRecord": "Registos de Arcada-Evolution",
    "LOT1M": "LOTTO de 1min",
    "LOT3M": "LOTTO de 3min",
    "LOT5M": "LOTTO de 5min",
    "LOTTM": "LOTTO de 10min",
    'LOTW3' : 'LOTTO',

    "RLT1M": "Roleta de 1min",
    "RLT3M": "Roleta de 3min",
    "RLT5M": "Roleta de 5min",
    "RLTTM": "Roleta de 10min",
    "LK281M": "Sorte 28 de 1min",
    "LK283M": "Sorte 28 de 3min",
    "LK285M": "Sorte 28 de 5min",
    "LK28TM": "Sorte 28 de 10min",
    "liveRecord": "Registos de  Directo-Ezugi",
    evoLiveRecord : 'Registos de  Directo-Evolution',
    pbRecord : 'Registos de Sportivos-PinnacleBet',
      LIMBO : 'LIMBO',
      HILO : 'HILO',
	'FSLOT' : 'Fruta',
    'RULTI' : 'Roda',
    'RULTI_2-075_0' : 'Preto',
    'RULTI_3-176_0' : 'Vermelho',
    'RULTI_5-4_0' : 'Azul',
    'RULTI_54_0' : 'Verde',
	'RB' : 'Vermelho_Preto',
	'RB_2-142_0' : 'Vermelho',
	'RB_15_0' : 'Branco',
	'RB_2-142_1' : 'Preto',
  },
  version25 : {
    sport : 'Desporto-Ponymuah',
    live :'Em directo-Ezugi',
    electron : 'Arcada-Evolution',

    sport_index : 'Desporto',
    live_index :'Em directo',
    electron_index : 'Arcada',

    PG : 'Arcada-PocketGame',
    PP : 'Arcada-PragmaticPlay',
    "sportsNum": "Utilizadores desportivos",
    "sportsAward": "Vencedor desportivo",
    "sportsBet": "Apostas desportivas",
    "sportsProfit": "Lucro desportivo",

    electronNum : 'Utilizadores de Arcada',
    electronAward : 'Vencedor de Arcada',
    electronBet:'Apostas de Arcada',
    electronProfit : 'Lucro de Arcada',

    liveNum : 'Utilizadores de directo',
    liveAward : 'Vencedor de directo',
    liveBet:'Apostas de directo',
    liveProfit : 'Lucro de directo',
		
	Num : 'Utilizadores',
    Award : 'Vencedor',
    Bet:'Apostas',
    Profit : 'Benefício',

    ourGame : 'Jogos Originais',
    "t1": "Toneladas de jogos, um só lugar para jogar",
    "t2": "A principal plataforma de jogos do mundo",
  },
  version26 : {
    liveTeamReport : 'Relatórios da equipa em tempo real',
    tip2 : `Você só pode escolher a hora dentro das últimas 24 horas`
  },
  version27 :{
    PGrecord : 'Registos de Arcada-PocketGame',
    PPRecord : 'Registos de Arcada-PragmaticPlay',

  },
  version28 : {
    account : 'Conta'
  },
  mayi : {
    live : 'Em directo',
    arcade : 'Arcada',
    inviteAward : 'Bônus de Convite',
    upAward:'Bônus de Promoção',
    betAward : 'Bônus de Apostas',

    t9: 'Esta atividade é válida por um longo período',

    t11: 'Número de pessoas na equipe',

    t22 : 'Jogo',

    t25 : 'Desporto',
    t26: 'Centro de promoção',
    t27: 'Desconto de ontem',
    t28: 'Nível de desconto de ontem',
    t29: 'Dados de ontem',
    t30: 'Todos os subordinados',
    t31: 'Valor da aposta',
    t32: 'Compartilhar link de convite' ,
    t33: 'Registro de desconto',

    t35: 'Taxa de desconto',
    t36: 'Desconto esta semana',

    t38: 'Recarga de equipe Número de pessoas ',

    t42: 'Nível 4',
    t43: 'Nível 5',
    t44: 'Nível 6',
    t55: 'Nível 1-6',

    t57 : '{curLevel} Bem-esta',

    t64: `Desconto de comissão inferior de nível {level}`,

    // t70 : '例如：如果玩家A邀请玩家B进行首次存款，则玩家A冷获则玩家A冷获得 Rs10.00 的奖金',
    t71: 'Rácio de bónus',

    t75: 'Valor do desconto',
 
    t79 : `
    Entre na "Central de Convites", copie o link do convite ou o código do convite e compartilhe-o com seus amigos. Amigos podem se tornar seus subordinados registrando-se com seu código de convite.
	`,
    t80: 'Proporção de bônus de aposta',
    t81: 'Se o valor efetivo da aposta atingir o padrão, você pode avançar',
    t82 : `
    Número de recarga: Número acumulado de recarga de subordinados nos níveis 1-6 na história <br/>
    Valor da aposta: valor efetivo da aposta de ontem para níveis inferiores 1-6<br/>
    Quantia de recarga: 1-6 Nível Quantia de recarga efetiva de ontem`,
    t83 : 'Instruções de desconto',
    t84 : 'Convide amigos ',
    t85: 'Ganhe descontos',
    t86 : 'O nível da equipe',
    
    t87 : 'O desconto só terá efeito após o subordinado concluir a recarga e suporta até 6 subordinados. Por exemplo, se você convidar o amigo A, A convida B e B convida C, então se A, B e C realizarem jogos e outras transações na plataforma e tiverem registros de recarga, todos vocês poderão obter descontos correspondentes.',
    t88 :  'UTC+0 calcula o nível da equipe às 02:00 todos os dias e distribui a comissão de ontem com base no nível da equipe, que pode ser visualizada através de registros de comissão.Quanto maior o nível da equipe, maior a comissão que pode ser apreciada.Os níveis de equipe são divididos em LV1-LV9, e as regras de atualização são mostradas na tabela abaixo.'
  }
}