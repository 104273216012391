module.exports = {
  "nav": {
    "shouye": "घर",
    "hanqing": "बाजार",
    "jiaoyi": "व्यापार",
    "heyue": "बदलना",
    "wode": "मुझे"
  },
  "common": {
    "login": "लॉग इन करें",
    "register": "साइन अप करें",
    "loginregister": "लॉगइन \साइनअप",
    "logout": "लॉग आउट",
    "tip": "सुझावों",
    "delete": "क्या वाकई हटाना है?",
    "set": "सेटिंग्स",
    "update": "रूप बदलना",
    "more": "अधिक",
    "loading": "लोड हो रहा है",
    "zanwu": "कोई रिकॉर्ड नहीं",
    "quxiao": "रद्द करना",
    "ok": "पुष्टि करना",
    "noyanzhengma": "कृपया सही कैप्चा दर्ज करें",
    "clearStorage": "कैश को साफ़ करें",
    "telErr": "कृपया सही मोबाइल नंबर दर्ज करें",
    "T404": "प्लेटफ़ॉर्म को अपडेट किया जा रहा है, और बाद में पहुँच बहाल कर दी जाएगी"
  },
  "loginPage": {
    "mima": "पासवर्ड",
    "passwordTxt": "कृपया पासवर्ड दर्ज करें"
  },
  "homePage": {
    "charge": "जमा",
    "pickup": "निकालना",
    "exchange": "स्थान",
    "promotionaward": "बट्टा"
  },
 "pageTitle": {
    "gonggaoxiangqing": "घोषणा",
    "wodezichang": "बैलेंस",
    "bangzhuxiangqing": "विवरण",
    "fundpwd": "निधि पासवर्ड",
    "yuyanshezhi": "भाषा",
    "huazhuang2": "स्थानांतरण",
    "renzheng": "प्रमाणीकरण",
    "chooseAlterPhoneType": "मोबाइल नंबर",
    "chooseAlterEmailType": "ईमेल",
    "letterList": "सूचनाएं",
    "appDownload": "ऐप डाउनलोड करें",
    "buy": "खरीदना",
    "sell": "बेचना",
    "cexiao": "रद्द करना",
    "time": "समय",
    "type": "प्रकार",
    "jiage": "कीमत",
    "weituoliang": "मात्रा",
    "changjiao": "निष्पादित",
    "weituoe": "भुगतान",
    "over": "सभी लोडेड",
    "weituojia": "सौंपने की कीमत",
    "weituoer": "कुल राशि",
    "quedingchedan": "क्या आप रद्द करना सुनिश्चित कर रहे हैं?",
    "chedaning": "रद्द कर रहा है",
    "jiaoyidui": "सिक्का जोड़े",
    "fangxiang": "ओर",
    "chongzhi": "रीसेट",
    "xianjia": "सीमा",
    "shijia": "बाज़ार",
    "plan": "चालू कर देना",
    "quanbu": "सभी",
    "xianjiaweituo": "सीमा",
    "yichexiao": "रद्द",
    "time1": "आरंभ समय समाप्ति समय से पहले होना चाहिए",
    "time2": "आरंभ समय समाप्ति समय से पहले होना चाहिए",
    "time3": "समाप्ति समय वर्तमान समय से पहले का होना चाहिए",
    "cedancg": "सफलतापूर्वक रद्द किया गया",
    "cedansb": "रद्द करने में विफल",
    "mairujiage": "यूनिट मूल्य",
    "yongxu": "विनिमय",
    "zhanghu": "हिस्सेदारी",
    "yugu": "EST क्लीयरेंस मूल्य",
    "baozheng": "मार्जिन दर",
    "kaicang": "खुला",
    "baocang": "जला दिया",
    "pincang": "बंद करना",
    "zhanghu2": "खाता",
    "pingduo":"क्लोज़ लॉन्ग",
    "kaiduo": "लंबा खुला",
    "pingkong": "बंद छोटा",
    "kaigong": "खुला छोटा",
    "cufajia": "ट्रिगर कीमत",
    "zuiyou": "सबसे अच्छी कीमत",
    "zhang": "अनुबंध",
    "keyong": "उपलब्ध",
    "kekaiduo": "अधिकतम. L",
    "kekaikong": "अधिकतम. S",
    "duocang": "अधिकतम (L)",
    "kongcang": "अधिकतम (S)",
    "kanzhang": "लंबा",
    "kandie": "छोटा",
    "shuliang": "मात्रा",
    "chicang": "पदों",
    "dangqianweituo": "खुले आदेश",
    "lishiweituo": "इतिहास",
    "shouyi": "P/L",
    "shouyilv": "P/Lअनुपात",
    "gangganbeishu": "फ़ायदा उठाना",
    "chicangliang": "पोजीशन",
    "kepingliang": "पद का लाभ उठायें",
    "kaichangjun": "खुली कीमत",
    "zuixinjia": "नवीनतम मूल्य",
    "dangqianbaozheng": "कर मार्जिन",
    "duotou": "लंबा",
    "kongtou": "छोटा",
    "baozhengjin": "अंतर",
    "weituoshuliang": "सौंपी गई राशि",
    "jiesuanweik": "समझौता P/L",
    "chengjiaojia": "अंतिम कीमत",
    "shouxufei": "शुल्क",
    "xiugaichenggong": "सफलतापूर्वक संशोधित"
  },
   "views": {
    "wangjiPass": "पासवर्ड भूल गए?",
    "confirmLogOut": "क्या आप लॉग आउट करना सुनिश्चित हैं?",
    "personalCenter": "उपयोगकर्ता केंद्र",
    "shezhi": "सफलतापूर्वक सेट करें",
    "yuanyouxiang": "पुराना ईमेल कोड",
    "newyouxiang": "नया ईमेल कोड",
    "fsyouxiang": "कोड भेजो",
    "xyouxiang": "नया ईमेल",
    "loginPass": "लॉगिन पासवर्ड",
    "baocun": "बचाना",
    "fschenggong": "सफलतापूर्वक भेज दिया",
    "inputyouxiang": "कृपया अपना नया ईमेल पता दर्ज करें",
    "inputOld": "कृपया पुराना ईमेल सत्यापन कोड दर्ज करें",
    "inputNew": "कृपया नया ईमेल सत्यापन कोड दर्ज करें",
    "inputPass": "कृपया अपना लॉगिन पासवर्ड दर्ज करें",
    "OldPass": "पुराना लॉगिन पासवर्ड",
    "inputOldPass": "कृपया अपना पुराना लॉगिन पासवर्ड दर्ज करें",
    "NewPass": "नया लॉगिन पासवर्ड",
    "inputNewPass": "कृपया अपना नया लॉगिन पासवर्ड दर्ज करें",
    "queren": "पासवर्ड की पुष्टि कीजिये",
    "email": "सत्यापन कोड",
    "liangciPass": "दर्ज किए गए पासवर्ड असंगत हैं",
    "shuruCode": "कृपया सत्यापन कोड दर्ज करें",
    "OldZJ": "पुराना फंड पासवर्ड",
    "inputOldZJ": "कृपया अपना पुराना फंड पासवर्ड दर्ज करें",
    "NewZJ": "नया फंड पासवर्ड",
    "inputNewZJ": "कृपया अपना नया फंड पासवर्ड दर्ज करें",
    "augeren": "व्यक्तिगत जानकारी",
    "aushang": "आईडी कार्ड अपलोड करें",
    "aushenghe": "समीक्षा",
    "name": "पूरा नाम",
    "inputName": "कृपया अपना पूरा नाम दर्ज करें",
    "huzhao": "सरकार द्वारा जारी आईडी नंबर",
    "inputhuzhao": "कृपया अपना पहचान पत्र, पासपोर्ट या ड्राइविंग लाइसेंस नंबर दर्ज करें",
    "next": "अगला",
    "uphuzhao": "अपने आईडी कार्ड की फोटो अपलोड करें",
    "shouhuzhao": "प्रमाण-पत्रों के साथ व्यक्तियों की तस्वीरें अपलोड करें",
    "tijiao": "जमा करना",
    "tip1": "1. कृपया सुनिश्चित करें कि आपकी आईडी समाप्त नहीं हुई है",
    "tip2": "2. हस्ताक्षर की सामग्री में हस्तलिखित {hrefArry}, व्यक्तिगत हस्ताक्षर और वर्तमान दिनांक शामिल हैं",
    "tip3": "3.कृपया सुनिश्चित करें कि फ़ोटो और व्यक्तिगत हस्ताक्षर स्पष्ट रूप से दिखाई दे रहे हों",
    "shentijiao": "जानकारी सबमिट कर दी गई है, कृपया समीक्षा की प्रतीक्षा करें",
    "fanhui": "वापस करना",
    "bangding": "बाँध",
    "inputEmail": "कृपया अपना ईमेल एड्रेस इंटर करें",
    "inputPhone": "अपना मोबाइल नंबर दर्ज करें",
    "kefu": "कृपया ग्राहक सेवा से संपर्क करें"
  }, 
 "list": {
    "wufaxiugia": "जब आपके पास पोजीशन या अनडील ऑर्डर हों तो आप एकाधिक संशोधन नहीं कर सकते",
    "submittip1": "अपने धन की सुरक्षा सुनिश्चित करने के लिए, कृपया पहले वास्तविक नाम प्रमाणीकरण करें",
    "submittip2": "अपने फंड की सुरक्षा सुनिश्चित करने के लिए, कृपया पहले फंड पासवर्ड सेट करें",
    "shuruweituo": "कृपया निर्दिष्ट मूल्य दर्ज करें",
    "shuruweituoliang": "कृपया सौंपी गई राशि दर्ज करें",
    "dayukekai": "राशि अधिकतम से अधिक नहीं हो सकती. L",
    "dayuduocang": "राशि अधिकतम (एस) से अधिक नहीं हो सकती",
    "dayukekaikong": "राशि अधिकतम से अधिक नहीं हो सकती. S",
    "dayukongcang": "राशि अधिकतम (L) से अधिक नहीं हो सकती",
    "shuruchufa": "कृपया ट्रिगर मूल्य दर्ज करें",
    "zhangfubang": "श्रेणी",
    "liang": " खंड",
    "safar": "सफारी में नीचे दिए गए बटन पर क्लिक करें",
    "kuaisuanzhuang": "[होम स्क्रीन में जोड़ें] चुनें",
    "noAccount": "अभी तक कोई खाता नहीं है?",
    "LoginReg": "पंजीकरण करवाना",
    "butigongfuwu": "कृपया समझें कि आपका आईपी या स्थानीय सेवा इस समय उपलब्ध नहीं है",
    "renzheng1": "सत्यापित",
    "renzheng2": "सत्यापित करने में विफल",
    "renzheng3": "समीक्षा के अंतर्गत",
    "renzheng4": "सत्यापन करें",
    "shenheing": "समीक्षाधीन, कृपया धैर्यपूर्वक प्रतीक्षा करें",
    "inputzaicimima": "कृपया पासवर्ड पुनः दर्ज करें",
    "xuantian": "कृपया आमंत्रण कोड दर्ज करें (वैकल्पिक)",
    "yuedu": "मैं इसके लिए सहमत हूं",
    "yonghuxieyi": "उपयोगकर्ता सेवा अनुबंध",
    "hasAccount": "पहले से ही पंजीकृत?",
    "denglu": "लॉग इन करें",
    "gouxuanxieyi": "कृपया उपयोगकर्ता सेवा अनुबंध पढ़ें और जाँचें",
    "inputzijinmima": "कृपया अपना फंड पासवर्ड दर्ज करें",
    "yijianfankui": "प्रतिक्रिया",
    "gao": "उच्च",
    "di": "कम",
    "shengdu": "आर्डर - बुक",
    "chengjiao": "भरा हुआ",
    "zixuan1": "पसंदीदा से हटाया गया",
    "zixuan2": "हटाने में विफल",
    "zixuan3": "हटाने में विफल",
    "zixuan4": "जोड़ने में विफल",
    "jiaoyie": "कुल राशि",
    "chengjiaozonger": "कुल राशि",
    "chengjiaojun": "औसत मूल्य",
    "chengjiaoliang": "आयतन",
    "bigmairu": "अधिकतम उपलब्ध राशि से अधिक होना ",
    "weituochenggong": "सफलतापूर्वक कारोबार किया गया",
    "weituoshibai": "व्यापार करने में विफल",
    "yue": " संतुलन",
    "bizhong": "मुद्रा",
    "huazhuanshuliang": "स्थानांतरण राशि",
    "inputhzsl": "कृपया स्थानांतरण राशि दर्ज करें",
    "bunengweifu": "स्थानांतरण राशि 0 से अधिक होनी चाहिए",
    "dayuhuazhuan": "स्थानांतरण राशि आपके खाते में उपलब्ध राशि से अधिक नहीं हो सकती",
    "huazhuanchenggong": "सफलतापूर्वक स्थानांतरित किया गया",
    "huazhuangshibai": "स्थानांतरित करने में विफल"
  },
  "user": {
    "guizeshuo मिनg": "​नियम",
    "shenheweitongguo": "सत्यापित करने में विफल",
    "shi मिनg": "कृपया अपने खाते को सत्यापित करें",
    "fuzhichenggong": "सफलतापूर्वक कॉपी किया गया",
    "frozen": "ऑर्डर पर",
    "zhanghuquanyi": "हिस्सेदारी",
    "baozhenjin": "मार्जिन का लाभ उठाएं",
    "jiaoyishijian": "लेन-देन का समय"
  },
  "tradePage": {
   "CUSTOM": "पसंदीदा"
  },
   "chart": {
    "minute": "​मिनट"
  },
  "charge": {
    "congbi": "जमा पता",
    "baocun": "क्यूआर कोड सहेजें"
  },
  "heyuePage": {
    "yijianpingcang": "फ़्लैश बंद करें",
    "copyerr": "कॉपी करने में विफल",
    "pincang": "नजदीकी स्थिति का अनुबंध 0 नहीं हो सकता"
  },
  "yaoqin": {
    "zuce": "पंजीकरण की तारीख",
    "showPersonInfo": "अपना प्रोफ़ाइल देखें",
    "myinvitelink": "आमंत्रण लिंक",
    "my_column4": "कक्षा",
    "yaoqing": "आमंत्रण",
    "dengji": "स्तर प्रगति",
    "mubiao": " लक्ष्य",
    "zong": "कुल छूट",
    "jiaoyi": "लेनदेन",
    "wode": "आमंत्रण संकेत",
    "huodong": "गतिविधि बोनस"
  },
  "version3": {
    "hash": "हैश मान",
    "betTip": "हर 10 मिनट में पहले ईटीएच ब्लॉक के हैश मान के अंतिम बिट का अनुमान लगाएं, 0-7 छोटे हैं, 8-F बड़े हैं, 0, 2, 4, 6, 8, A, C और E सम हैं, 1, 3 , 5, 7, 9, b, d और f विषम हैं।",
    "betTip2": "हैश मान के अंतिम बिट का अनुमान लगाएं, 0-7 छोटे हैं, 8-F बड़े हैं, 0, 2, 4, 6, 8, A,C और E सम हैं, 1, 3, 5, 7, 9, B, d और f विषम हैं।",
    "betMoneyTip": "प्रति विकल्प राशि",
    "doBet": "पुष्टि करना",
    "issue": "नहीं.",
    "blockHigh": "ब्लॉक ऊंचाई",
    "blockTime": "ब्लॉक समय",
    "hash2": "हैश मान",
    "gameRecord": "खेल रिकॉर्ड्स",
    "betTime": "आदेश का समय",
    "pickUpAddress": "निकासी पता",
    "arriveAccountNum": "प्राप्त रकम",
    "remark": "टिप्पणी",
    "contachService": "ग्राहक सेवा से संपर्क करें",
    "totalBetMoney": "कुल राशि",
    "unOpen": "कोई परिणाम नहीं",
    "maintain": "को बनाए रखने",
    "hasBingGo": "जीत गया",
    "unBingGo": "खो गया",
    "outOfBalance": "संतुलन पर्याप्त नहीं है",
    "chooseBetNumTip": "कृपया विकल्प चुनें",
    "chooseBetMoney": "कृपया प्रति विकल्प राशि दर्ज करें",
    "minBetAmount": "प्रति विकल्प न्यूनतम राशि है ",
    "maxBetAmount": "प्रति विकल्प अधिकतम राशि है ",
    "chooseWithDrawAddressTip": "कृपया निकासी पता चुनें",
    "inputRemarkTip": "कृपया अपना निकासी पता दर्ज करें",
    "confirmOrder": "पुष्टि करना",
    "manji": "अधिकतम वीआईपी स्तर तक पहुँच गया",
    "selWithAddress": "कृपया निकासी पता चुनें",
    "withNumTip": "निकासी राशि होनी चाहिए ",
    "unLiuShui": "अधूरा प्रवाह",
    "withdrawChannel": "निकासी चैनल"
  },
  "version4": {
    "game": "खेल",
    "baocangIntro": "आपके {date} स्वैप खाते की इक्विटी {account} पर 0 यूएसडीटी थी, मार्जिन दर ≤ 0 थी और जबरन परिसमापन शुरू हो जाएगा।",
    "geshi": "कृपया सही राशि दर्ज करें",
    "mingc": "आयतन",
    "zhangd": "परिवर्तन",
    "quanqiu": "वैश्विक",
    "yiliu": "एक टीम",
    "yinhang": "सुरक्षा"
  },
"version5": {
    "rechargeWay": "जमा चैनल",
    "inputChargeNum": "कृपया जमा राशि दर्ज करें",
    "receivePeople": "आदाता",
    "bankCard": "बैंक कार्ड",
    "bank": "बैंक",
    "bankNumber": "बैंक खाता",
    "bankProvince": "स्थित प्रांत",
    "bankCity": "स्थित शहर",
    "bankBrancher": "शाखा बैंक",
    "rengong": "मैनुअल जमा और निकासी"
  },
  "version7": {
    "p6": "कृपया पहले अपना बैंड कार्ड बाँध लें",
    "jinxingzhong": "व्यापार",
    "yiwancheng": "हो गया",
    "shibai": "असफल",
    "nichen": "उपयोगकर्ता नाम",
    "p8": "एकल लेनदेन कोटा",
    "username": "कृपया उपयोगकर्ता नाम दर्ज करें"
  },
  "version8": {
    "noArrive0": "प्राप्त राशि 0 नहीं हो सकती",
    "xuantian": "कृपया आमंत्रण कोड दर्ज करें",
    "result": "परिणाम",
    "color": "रंग",
    "hlRule": "0-9 के बीच की संख्या का अनुमान लगाएं. 1, 3, 5, 7, 9 हरे हैं, 0, 2, 4, 6, 8 लाल हैं, 0 और 5 भी बैंगनी हैं।"
  },
  "Transfer": {
    "FriendsReport": "टीम रिपोर्ट",
    "jintian": "आज",
    "zuotian": "कल",
    "benzhou": "इस सप्ताह",
    "shangzhou": "पिछले सप्ताह",
    "benyue": "इस महीने",
    "shangyue": "पिछला महीना",
    "zuce": "पंजीकरण उपयोगकर्ता",
    "shoucong": "प्रथम जमा उपयोगकर्ता",
    "chongzhi": "जमा उपयोगकर्ता",
    "tixian": "निकासी उपयोगकर्ता",
    "heyue": "उपयोगकर्ताओं को स्वैप करें",
    "youxi": "गेम उपयोगकर्ता",
    "touzu": "खेल सट्टेबाजी",
    "zhongjiang": "खेल जीतना",
    "shimingrenshu": "सत्यापन उपयोगकर्ता",
    "status_1": "की समीक्षा",
    "status_3": "असफल",
    "status_4": "सफलता",
    "jilu": "अभिलेख"
  },
 "version9": {
    "SD1MRule": "रंगीन डिस्क (लाल और सफेद) की संख्या का अनुमान लगाएं। एक ही रंग की डिस्क की सम संख्या सम होती है, और एक ही रंग की डिस्क की विषम संख्या विषम होती है।",
    "FourRed":"4-लाल/0-सफेद",
    "ThreeRedOneWhite": "3-लाल/1-सफेद",
    "FourWhite": "0-लाल/4-सफेद",
    "OneRedThreeWhite":"1-लाल/3-सफेद"
  },
  "version10": {
    "titMode3": "से यह सुनिश्चित करने के लिए खनन शुल्क का भुगतान करना होगा कि निकासी करते समय श्रृंखला पर लेनदेन संसाधित हो। वर्तमान खनन शुल्क {minTxFee} USDT है।",
    "onGame": "चल रहे",
    "tuihui": "लौटा हुआ",
    "hasJump": "भुनाया",
    "player": "खिलाड़ियों",
    "money": "मात्रा",
    "jump": "नकदी निकलना",
    "jumpMulti": "कैशआउट मल्टीपल",
    "betMoney": "ऑर्डर करने की राशि",
    "autoJumpTip": "ऑटो कैशआउट मल्टीपल (वैकल्पिक)",
    "p1": "न्यूनतम गुणक इससे अधिक होना चाहिए ",
    "p2": "अधिकतम गुणज है ",
    "autoJump": "ऑटो कैशआउट",
    "boomValue": "एकाधिक दुर्घटनाग्रस्त",
    "crash": "दुर्घटनाग्रस्त",
    "noRepeatOrder": "बार-बार दांव न लगाएं",
    "jumpSuccess": "भुनाया",
    "tip1_plan": "1. ऑटो कैशआउट मल्टीपल वैकल्पिक है। भरने के बाद, विमान के संबंधित गुणक तक पहुंचने के बाद आप स्वचालित रूप से नकद निकाल लेंगे, या आप विमान के दुर्घटनाग्रस्त होने से पहले मैन्युअल रूप से नकद निकाल सकते हैं; यदि आपने नहीं भरा है, तो भी आप विमान दुर्घटनाग्रस्त होने से पहले मैन्युअल रूप से नकद निकाल सकते हैं।",
    "tip2_plan": "2. हम कैशआउट खिलाड़ियों के लाभ का {amount}% लेंगे।",
    "dizhi": "कृपया सही पता दर्ज करें"
  },
  "version11": {
    "trend": "प्रवृत्तियों",
    "goldenBroad": "विजेताओं",
    "broadTxt": "बधाई, {userName} जीत गया <span class='Tcolor'>{winMoney}</span> USDT",
    "TS": "Crash",
    "CQK1M": "1मिनट ब्लॉक",
    "CQK5M": "5 मिनट ब्लॉक",
    "CQK": "10 मिनट ब्लॉक (चेन अप)",
    "HL": "1मिनट लाल_ग्रीन",
    "HL5M": "5मिनट लाल_ग्रीन",
    "SD1M": "1मिनट डिस्क",
    "SD5M": "5मिनट डिस्क",
    "Gogame": "खेलने के लिए जाओ",
    "yinli": "लाभ",
    "munber": "मुनाफ़ा = व्यापारिक लाभ + गतिविधि बोनस+छूट",
    "Gyinkui": "खेल लाभ",
    "Hyinkui": "स्वैप लाभ",
    "Hlijin": "गतिविधि बोनस",
    "Yfanyong": "छूट"
  },
  "version12": {
    "hl10mRule": "प्रत्येक 10 मिनट में पहले ETH ब्लॉक के हैश मान के अंतिम बिट से आगे तक गिनती करते हुए पहले 0-9 का अनुमान लगाएं, 1, 3, 5, 7, 9 हरे हैं, 0, 2, 4, 6, 8 लाल हैं, 0 और 5 भी बैंगनी हैं।",
    "sd10mRule": "हर 10 मिनट में पहले ETH ब्लॉक के हैश वैल्यू के अंतिम चार बिट्स का अनुमान लगाएं, प्रत्येक कोड एक डिस्क से मेल खाता है, 0-7 सफ़ेद हैं, 8-f लाल हैं। एक ही रंग की डिस्क की सम संख्या का मतलब है सम, और एक ही रंग की डिस्क की विषम संख्या का मतलब है विषम।",
    "SD10M": "10 मिनट डिस्क (चेन अप)",
    "HL10M": "10 मिनट लाल_ग्रीन (चेन अप)",
    "refresh": "ताज़ा करना"
  },
  "version13": {
    "kaijiangzhong": "चित्रकला",
    "canyu": "मेरा",
    "kaijiang": "खत्म",
    "join": "जोड़ना",
    "buyAmount": "खरीदी जाने वाली प्रतियों की संख्या",
    "minBetAmount": "Tप्रतियों की न्यूनतम क्रय संख्या है: ",
    "maxBetAmount": "प्रतियों की अधिकतम क्रय संख्या है: ",
    "tipMax": "कृपया ध्यान दें कि अधिकतम संख्या से अधिक प्रतियां न खरीदें",
    "totalVal": "कुल मूल्य",
    "jindu": "प्रगति",
    "leave": "अवशेष",
    "goldNumber": "विजेता कोड",
    "myGoldNumber": "मेरा कोड",
    "val": "कीमत",
    "mode": "शेष",
    "unJoin": "भाग नहीं लिया",
    "calNumber": "गिना जा रहा है",
    "eachPrice": "प्रति कॉपी की कीमत",
    "currentIssue": "इस दौर के रिकॉर्ड",
    "hisIssue": "ड्रा से पहले 50 रिकॉर्ड",
    "showDetail": "देखना",
    "buy": "खरीदना",
    "buyTime": "समय खरीदना",
    "luckNumber": "पुरस्कार संख्या",
    "totalBuyAmount": "कुल खरीद राशि",
    "buyTip2": "कृपया प्रति कॉपी का सही प्रारूप दर्ज करें",
    "goldMember": "पुरस्कार सदस्य",
    "openTime": "ड्राइंग का समय",
    "Wtime": "अनुमानित रखरखाव समय",
    "invitationNote": "आमंत्रण निर्देश",
    "p1": "<ins class='paramTitle'>मित्रों को आमंत्रित करें</ins>आमंत्रण केंद्र में प्रवेश करें, आमंत्रण लिंक या आमंत्रण कोड की प्रतिलिपि बनाएँ, और इसे अपने मित्रों के साथ साझा करें। आपके आमंत्रण कोड के साथ पंजीकरण करके मित्र आपके अधीनस्थ सदस्य बन सकते हैं।",
    "p2": "ins class='paramTitle'>छूट अर्जित करें</ins>जब आमंत्रित व्यक्ति ट्रेडिंग करते हैं, तो आप संबंधित छूट प्राप्त कर सकते हैं, जो आमंत्रित व्यक्तियों के तीन स्तरों तक होती है। उदाहरण के लिए, यदि आप अपने मित्र A को आमंत्रित करते हैं, A, B को आमंत्रित करता है, और B, C को आमंत्रित करता है, तो A, B और C प्लेटफ़ॉर्म पर गेम, स्वैप और अन्य लेन-देन करने में सक्षम होते हैं। आप इन क्रियाओं में भी संबंधित छूट प्राप्त कर सकते हैं।",
    "fuk": "भुगतान करें",
    "guoq": "खत्म हो चुका",
    "exchtishi": "कृपया उपरोक्त खाते में धनराशि भेजने के लिए अपने प्रमाणित बैंक कार्ड का उपयोग करें।",
    "exchtishi2": "कृपया धन प्रेषण की प्रक्रिया में बीटीसी, यूएसडीटी और अन्य जानकारी को नोट न करें, ताकि धन प्रेषण में अवरोध उत्पन्न होने या अन्य कोई समस्या उत्पन्न न हो।",
    "quxiaoOrder": "आदेश रद्द करें",
    "chengong": "मैंने सफलतापूर्वक भुगतान कर दिया है",
    "fukuanren": "भुगतानकर्ता का नाम",
    "fukuanrenTi": "कृपया भुगतानकर्ता का नाम दर्ज करें",
    "zhanghao": "भुगतान खाता",
    "zhanghaoTi": "कृपया अपना भुगतान खाता दर्ज करें",
    "yihang": "भुगतान बैंक",
    "yihangTi": "कृपया अपना भुगतानकर्ता बैंक दर्ज करें",
    "jietu": "भुगतान का स्क्रीनशॉट",
    "jietuTi": "कृपया भुगतान का अपना स्क्रीनशॉट अपलोड करें",
    "queren": "कृपया पुष्टि करें कि आपने भुगतान कर दिया है",
    "queren2": "दुर्भावनापूर्ण पुष्टि के मामले में, खाता फ्रीज कर दिया जाएगा",
    "YYYG": "लकी 1U",
    "dragon": "श्रेणी",
    "paimng": "श्रेणी"
  },
   "version15": {
    "only60Days": "डेटा को केवल 60 दिनों के भीतर ही रखें",
    "only100Records": "केवल अंतिम 100 रिकॉर्ड दिखाएं",
    "status": "स्थिति",
    "luckyWheel": "लकी ड्रा",
    "option1": "बक्शीश",
    "option2": "धन्यवाद",
    "option3": "एक बार और",
    "freeTimes": "ड्राइंग के अवसर",
    "p1": "सफल पंजीकरण के बाद आपको {registerGiveCount} ड्रॉ के अवसर मिलेंगे।",
    "p2": "यदि जमा राशि कल {todayRechargeCondition} USDT तक पहुँच जाती है (UTC+0 समय क्षेत्र के अधीन), तो आपको {rechargeGiveCount} ड्राइंग अवसर मिल सकते हैं।",
    "p3": "यदि प्रभावी सट्टेबाजी राशि कल {todayBettingCondition} USDT तक पहुँच जाती है (UTC+0 समय क्षेत्र के अधीन), तो आपको {bettingGiveCount} ड्रॉइंग अवसर मिल सकते हैं।",
    "continuDraw": "चित्र बनाना जारी रखें",
    "noFreeTimeTip": "आपके सभी ड्राइंग अवसर समाप्त हो चुके हैं"
  },
  "version16": {
    "totalPerMoney": "कुल राशि",
    "notFu": "ऑर्डर राशि 0 नहीं हो सकती",
    "find": "खोज करना",
    "channel": "चैनल"
  },
  "version17": {
    "drawWarmTip": "लकी ड्रा की पुष्टि होने में दस मिनट से लेकर आधे घंटे तक का समय लग सकता है। कृपया धैर्यपूर्वक प्रतीक्षा करें।",
    "level": "स्तर",
    "upLimit": "अपग्रेड सीमा",
    "chatRoom": "बात करना",
    "group": "समूह चैट",
    "privateChat": "निजी चैट",
    "groupManager": "समूह प्रबंधक",
    "groupMember": "समूह का सदस्य",
    "groupSetting": "समूह सेटिंग्स",
    "totalChargeMoney": "संचयी जमा राशि",
    "vip9Tip": "अधिकतम वीआईपी स्तर तक पहुँच गया",
    "dataTime": "डेटा समय",
    "sortType": "क्रमबद्ध करेन का आदेश",	
    "loginTime": "लॉग-इन समय",
    "lowerPeople": "आमंत्रित",
    "chargeNum": "जमा राशि",
    "withdrawNum": "निकाली गयी राशि",
    "lowerReport": "टीम रिपोर्ट",
    "lowerUserBetNum": "आमंत्रित' बेट",
    "lowerUserAwardNum": "आमंत्रित' जीत",
    "lowerUserChargeNum": "आमंत्रित'जमा",
    "lowerUserWithdrawNum": "आमंत्रित'निकासी",
    "firstRechargeNum": "प्रथम जमा राशि",
    "warmTip": "आज का डेटा स्वचालित रूप से दिखाएँ",
    "publishNotice": "प्रकाशित करना",
    "editNotice": "सूचना संपादित करें",
    "noticeTime": "नोटिस समय",
    "inputNoticeTip": "कृपया नोटिस की सामग्री दर्ज करें",
    "tip": "'सभी निषिद्ध' सक्षम होने के बाद, केवल समूह प्रबंधकों को बोलने की अनुमति होती है",
    "allNotSpeak": "सभी निषिद्ध",
    "canSpeakLevel": "बोलने का स्तर",
    "eryuanqiquan": "बायनरी विकल्प",
    "optionHelpText": "अगली अवधि के उतार-चढ़ाव की भविष्यवाणी करें। प्रत्येक अवधि की शुरुआत में कीमत शुरुआती कीमत होती है, और प्रत्येक अवधि के अंत में कीमत निपटान कीमत होती है। यदि निपटान मूल्य शुरुआती कीमत से अधिक है, तो यह ऊपर (लंबा) है, और यदि निपटान मूल्य शुरुआती कीमत से कम है, तो यह नीचे (छोटा) है।",
    "optionRecord": "विकल्प रिकॉर्ड",
    "calcuteDirection": "भविष्यवाणी दिशा",
    "startPrice": "शुरुआती कीमत",
    "endPrice": "निपटान मूल्य",
    "equal": "बराबर",
    "waitResult": "व्यवस्थित होना",
    "up": "लंबा",
    "down": "छोटा",
    "findPassword": "पासवर्ड पुनःप्राप्त करें",
    "calTime": "भविष्यवाणी समय",
    "calWeek": "भविष्यवाणी अवधि",
    "kanzhang": "लंबा",
    "kandie": "छोटा"
  },
  "version18": {
    "issue": "नहीं.",
    "vipSort": "वीआईपी",
    "jinyan": "म्यूट किए गए",
    "weijinyan": "अनम्यूट किया गया",
    "confirmDelete": "क्या आप वाकई सदस्य को हटाना चाहते हैं?",
    "confirmUnSpeak": "क्या आप वाकई सदस्य को म्यूट करना चाहते हैं?",
    "confirmSpeak": "क्या आप वाकई सदस्य की आवाज़ हटाना चाहते हैं?",
    "confirmAdd": "क्या आप वाकई सदस्य को जोड़ना चाहते हैं?",
    "addMember": "नया सदस्य जोड़ें",
    "delMember": "सदस्य को हटाएँ",
    "lockSpeak": "म्यूट किए गए",
    "unLockSpeak": "अनम्यूट किया गया",
    "lockSpeakSelMember": "सदस्य को म्यूट किया गया",
    "unLockSpeakSelMember": "सदस्य को अनम्यूट करें",
    "noSelMemberTip": "कृपया सदस्य का चयन करें",
    "recentlyPlayGame": "अक्सर खेला जाता है",
    "sendMsg": "मेसेज भेजें",
    "addGroupMember": "नया सदस्य जोड़ें",
    "picTip": "छवि",
    "time3": "1 सप्ताह पहले",
    "chooseAll": "उलटा चुनें",
    "personalPage": "निजी मुख पृष्ठ",
    "CQK3M": "3 मिनट ब्लॉक",
    "HL3M": "3 मिनट लाल_ग्रीन",
    "SD3M": "3 मिनट डिस्क"
  },
  "version19": {
    "avatar": "अवतार",
    "share": "शेयर करना",
    "todayProfit": "आज का लाभ",
    "followBet": "पालन ​​करें",
    "Jump": "जाना",
    "over": "खत्म",
    "optionNumber": "विकल्प उपयोगकर्ता",
    "optionBet": "विकल्प सट्टेबाजी",
    "optionReward": "विकल्प जीतना",
    "optionProfit": "विकल्प लाभ",
    "RG_2_0": "लाल",
    "RG_5_1": "बैंगनी",
    "RG_2_1": "ग्रीन",
    "DS_16_0": "1111",
    "DS_16_1": "0000",
    "DS_4_0": "1110",
    "DS_4_1": "1000",
    "DS_2_0": "सम ",
    "DS_2_1": " विषम ",
    "BLK_2_0": "बड़ा",
    "BLK_2_1": "छोटा",
    "BLK_2_2": " विषम ",
    "BLK_2_3": "सम ",
    "BLK_4_0": "बड़ा- विषम ",
    "BLK_4_1": "बड़ा-सम ",
    "BLK_4_2": "छोटा- विषम ",
    "BLK_4_3": "छोटा-सम "
  }, 
 "version20": {
    "server": "ग्राहक सेवा",
    "teacher": "पर्यवेक्षक",
    "financer": "वित्त",
    "planer": "योजनाकर्ता",
    "chatPop": "चैट फ़्लोटिंग विंडो",
    "cancelText": "फ़्लोटिंग विंडो रद्द करें",
    "betNumber": "सट्टेबाजी के विकल्प",
    "resendMsg": "संदेश भेजना विफल हुआ। क्या आप संदेश पुनः भेजना चाहते हैं?",
    "delThisMsg": "संदेश हटाएँ",
    "delThisMemberAllMsg": "सदस्य के सभी संदेश हटाएँ",
    "forbitThisMember": "सदस्य को म्यूट किया गया",
    "delThisMember": "सदस्य को हटाएँ",
    "delTipModal": "कृपया संचालित किए जाने वाले फ़ंक्शन का चयन करें"
  },
  "version21": {
    "USDT_account": "यूएसडीटी खाता",
    "closeSwapTip": "कृपया समापन अनुपात चुनें",
    "hasRecharge": "रिचार्ज किया गया.",
    "numberCoin": "डिजिटल मुद्रा",
    "none": "-",
    "fastCharge": "तेज़ रिचार्ज",
    "bankTransfer": "बैंक ट्रांसफर",
    "setLegalTip": "कृपया अपनी फिएट मुद्रा चुनें",
    "legalCoin": "फिएट मुद्रा",
    "orderCreateTime": "रचना समय",
    "tradeOrderNumber": "लेन-देन सं.",
    "completeTime": "समापन समय",
    "fastWithdraw": "तेजी से निकासी",
    "bindCardTip1": "कृपया अपने वैध आईडी कार्ड पर पूरा नाम भरें, क्योंकि इसे जमा करने के बाद संशोधित नहीं किया जा सकता है",
    "bindCardTip2": "कृपया अपनी बैंक कार्ड जानकारी भरें"
  },
  "saas": {
    "fastChargeType": "फास्ट चैनल रिचार्ज",
    "numberCoinChargeType": "डिजिटल मुद्रा रिचार्ज",
    "humanChargeType": "मैनुअल रिचार्ज",
    "fastChannelWithdrawType": "फास्ट चैनल निकासी",
    "numberCoinWithdrawType": "डिजिटल मुद्रा निकासी",
    "humanWithdrawType": "मैनुअल निकासी",
    "fastChannelBackType": "फास्ट चैनल रिटर्न",
    "numberChannelBackType": "डिजिटल मुद्रा की वापसी",
    "betType": "Bet",
    "betProfitType": "लाभ",
    "betEqualType": "बराबर",
    "gameBackRewardType": "खेल छूट",
    "swapBackRewardType": "स्वैप छूट",
    "exchangeBackRewardType": "स्पॉट छूट",
    "tranInType": "में स्थानांतरण",
    "tranOutType": "बाहर स्थानांतरण",
    "chargeRewardType": "रिचार्ज बोनस",
    "firstChargeRewardType": "प्रथम जमा बोनस",
    "regRewardType": "पंजीकरण बोनस",
    "upGradeRewardType": "प्रमोशन बोनस",
    "luckyRewardType": "लकी ड्रा",
    "realNameRewardType": "वास्तविक नाम बोनस",
    "betRewardType": "बेटिंग बोनस",
    "dailyFirstChargeRewardType": "दैनिक प्रथम जमा बोनस",
    "upScoreType": "मैनुअल वृद्धि",
    "downScoreType": "मैनुअल कमी",
    "unRecharge": "रिचार्ज नहीं किया गया",
    "canWithdrawBalance": "निकासी योग्य शेष राशि",
    "withPopTip1": "यदि प्रवाह अधूरा है तो निकासी की अनुमति नहीं है।",
    "withPopTip2": "अधूरे प्रवाह वाले भाग को वापस लेते समय प्रवाह शुल्क का {flowFee}% भुगतान करना होगा।",
    "alterBankCardNameTip": "यदि आपको अपना नाम बदलने की आवश्यकता हो तो कृपया ग्राहक सेवा से संपर्क करें",
    "arriveMoney": "प्राप्त रकम",
    "withdrawNum": "निकाली गयी राशि",
    "failReason": "विफलता का कारण",
    "tradeHash": "TXID",
    "waitCoin": "ऋण की प्रतीक्षा",
    "zicang": "संपत्ति",
    "otherMoney": "अन्य परिसंपत्तियों का आकलन",
    "moneyList": "परिसंपत्ति सूची",
    "onlyShowSwap": "केवल स्वैप",
    "hideZeroMoney": "छोटे शेष राशि छिपाएँ",
    "chargeFlowTip1": "अधूरे प्रवाह को USDT खाते की रिचार्ज राशि के {deposit}% से बढ़ाया जाएगा। उदाहरण के लिए, यदि 1000 USDT रिचार्ज किया जाता है, तो अधूरे प्रवाह का {depositValue} USDT जोड़ा जाएगा।",
    "chargeFlowTip2": "अधूरे प्रवाह को खेल/मनोरंजन अनुबंध के प्रभावी दांव के माध्यम से घटाया जा सकता है। उदाहरण के लिए, यदि अधूरा प्रवाह 200USDT है, और खेल में 10USDT का वैध दांव उत्पन्न होता है, तो अधूरे प्रवाह में 190USDT शेष रह जाता है।",
    "chargeRecord": "जमा रिकॉर्ड",
    "withdrawRecord": "निकासी रिकॉर्ड"
  },
  "version22": {
    "verificationCode": "कॅप्चा",
    "userNameErr": "उपयोगकर्ता नाम का प्रारूप गलत है। कृपया 6-32 अक्षर या अल्फ़ान्यूमेरिक संयोजन दर्ज करें जिसमें शामिल हो सकते हैं। और @",
    "tip": "बख्शीश",
    "because": "कारण",
    "no": "संख्या",
    "auditError": "ऑडिट विफल",
    "auditSuccess": "लेखापरीक्षा सफल हुई",
    "fundPassword": "फंड पासवर्ड का प्रारूप गलत है। कृपया 6 अंक दर्ज करें",
    "allEntrust": "आदेश इतिहास",
    "dealAmount": "सोदा राशि",
    "watcherTeamForm": "टीम रिपोर्ट देखें",
    "overview": "अवलोकन",
    "stopBet": "इस अवधि में ऑर्डर बंद कर दिया गया है। कृपया सट्टेबाजी बंद करें",
    "balance": "उपलब्ध शेष राशि",
    "exchangeOrder मिनVolume": "न्यूनतम सौंपी गई राशि है ",
    "exchangeOrderMaxVolume": "अधिकतम सौंपी जाने वाली राशि है ",
    "BLK5MC": "5 मिनट ब्लॉक (चेन अप)",
    "RG5MC": "5 मिनट रेड_ग्रीन (चेन अप)",
    "DS5MC": "5 मिनट डिस्क (चेन अप)",
    "forbidTrade": "लेनदेन मॉड्यूल ने लेनदेन पर प्रतिबंध लगा दिया है",
    "minTurnoverNum": "न्यूनतम टर्नओवर है",
    "minSwapOpenNum": "अनुबंधों की न्यूनतम संख्या x है",
    "maxSwapOpenNum": "इस समय अनुबंधों की अधिकतम संख्या x है",
    "activity": "गतिविधि",
    "profitRecord": "जीत",
    "service": "सेवा",
    "help": "मदद",
    "totalAssets": "कुल मूल्य",
    "readAllLetterTip": "क्या आप वाकई सभी संदेशों को पढ़ा हुआ सेट करना चाहते हैं?",
    "myTeam": "मेरी टीम",
    "lv1": "वर्ग 1",
    "lv2": "वर्ग 2",
    "lv3": "वर्ग 3",
    "lvAll": "वर्ग  1/2/3",
    "calDataTime": "सांख्यिकीय समय",
    "dataUpdateTip": "डेटा अपडेट समय: {date}",
    "total": "कुल प्रतियां",
    "lastTopUserName": "सुपीरियर उपयोगकर्ता नाम",
    "humanAdd": "मैनुअल वृद्धि",
    "humanSub": "मैनुअल कमी",
    "realNameTime": "प्रमाणीकरण समय",
    "chargeTime": "जमा करने का समय",
    "reSubmit": "पुनः सबमिट करें",
    "goldMoney": "जीत की राशि",
    "username": "उपयोगकर्ता",
    "myNum": "मेरा कोड",
    "waitOpen": "ड्रॉ का इंतजार",
    "buyAmount": "खरीदी जाने वाली प्रतियों की संख्या",
    "bugNum": "संबंधित प्रतियां खरीदें",
    "gainCode": "लॉटरी कोड प्राप्त करें",
    "totalNumOpenReward": "लॉटरी तब खोली जाएगी जब कुल प्रतियां बिक जाएंगी",
    "winnerGainAllReward": "विजेता को सभी पुरस्कार मिलेंगे",
    "teamLevel": "टीम स्तर",
    "DS_serialGame": "डिस्क",
    "RG_serialGame": "लाल, हरे",
    "BLK_serialGame": "ब्लाकों",
    "codeTip": "जमा पता क्यूआर कोड",
    "relateTopUser": "वरिष्ठ",
    "lv1Num": "कक्षा 1 - आमंत्रित व्यक्ति",
    "lv2Num": "कक्षा 2 - आमंत्रित व्यक्ति",
    "lv3Num": "कक्षा 3 - आमंत्रित व्यक्ति",
    "singleLevelTxt": " कक्षा",
    "wrondPasswordCorrect": "लॉगिन पासवर्ड का प्रारूप ग़लत है। कृपया 8-32 अक्षरों और संख्याओं का संयोजन दर्ज करें",
    "withdrawAccount": "वापसी के खाते",
    "optionModeShutTip": "{minute} मिनट मोड वर्तमान में रखरखाव के अधीन है",
    "optionModuleShutTip": "रखरखाव जारी",
    "gameClosed": "खेल बंद",
    "doBet": "बेट",
    "reAlterBindCard": "आपकी फ़िएट मुद्रा आपके बैंक कार्ड की मुद्रा से मेल नहीं खाती। कृपया समय रहते बैंक कार्ड की जानकारी अपडेट कर लें",
    "t2": "ड्रा से पहले खेल के अंतिम 50 खरीद रिकॉर्डों के समय मूल्यों (UTC+0) के योग के अनुसार",
    "emailErr": "कृपया सही ईमेल पता दर्ज करें",
    "bigSymTip": "<बड़ा> का प्रतिनिधित्व करें",
    "smallSymTip": "प्रतिनिधित्व करना <छोटा>",
    "EvenSymTip": "प्रतिनिधित्व करना <सम >",
    "OddSymTip": "प्रतिनिधित्व करना< विषम >",
    "exchangeNum": "स्पॉट उपयोगकर्ता",
    "yesterdayAward": "कल की जीत",
    "yijixiaji": "कक्षा 1 - आमंत्रित व्यक्ति",
    "erjixiaji": "कक्षा 2 - आमंत्रित व्यक्ति",
    "sanjixiaji": "कक्षा 3 - आमंत्रित व्यक्ति",
    "p3": "<ins class='पैरामटाइटल'>टीम स्तर</ins>जितने अधिक वर्ग 1 - आमंत्रित व्यक्तियों को पदोन्नत किया जाएगा, टीम का स्तर उतना ही ऊंचा होगा, और आप उतनी ही अधिक छूट का आनंद ले सकेंगे। टीम स्तर को LV1-LV6 में विभाजित किया गया है। उन्नयन नियमों के लिए निम्न तालिका देखें, जहाँ N वर्ग 1 - आमंत्रित व्यक्तियों की संख्या है जिन्हें चार्ज किया गया है।",
    "p4": "<ins class='पैरामटाइटल'>गेम, बाइनरी ऑप्शन, सेकंड कॉन्ट्रैक्ट और मनोरंजन का रिबेट अनुपात</ins>जब आमंत्रित व्यक्ति गेम, बाइनरी ऑप्शन, सेकंड कॉन्ट्रैक्ट और मनोरंजन पर दांव लगाते हैं, तो आप उनकी दांव राशि के अनुरूप छूट प्राप्त कर सकते हैं।",
    "p5": "<ins class='पैरामटाइटल'>स्पॉट और स्वैप का रिबेट अनुपात</ins>जब आमंत्रित व्यक्ति स्पॉट और स्वैप लेनदेन करते हैं, तो आप उनके लेनदेन कमीशन के अनुरूप रिबेट प्राप्त कर सकते हैं। यदि कमीशन USDT के अलावा अन्य मुद्राओं में है, तो रिबेट को वास्तविक समय विनिमय दर के अनुसार USDT में परिवर्तित किया जाएगा और आपके USDT खाते में जारी किया जाएगा।",
    "flag": "विशेषताएँ",
    "noZero": "स्थानांतरण राशि 0 नहीं हो सकती",
    "networkTip": "नेटवर्क त्रुटि, कृपया पृष्ठ को ताज़ा करें और पुनः लोड करें।",
    "inviteChargeTip": "टीम स्तर का उन्नयन कक्षा 1 - सफलतापूर्वक रिचार्ज करने वाले आमंत्रितों की संख्या पर निर्भर करता है।",
    "canWithdrawNum": "बैलेंस का लाभ उठाएँ",
    "withTip": "कृपया निकासी राशि दर्ज करें",
    "withModalTip": "सेवा शुल्क में प्रवाह शुल्क और खनन शुल्क शामिल हैं।.",
    "totalUpNum": "कुल लोग",
    "chargeRangeTip": "जमा राशि होनी चाहिए ",
    "jiaocheng": "अनुदेश",
    "spare1": "वैकल्पिक जानकारी 1",
    "spare2": "वैकल्पिक जानकारी 2",
    "loadAllGroupMsg": "7 दिनों के भीतर सभी संदेश लोड कर दिए गए हैं",
    "realMember": "पूर्ण सदस्यता",
    "testMember": "परीक्षण सदस्य",
    "noBlankMsg": "आप रिक्त संदेश नहीं भेज सकते",
    "maxLength1500": "संदेश भेजना विफल रहा क्योंकि सामग्री बहुत लंबी है",
    "sendToMore": "संदेश भेजना विफल रहा क्योंकि अनुरोध बहुत अधिक बार आए",
    "countdown": "उलटी गिनती",
    "toLongRemark": "बहुत सारे शब्द हैं, कृपया कुछ शब्द हटा दें",
    "tip1": "<p>* डेटा सांख्यिकीय समय के अधीन है. डेटा हर घंटे अपडेट किया जाता है। नवीनतम अद्यतन समय है: {time}; उनमें से, पंजीकरण उपयोगकर्ता / सत्यापन उपयोगकर्ता / प्रथम-जमा उपयोगकर्ता / प्रथम-जमा राशि वास्तविक समय में अपडेट की जाती है</p>",
    "tip2": "<p>* यूएसडीटी बैलेंस का सांख्यिकीय समय से कोई लेना-देना नहीं है, और यह हमेशा टीम का वर्तमान कुल बैलेंस प्रदर्शित करता है</p>",
    "tip3": "* जमा/निकासी/सट्टेबाजी/जीत/छूट की राशि सांख्यिकीय समय के अधीन है। डेटा हर घंटे अपडेट किया जाता है। नवीनतम अद्यतन समय है: {time}",
    "tip4": "* अन्य डेटा सांख्यिकीय समय से स्वतंत्र है, और नवीनतम डेटा हमेशा प्रदर्शित होता है",
    "wrongImgType": "असमर्थित फ़ाइल स्वरूप",
    "addNewNotice": "जोड़ना",
    "noChoose": "अभी चयनित नहीं",
    "nullCoin": "/",
    "thankyou": "भागीदारी के लिए धन्यवाद",
    "oncemore": "एक बार और",
    "tip5": "चूंकि विनिमय दर में उतार-चढ़ाव हो सकता है, कृपया वास्तविक राशि का संदर्भ लें",
    "xiugaichenggong": "संशोधन सफल हुआ, कृपया पुनः लॉगिन करें",
    "entrustNoZero": "सौंपने की राशि 0 नहीं हो सकती",
    "herTeam": "उसकी टोली",
    "betMoney": "कृपया ऑर्डर राशि दर्ज करें",
    addFilterMember : 'सभी फ़िल्टर किए गए सदस्यों को जोड़ें',
    delFilterMember : 'सभी फ़िल्टर किए गए सदस्यों को हटाएँ',
    forbidFilterMember : 'सभी फ़िल्टर किए गए सदस्यों को म्यूट करें',
    freeFilterMember : 'सभी फ़िल्टर किए गए सदस्यों को अनम्यूट करें',
    theme1 : 'गेम थीम 1',
    theme2 : 'गेम थीम 2',
    theme3 : 'पूर्व थीम 1',
    theme4 : 'पूर्व थीम 2',
    themeTitle : 'थीम',
    maxTradeMoney: 'अधिकतम कारोबार है ',
  },
  "version23": {
    inviteCenter: "निमंत्रण केंद्र",
    search: 'खोज',
    active: 'कार्रवाई',
    openDirect: 'परिणाम दिशा',
    profitAndLossAmount: 'P/L',
    levelMax: "उच्चतम स्तर पर पहुंच गया",
    next: 'अगला पेज',
    prev: 'पिछला पृष्ठ',
    RD100N : 'यादृच्छिक 100',
    secondRecord : 'सेकंड्स अनुबंध रिकॉर्ड',
    entrusting : 'खेप में',
    secondOption : 'सेकंड अनुबंध',
    secondNum : 'सेकंड अनुबंध उपयोगकर्ता',
    secondReward : 'सेकंड्स अनुबंध जीतना',
    secondBet : 'सेकंड्स अनुबंध सट्टेबाजी',
    secondProfit : 'सेकंड अनुबंध लाभ',
    rebackTip : 'छूट का भुगतान हर घंटे किया जाएगा, कृपया धैर्यपूर्वक प्रतीक्षा करें।',
    qiquan : 'विकल्प',
    exportBtn : 'निर्यात',
    "SB1M": "1मिन फ्लाइंगबोट",
    "SB3M": "3मिन फ्लाइंगबोट",
    "SB5M": "5मिन फ्लाइंगबोट",
    "SBTM": "10मिन फ्लाइंगबोट",
  },
  version24 : {
    goldBoom : 'एक खदान पर कदम रखना',
    'MS' : 'माइनस्वीपिंग',
    spRecord : 'खेल रिकॉर्ड्स-Ponymuah',
    "QT1M": "1 मिन SICBO",
    "QT3M": "3 मिन SICBO",
    "QT5M": "5 मिन SICBO",
    "QTTM": "10 मिन SICBO",
    "KN1M": "1 मिन KENO",
    "KN3M": "3 मिन KENO",
    "KN5M": "5 मिन KENO",
    "KNTM": "10 मिन KENO",
    "electronRecord": "आर्केड रिकॉर्ड्स-Evolution",
    evoLiveRecord : '"लाइव रिकॉर्ड्स-Evolution',
    pbRecord : 'स्पोर्ट्स रिकॉर्ड्स-PinnacleBet',
    "LOT1M": "1 मिन LOTTO",
    "LOT3M": "3 मिन LOTTO",
    "LOT5M": "5 मिन LOTTO",
    "LOTTM": "10 मिन LOTTO",
    'LOTW3' : 'LOTTO',

    "RLT1M": "1 मिन Roulette",
    "RLT3M": "3 मिन Roulette",
    "RLT5M": "5 मिन Roulette",
    "RLTTM": "10 मिन Roulette",
    "LK281M": "1 मिन Lucky 28",
    "LK283M": "3 मिन Lucky 28",
    "LK285M": "5 मिन Lucky 28",
    "LK28TM": "10 मिन Lucky 28",
    "liveRecord": "लाइव रिकॉर्ड्स-Ezugi",
    LIMBO : 'लीम्बो',
    HILO : 'हिलो',
    'FSLOT' : 'फल',
    'RULTI' : 'चक्र',
    'RULTI_2-075_0' : 'काला',
    'RULTI_3-176_0' : 'लाल',
    'RULTI_5-4_0' : 'नीला',
    'RULTI_54_0' : 'ग्रीन',
	'RB' : 'लाल काला',
	'RB_2-142_0' : 'लाल',
	'RB_15_0' : 'सफ़ेद',
	'RB_2-142_1' : 'काला',
  },
  version25 : {
    sport : 'खेल-Ponymuah',
    live :'लाइव-Ezugi',
    electron : 'आर्केड-Evolution',
	PG : 'आर्केड-PocketGame',
    PP : 'आर्केड-PragmaticPlay',
	
    sport_index : 'खेल',
    live_index :'सीधा प्रसारण',
    electron_index : 'आर्केड',

    "sportsNum": "खेल उपयोगकर्ता",
    "sportsAward": "खेल जीतना",
    "sportsBet": "खेल में सट्टेबाजी",
    "sportsProfit": "खेल लाभ",

    electronNum : 'आर्केड उपयोगकर्ता',
    electronAward : 'आर्केड जीतना',
    electronBet:'आर्केड सट्टेबाजी',
    electronProfit : 'आर्केड लाभ',

    liveNum : 'लाइव उपयोगकर्ता',
    liveAward : 'लाइव-विनिंग',
    liveBet:'लाइव सट्टेबाजी',
    liveProfit : 'लाइव लाभ',
		
	Num : 'उपयोगकर्ताओं',
    Award : 'जीत',
    Bet:'शर्त',
    Profit : 'लाभ',

    ourGame : 'मूल खेल',
    "t1": "ढेर सारे गेम्स, वन-स्टॉप प्ले",
    t2 : 'वैश्विक अग्रणी गेमिंग प्लेटफ़ॉर्म'
  },
  version26 : {
    liveTeamReport : 'वास्तविक समय टीम की रिपोर्ट',
    tip2 : `केवल पिछले 24 घंटों के भीतर का समय चुन सकते हैं`
  },
  version27 : {
    PGrecord : 'आर्केड रिकॉर्ड्स-PocketGame',
    PPRecord : 'आर्केड रिकॉर्ड्स-PragmaticPlay',
  },
  version28 : {
    account : 'खाता'
  },
  mayi : {
    live : 'रहना',
    arcade : 'आर्केड',
    inviteAward : 'आमंत्रण बोनस',
    upAward:'प्रमोशन बोनस',
    betAward : 'बेटिंग बोनस',

    t9 : 'यह गतिविधि लंबे समय तक वैध है',

    t11 : 'टीम संख्या',

    t22 : 'खेल',

    t25 : 'खेल-कूद',
    t26 : 'प्रमोशन सेंटर',
    t27 : "कल का छूट",
    t28 : "कल की छूट का स्तर",
    t29 : "कल का डेटा",
    t30 : 'सभी अधीनस्थ',
    t31 : 'सट्टे की राशि',
    t32 : 'आमंत्रण लिंक साझा करें',
    t33 : 'छूट रिकॉर्ड',

    t35 : 'छूट अनुपात',
    t36:'इस सप्ताह छूट',

    t38 : 'टीम रिचार्ज लोगों की संख्या',

    t42: 'स्तर 4',
    t43 : 'स्तर 5',
    t44 : 'स्तर 6',
    t55 : 'स्तर 4',

    t57 : '{curLevel}कल्याण',

    t64 : `स्तर {level} अधीनस्थ आयोग`,

    // t70 : '',
    t71 : 'बोनस अनुपात',

    t75 : 'छूट राशि',
 
    t79 : `
   आमंत्रण केंद्र" में प्रवेश करें, आमंत्रण लिंक या कोड को कॉपी करें, और इसे अपने दोस्तों के साथ शेयर करें। आपके आमंत्रण कोड के साथ पंजीकरण करके मित्र आपके अधीनस्थ बन सकते हैं।.
	`,
    t80 : 'सट्टेबाजी बोनस अनुपात',
    t81 : 'यदि प्रभावी सट्टेबाजी राशि मानक तक पहुँच जाती है, तो आपको पदोन्नत किया जा सकता है',
    t82 : `
      टॉप-अप की संख्या: 1 से 6 तक के निचले स्तरों पर टॉप-अप की संचयी संख्या <br/>
      शर्त राशि: कल का 1-6 के निचले स्तर की प्रभावी शर्त राशि<br/>
      टॉप-अप राशि: कल का स्तर 1-6 अधीनस्थों की प्रभावी टॉप-अप राशि`,
     t83 : 'छूट निर्देश',
    t84 : 'मित्रों को आमंत्रित करें ',
    t85: 'कमीशन छूट अर्जित करें',
    t86 : 'टीम स्तर',
    
    t87 :'छूट केवल अधीनस्थों द्वारा रिचार्ज पूरा करने के बाद ही प्रभावी होगी, और यह अधीनस्थों के 6 स्तरों तक का समर्थन करती है। उदाहरण के लिए, आपने मित्र A को आमंत्रित किया, A ने B को आमंत्रित किया, और B ने C को आमंत्रित किया। यदि A, B और C प्लेटफ़ॉर्म पर गेम जैसे लेनदेन करते हैं और उनके पास रिचार्ज रिकॉर्ड हैं, तो आप संबंधित छूट प्राप्त कर सकते हैं।',
    t88 : "टीम स्तर की गणना हर दिन 02:00 UTC+0 पर की जाती है, और कल की छूट टीम स्तर के अनुसार वितरित की जाएगी, जिसे छूट रिकॉर्ड के माध्यम से देखा जा सकता है। टीम का स्तर जितना ऊँचा होगा, आप उतने ही अधिक कमीशन का आनंद ले सकते हैं। टीम के स्तरों को LV1-LV9 में विभाजित किया गया है, और अपग्रेड नियम नीचे दी गई तालिका में दिखाए गए हैं।"
  }
}