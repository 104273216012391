'use strict';

exports.__esModule = true;
exports.default = {
  el: {
    colorpicker: {
      confirm: 'ठीक है',
      clear: 'स्पष्ट'
    },
    datepicker: {
      now: 'स्पष्ट',
      today: 'आज',
      cancel: 'रद्द करना',
      clear: 'स्पष्ट',
      confirm: 'ठीक है',
      selectDate: 'तारीख़ चुनें',
      selectTime: 'समय चुनें',
      startDate: 'आरंभ करने की तिथि',
      startTime: 'समय शुरू',
      endDate: 'अंतिम तिथि',
      endTime: 'अंत समय',
      prevYear: 'पिछले वर्ष',
      nextYear: 'अगले वर्ष',
      prevMonth: 'पिछला महीना',
      nextMonth: 'अगले महीने',
      year: '',
      month1: 'जनवरी',
      month2: 'फ़रवरी',
      month3: 'मार्च',
      month4: 'अप्रैल',
      month5: 'मई',
      month6: 'जून',
      month7: 'जुलाई',
      month8: 'अगस्त',
      month9: 'सितम्बर',
      month10: 'अक्टूबर',
      month11: 'नवंबर',
      month12: 'दिसंबर',
      week: 'सप्ताह',
      weeks: {
        sun: 'Sun',
        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat'
      },
      months: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        may: 'May',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Aug',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'Dec'
      }
    },
    select: {
      loading: 'लोडिंग',
      noMatch: 'कोई मेल खाता डेटा नहीं',
      noData: 'कोई डेटा नहीं',
      placeholder: 'चुनें'
    },
    cascader: {
      noMatch: 'कोई मेल खाता डेटा नहीं',
      loading: 'लोडिंग',
      placeholder: 'चुनें',
      noData: 'कोई डेटा नहीं'
    },
    pagination: {
      goto: 'Go to',
      pagesize: '/पृष्ठ',
      total: 'कुल {कुल}',
      pageClassifier: ''
    },
    messagebox: {
      title: 'संदेश',
      confirm: 'ठीक है',
      cancel: 'रद्द करना',
      error: 'गैरकानूनी इनपुट'
    },
    upload: {
      deleteTip: 'हटाने के लिए डिलीट दबाएँ',
      delete: 'मिटाना',
      preview: 'पूर्व दर्शन',
      continue: 'जारी रखना'
    },
    table: {
      emptyText: 'कोई डेटा नहीं',
      confirmFilter: 'पुष्टि करना',
      resetFilter: 'रीसेट',
      clearFilter: 'सभी',
      sumText: 'योग'
    },
    tree: {
      emptyText: 'कोई डेटा नहीं'
    },
    transfer: {
      noMatch: 'कोई मेल खाता डेटा नहीं',
      noData: 'कोई डेटा नहीं',
      titles: ['सूची 1', 'सूची 2'], //अनुवाद किया जाना है
      filterPlaceholder: 'कुंजीशब्द दर्ज करें', // अनुवाद किया जाना है
      noCheckedFormat: '{कुल} आइटम', //अनुवाद किया जाना है
      hasCheckedFormat: '{चेक किए गए}/{कुल} चेक किए गए' // अनुवाद किया जाना है
    },
    image: {
      error: 'असफल'
    },
    pageHeader: {
      title: 'पीछे' //  अनुवाद किया जाना है
    },
    popconfirm: {
      confirmButtonText: 'हाँ',
      cancelButtonText: 'नहीं'
    },
    empty: {
      description: 'कोई डेटा नहीं'
    }
  }
};